import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LANG from "../properties.json";
import images_store from "../utils/Images";
import CircleButton from "./CircleButton";
import soundImg from "../assets/icone_son.png";

import { Howl, Howler } from "howler";
import { useNavigate, useParams } from "react-router-dom";

const QUIZ =() => {
  //INITIALISATION
  //Id thème choisie à gerer dynamiquement
  //   var themeID = sessionStorage.getItem("theme");

  //   //Id langue à gerer dynamiquement 0=Français, 1=Lingala, 2=Kikongo, 3=Swahili, 4=Tshiluba
  var langID = sessionStorage.getItem("Lang");

  //   //Titre consigne
  //   function Consigne(IdLang, IdMod, page, seq) {
  //     const data = LANG.map((info) => {
  //       return info.languages[IdLang].domains[0].subdomains[IdMod].sequences[seq]
  //         .pages[page].text;
  //     });
  //     return data;
  //   }
  //   //Fin de partie
  //   function FIN_DE_PAGE(Idlangue, Idmodule, seq) {
  //     const data = LANG.map((info) => {
  //       return info.languages[Idlangue].domains[0].subdomains[Idmodule].sequences[
  //         seq
  //       ].pages[0].title;
  //     });

  //     return data;
  //   }

  //   //Afficher les auestionneaires et réponses
  
  //compteur points
  const [countPoint, setCount] = useState(0);

  const [show, setShow] = useState(false);
  const fermer = () => setShow(false);
  const ouvrir = () => setShow(true);
  //compteur questions
  const [countQuestion, setCountQuestion] = useState(0);
  const [reponsesnum, setReponsesnum] = useState(
    [0, 1, 2].sort(() => Math.random() - 0.5)
  );

  function REPONSES_SELECT(Idlangue, quest, numRep) {
    const data = LANG.map((info) => {
      return info.languages[Idlangue].domains[0].subdomains[0].sequences[4]
        .pages[5].questions[quest].responses[numRep].text;
    });
    return data;
  }
  var nbreQuest = LANG.map((info) => {
    return info.languages[parseInt(langID)].domains[0].subdomains[0]
      .sequences[4].pages[5].questions.length;
  });

  var questChoisie = Math.floor(Math.random() * nbreQuest);
  var [choixQuest, setChoixQuest] = useState(questChoisie);

  function controleQuestion(resolution, btn) {
    Howler.stop();
    if (resolution === questionnaire1.bonneReponse[0]) {
      //Bonne réponse
      btn.style.backgroundColor = "#46CC00";
      btn.style.color = "white";

      setTimeout(() => {
        btn.style.backgroundColor = "white";
        btn.style.color = "#043E7E";
        setReponsesnum(reponsesnum.sort(() => Math.random() - 0.5));
      }, 800);

      setTimeout(() => {
        if (countQuestion < 9) {
          setCount(countPoint + 1);
          setCountQuestion(countQuestion + 1);
        }
      }, 1000);

      if (countPoint === 0) {
        setCouleur1("#46CC00");
      }
      if (countPoint === 1) {
        setCouleur2("#46CC00");
      }
      if (countPoint === 2) {
        setCouleur3("#46CC00");
      }
      if (countPoint === 3) {
        setCouleur4("#46CC00");
      }
      if (countPoint === 4) {
        setCouleur5("#46CC00");
      }
      if (countPoint === 5) {
        setCouleur6("#46CC00");
      }
      if (countPoint === 6) {
        setCouleur7("#46CC00");
      }
      if (countPoint === 7) {
        setCouleur8("#46CC00");
      }
      if (countPoint === 8) {
        setCouleur9("#46CC00");
      }
      if (countPoint === 9) {
        setCouleur10("#46CC00");
        setTimeout(() => {
          ouvrir();
        }, 300);
      }

      /*setTimeout(() => {
        window.location.reload();
      }, 1000);*/
    } else {
      //Mauvaise réponse
      setTimeout(() => {
        btn.style.backgroundColor = "white";
        btn.style.color = "#043E7E";
        setReponsesnum(reponsesnum.sort(() => Math.random() - 0.5));
      }, 800);

      btn.style.backgroundColor = "lightcoral";
      btn.style.color = "white";
     
      setTimeout(() => {
        if (countQuestion < 9) {
          setCount(countPoint + 1);
          setCountQuestion(countQuestion + 1);
        }
      }, 800);

      if (countPoint === 0) {
        setCouleur1("red");
      }
      if (countPoint === 1) {
        setCouleur2("red");
      }
      if (countPoint === 2) {
        setCouleur3("red");
      }
      if (countPoint === 3) {
        setCouleur4("red");
      }
      if (countPoint === 4) {
        setCouleur5("red");
      }
      if (countPoint === 5) {
        setCouleur6("red");
      }
      if (countPoint === 6) {
        setCouleur7("red");
      }
      if (countPoint === 7) {
        setCouleur8("red");
      }
      if (countPoint === 8) {
        setCouleur9("red");
      }
      if (countPoint === 9) {
        setCouleur10("red");
        setTimeout(() => {
          ouvrir();
        }, 300);
      }
    }
  }

  //States changement couleurs
  const [couleur1, setCouleur1] = useState("white");

  var style1 = {
    backgroundColor: couleur1,
  };
  const [couleur2, setCouleur2] = useState("white");

  var style2 = {
    backgroundColor: couleur2,
  };
  const [couleur3, setCouleur3] = useState("white");

  var style3 = {
    backgroundColor: couleur3,
  };
  const [couleur4, setCouleur4] = useState("white");

  var style4 = {
    backgroundColor: couleur4,
  };
  const [couleur5, setCouleur5] = useState("white");

  var style5 = {
    backgroundColor: couleur5,
  };
  const [couleur6, setCouleur6] = useState("white");

  var style6 = {
    backgroundColor: couleur6,
  };
  const [couleur7, setCouleur7] = useState("white");

  var style7 = {
    backgroundColor: couleur7,
  };
  const [couleur8, setCouleur8] = useState("white");

  var style8 = {
    backgroundColor: couleur8,
  };
  const [couleur9, setCouleur9] = useState("white");

  var style9 = {
    backgroundColor: couleur9,
  };
  const [couleur10, setCouleur10] = useState("white");

  var style10 = {
    backgroundColor: couleur10,
  };

  const [numeroQuestion, setNumeroQuestion] = useState(
    questionsnum(nbreQuest).sort(() => Math.random() - 0.5)
  );

  // Variable objet pour les questions et reponses
  var questionnaire1 = {
    question: LANG.map((info) => {
      return stringFormer(info.languages[langID].domains[0].subdomains[0].sequences[4]
        .pages[5].questions[numeroQuestion[countQuestion]].text);
    }),
    audio: LANG.map((info) => {
      return info.languages[langID].domains[0].subdomains[0].sequences[4]
        .pages[5].questions[numeroQuestion[countQuestion]].media_questions[0].audio_file;
    }),
    reponse: [
      REPONSES_SELECT(langID, numeroQuestion[countQuestion], 0),
      REPONSES_SELECT(langID, numeroQuestion[countQuestion], 1),
      REPONSES_SELECT(langID, numeroQuestion[countQuestion], 2),
    ],
    bonneReponse: REPONSES_SELECT(langID, numeroQuestion[countQuestion], 0),
  };

  function questionsnum(nbr) {
    var data = [];
    for (let index = 0; index < nbr; index++) {
      data.push(index);
    }

    return data;
  }
  let navigate = useNavigate();
  const { moduleId } = useParams();
  const handleOnRefresh = () => {
   // props.onReload();
  };

  const handleReturnHome = () => {
    navigate("/" + moduleId + "/exercices", { replace: true });
  };
  function Fin_partie(IdLang){
    const data = LANG.map((info)=>{
      return info.languages[IdLang].domains[0].subdomains[0].sequences[0].pages[6].title
    })
    return data
      }
      const activeCloseModal = {
        transform: "translateY(10px)",
        opacity: 1,
      };

  return (
    <div className="container-fluid">
      <div className="col " >
        <div
          className="row mt-4 "
          style={{
            fontSize: "2vw",
            fontWeight: "bold",
            color: "#00884A",
            textTransform: "uppercase",
          }}
        >
          {/* <div
                className="col"
                style={{ textAlign: "center",marginTop:'2%',marginBottom:'3%'}}
                dangerouslySetInnerHTML={{
                  __html: Titre_Module(langID, themeID, 0),
                }}
              /> */}
          <div className="col-1"></div>
        </div>
        <Modal show={show} onHide={fermer} size="lg" style={{ width: "45vw",height:'50vw' ,marginTop:"5%",marginLeft:'25%' }}>
          <div className="row finpartie" id="">
          
            <div className="row finpartie-text" style={{ marginTop:'5%' }}>
              <p className="h2">
                 <div
                      dangerouslySetInnerHTML={{
                        __html: Fin_partie(langID)
                      }}
                    /> 
              </p>
            </div>
            <div className="col mb-5 mt-5">
              <div
                className="row cases-pointMod3"
                style={{ margin: "auto", marginTop: "1%", marginBottom: "3%" }}
              >
                <div className="col case" id="1" style={style1}>
                  <p>
                    <br />
                  </p>
                </div>
                <div className="col case" id="2" style={style2}></div>
                <div className="col case " id="3" style={style3}></div>
                <div className="col case" id="4" style={style4}></div>
                <div className="col case" id="5" style={style5}></div>
                <div className="col case" id="6" style={style6}></div>
                <div className="col case" id="7" style={style7}></div>
                <div className="col case" id="8" style={style8}></div>
                <div className="col case" id="9" style={style9}></div>
                <div className="col case" id="10" style={style10}></div>
              </div>
            </div>
            <div
              className="row "
              style={{  marginBottom:'10%'}}
            >
              {/* <div className="col  finpartie-btn">
                <NavLink to="" onClick={() => window.location.reload()}>
                <CircleButton
             // onClickEvt={()=> window.location.reload()}
              img={images_store.general.restartIcon}
            />
                </NavLink>
              </div> */}
              <div className="col  finpartie-btn-1" style={{marginLeft:'44%'}}>
              <CircleButton
              onClickEvt={handleReturnHome}
              img={images_store.general.backHomeImage}
            />
              </div>
            </div>
            
          </div>
        </Modal>
        <div
          className="row cases-pointMod3 "
          style={{ margin: "auto", marginBottom: "7%" }}
        >
          <div className="col case" id="1" style={style1}>
            <p>
              <br />
            </p>
          </div>
          <div className="col case" id="2" style={style2}></div>
          <div className="col case " id="3" style={style3}></div>
          <div className="col case" id="4" style={style4}></div>
          <div className="col case" id="5" style={style5}></div>
          <div className="col case" id="6" style={style6}></div>
          <div className="col case" id="7" style={style7}></div>
          <div className="col case" id="8" style={style8}></div>
          <div className="col case" id="9" style={style9}></div>
          <div className="col case" id="10" style={style10}></div>
        </div>
        <div className="row centrer" >
          <div className="col">
            
            <div
              className="row"
              style={{ marginRight: "10%", marginLeft: "10%",marginTop:'-2%' }}
            >
              <div
                className="row  quiz-content "
                style={{
                  width: "50%",
                  
                  marginLeft: "5%",
                  marginTop: "10%",
                  marginBottom:'5%',
                  position: "relative",
                }}
              >
                <div
                  className="col"
                  style={{ height: "100px", overflow: "auto" }}
                >
                  <p
                    style={{
                      marginTop: "3%",
                      fontSize: "1.3vw",
                      color: "#60523B",
                    }}
                  >
                   <img src={soundImg} alt="sound" style={{cursor: "pointer", verticalAlign: "middle", width: "6%", marginRight: "2%"}} onClick={() => {
                    var audio = new Howl({
                      src: ["../../sounds/" + questionnaire1.audio],
                    });
                    audio.play();
                  }
                  } />
                    { questionnaire1.question }
                  </p>
                </div>
              </div>
              <div className="col" style={{marginBottom:'5%'}}>
                <div className="col reponses-quiz" style={{ marginTop: "12%" }}>
                  <div className="row">
                    <div className="col">
                      <button
                        style={{
                          width: "80%",height:'5vw',
                          marginBottom: "1%",
                          border: "none",
                        }}
                        onClick={(e) =>
                          controleQuestion(
                            questionnaire1.reponse[reponsesnum[0]][0],
                            document.getElementById("btn-reponse1")
                          )
                        }
                        id="btn-reponse1"
                      >
                        <p
                          style={{ color: "#60523B", fontSize: "1.2vw" }}
                          dangerouslySetInnerHTML={{
                            __html: questionnaire1.reponse[reponsesnum[0]][0],
                          }}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col" style={{}}>
                      <button
                        style={{
                          width: "80%",
                          marginBottom: "1%",
                          border: "none",height:'5vw',
                        }}
                        onClick={(e) =>
                          controleQuestion(
                            questionnaire1.reponse[reponsesnum[1]][0],
                            document.getElementById("btn-reponse2")
                          )
                        }
                        id="btn-reponse2"
                        disabled={false}
                      >
                        <p
                          style={{ color: "#60523B", fontSize: "1.2vw", }}
                          dangerouslySetInnerHTML={{
                            __html: questionnaire1.reponse[reponsesnum[1]][0],
                          }}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button
                        style={{
                          width: "80%",
                          marginBottom: "1%",
                          border: "none",height:'5vw',
                        }}
                        onClick={(e) =>
                          controleQuestion(
                            questionnaire1.reponse[reponsesnum[2]][0],
                            document.getElementById("btn-reponse3")
                          )
                        }
                        id="btn-reponse3"
                      >
                        <p
                          style={{ color: "#60523B", fontSize: "1.2vw" }}
                          dangerouslySetInnerHTML={{
                            __html: questionnaire1.reponse[reponsesnum[2]][0],
                          }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//

function stringFormer(string) {
  if (string == null) {
    return "";
  } else {
    const txt = string.replace(/<[^>]+>/g, "");
    return txt;
  }
}

export default QUIZ;
{/* <div
              className="col"
              style={{
                height: "4vw",
                marginTop: "-3vw",
              }}
            >
              <div className="col " style={{}}>
                <p
                  className="h4 texte-choix-reponse centrer "
                  style={{ marginTop: "5%" }}
                >
                  {/* <div
                        className="col body-text"
                        style={{ textAlign: "center" }}
                        dangerouslySetInnerHTML={{
                          __html: Consigne(langID, themeID, 0, 0),
                        }}
                      /> */}
                  //REPONDEZ AUX QUESTIONS SUIVANTES
            //     </p>
            //   </div>
            // </div> */}