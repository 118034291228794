import images_store from "./Images";
import {
  pages_data,
  pages_data_mod2,
  pages_data_mod3,
  pages_data_mod4,
  pages_data_mod5,
  pages_data_mod6,
} from "./Lesson_Data";

const stepsTitle_module1 = [
  pages_data[0].title,
  pages_data[1].title,
  pages_data[2].title,
  pages_data[3].title,
  pages_data[4].title,
  pages_data[5].title
];
const stepsTitle_module1_exercices = [
  pages_data[0].title,
  pages_data[1].title,
  pages_data[2].title,
  pages_data[3].title,
  pages_data[4].title,
];
const stepsTitle_module4_exercices = [
  pages_data_mod4[0].title,
  pages_data_mod4[1].title,
  pages_data_mod4[2].title,
  pages_data_mod4[3].title,
  pages_data_mod4[4].title,
];
const stepsTitle_module6_exercices = [
  pages_data_mod6[0].title,
  pages_data_mod6[1].title,
  pages_data_mod6[2].title,
];
const stepsTitle_module5_exercices = [
  pages_data_mod5[5].title = 'Quiz'
];

const stepsTitle_module2 = [
  pages_data_mod2[0].title,
  pages_data_mod2[1].title,
  pages_data_mod2[2].title,
  pages_data_mod2[3].title,
  pages_data_mod2[4].title,
  pages_data_mod2[5].title,
];

const stepsTitle_module2_exercices = [
  pages_data_mod2[1].title,
  pages_data_mod2[2].title,
  pages_data_mod2[3].title,
  pages_data_mod2[4].title,
];

const stepsTitle_module3 = [
  pages_data_mod3[0].title,
  pages_data_mod3[1].title,
  pages_data_mod3[2].title,
  pages_data_mod3[3].title,
  pages_data_mod3[4].title,
  pages_data_mod3[5].title
];

const stepsTitle_module3_exercices = [
  pages_data_mod3[0].title,
  pages_data_mod3[1].title,
  pages_data_mod3[3].title,
];

const stepsTitle_module4 = [
  pages_data_mod4[0].title,
  pages_data_mod4[1].title,
  pages_data_mod4[2].title,
  pages_data_mod4[3].title,
  pages_data_mod4[4].title,
  pages_data_mod4[5].title,
];

const stepsTitle_module5 = [
  pages_data_mod5[0].title,
  pages_data_mod5[1].title,
  pages_data_mod5[2].title,
  pages_data_mod5[3].title,
  pages_data_mod5[4].title,
];

const stepsTitle_module6 = [
  pages_data_mod6[0].title,
  pages_data_mod6[1].title,
  pages_data_mod6[2].title,
  pages_data_mod6[3].title,
  pages_data_mod6[4].title,
];

const Etapes = {
  module1: [
    {
      image_src: images_store.mod1.steps.step1,
      image_orange_src: images_store.mod1.steps_onClick.step1,
      title: stepsTitle_module1[0],
      path: "/module1/lesson1",
      key: 1,
    },
    {
      image_src: images_store.mod1.steps.step2,
      image_orange_src: images_store.mod1.steps_onClick.step2,
      title: stepsTitle_module1[1],
      path: "/module1/lesson2",
      key: 2,
    },
    {
      image_src: images_store.mod1.steps.step3,
      image_orange_src: images_store.mod1.steps_onClick.step3,
      title: stepsTitle_module1[2],
      path: "/module1/lesson3",
      key: 3,
    },
    {
      image_src: images_store.mod1.steps.step4,
      image_orange_src: images_store.mod1.steps_onClick.step4,
      title: stepsTitle_module1[3],
      path: "/module1/lesson4",
      key: 4,
    },
    {
      image_src: images_store.mod1.steps.step5,
      image_orange_src: images_store.mod1.steps_onClick.step5,
      title: stepsTitle_module1[4],
      path: "/module1/lesson5",
      key: 5,
    },
    {
      image_src: images_store.mod1.steps.step6,
      image_orange_src: images_store.mod1.steps_onClick.step6,
      title: stepsTitle_module1[5],
      path: "/module1/exercices",
      key: 6,
    },
  ],
  module2: [
    {
      image_src: images_store.mod2.steps.step1,
      image_orange_src: images_store.mod2.steps_onClick.step1,
      title: stepsTitle_module2[0],
      path: "/module2/lesson1",
      key: 1,
    },
    {
      image_src: images_store.mod2.steps.step2,
      image_orange_src: images_store.mod2.steps_onClick.step2,
      title: stepsTitle_module2[1],
      path: "/module2/lesson2",
      key: 2,
    },
    {
      image_src: images_store.mod2.steps.step3,
      image_orange_src: images_store.mod2.steps_onClick.step3,
      title: stepsTitle_module2[2],
      path: "/module2/lesson3",
      key: 3,
    },
    {
      image_src: images_store.mod2.steps.step4,
      image_orange_src: images_store.mod2.steps_onClick.step4,
      title: stepsTitle_module2[3],
      path: "/module2/lesson4",
      key: 4,
    },
    {
      image_src: images_store.mod2.steps.step5,
      image_orange_src: images_store.mod2.steps_onClick.step5,
      title: stepsTitle_module2[4],
      path: "/module2/lesson5",
      key: 5,
    },
    {
      image_src: images_store.mod2.steps.step6,
      image_orange_src: images_store.mod2.steps_onClick.step6,
      title: stepsTitle_module2[5],
      path: "/module2/exercices",
      key: 6,
    },
  ],
  module3: [
    {
      image_src: images_store.mod3.steps.step1,
      image_orange_src: images_store.mod3.steps_onClick.step1,
      title: stepsTitle_module3[0],
      path: "/module3/lesson1",
      key: 1,
    },
    {
      image_src: images_store.mod3.steps.step2,
      image_orange_src: images_store.mod3.steps_onClick.step2,
      title: stepsTitle_module3[1],
      path: "/module3/lesson2",
      key: 2,
    },
    {
      image_src: images_store.mod3.steps.step3,
      image_orange_src: images_store.mod3.steps_onClick.step3,
      title: stepsTitle_module3[2],
      path: "/module3/lesson3",
      key: 3,
    },
    {
      image_src: images_store.mod3.steps.step4,
      image_orange_src: images_store.mod3.steps_onClick.step4,
      title: stepsTitle_module3[3],
      path: "/module3/lesson4",
      key: 4,
    },
    {
      image_src: images_store.mod3.steps.step5,
      image_orange_src: images_store.mod3.steps_onClick.step5,
      title: stepsTitle_module3[4],
      path: "/module3/lesson5",
      key: 5,
    },
    {
      image_src: images_store.mod3.steps.step6,
      image_orange_src: images_store.mod3.steps_onClick.step6,
      title: stepsTitle_module3[5],
      path: "/module3/exercices",
      key: 6,
    },
  ],
  module4: [
    {
      image_src: images_store.mod4.steps.step1,
      image_orange_src: images_store.mod4.steps_onClick.step1,
      title: stepsTitle_module4[0],
      path: "/module4/lesson1",
      key: 1,
    },
    {
      image_src: images_store.mod4.steps.step2,
      image_orange_src: images_store.mod4.steps_onClick.step2,
      title: stepsTitle_module4[1],
      path: "/module4/lesson2",
      key: 2,
    },
    {
      image_src: images_store.mod4.steps.step3,
      image_orange_src: images_store.mod4.steps_onClick.step3,
      title: stepsTitle_module4[2],
      path: "/module4/lesson3",
      key: 3,
    },
    {
      image_src: images_store.mod4.steps.step4,
      image_orange_src: images_store.mod4.steps_onClick.step4,
      title: stepsTitle_module4[3],
      path: "/module4/lesson4",
      key: 4,
    },
    {
      image_src: images_store.mod4.steps.step5,
      image_orange_src: images_store.mod4.steps_onClick.step5,
      title: stepsTitle_module4[4],
      path: "/module4/lesson5",
      key: 5,
    },
    {
      image_src: images_store.mod4.steps.step6,
      image_orange_src: images_store.mod4.steps_onClick.step6,
      title: stepsTitle_module4[5],
      path: "/module4/exercices",
      key: 6,
    },
  ],
  module5: [
    {
      image_src: images_store.mod5.steps.step1,
      image_orange_src: images_store.mod5.steps_onClick.step1,
      title: stepsTitle_module5[0],
      path: "/module5/lesson1",
      key: 1,
    },
    {
      image_src: images_store.mod5.steps.step2,
      image_orange_src: images_store.mod5.steps_onClick.step2,
      title: stepsTitle_module5[1],
      path: "/module5/lesson2",
      key: 2,
    },
    {
      image_src: images_store.mod5.steps.step3,
      image_orange_src: images_store.mod5.steps_onClick.step3,
      title: stepsTitle_module5[2],
      path: "/module5/lesson3",
      key: 3,
    },
    {
      image_src: images_store.mod5.steps.step4,
      image_orange_src: images_store.mod5.steps_onClick.step4,
      title: stepsTitle_module5[3],
      path: "/module5/lesson4",
      key: 4,
    },
    {
      image_src: images_store.mod5.steps.step5,
      image_orange_src: images_store.mod5.steps_onClick.step5,
      title: stepsTitle_module5[4],
      path: "/module5/lesson5",
      key: 5,
    },
    {
      image_src: images_store.mod5.steps.step6,
      image_orange_src: images_store.mod5.steps_onClick.step6,
      title: "Exercices",
      path: "/module5/exercices",
      key: 6,
    },
  ],
  module6: [
    {
      image_src: images_store.mod6.steps.step1,
      image_orange_src: images_store.mod6.steps_onClick.step1,
      title: stepsTitle_module6[0],
      path: "/module6/lesson1",
      key: 1,
    },
    {
      image_src: images_store.mod6.steps.step2,
      image_orange_src: images_store.mod6.steps_onClick.step2,
      title: stepsTitle_module6[1],
      path: "/module6/lesson2",
      key: 2,
    },
    {
      image_src: images_store.mod6.steps.step3,
      image_orange_src: images_store.mod6.steps_onClick.step3,
      title: stepsTitle_module6[2],
      path: "/module6/lesson3",
      key: 3,
    },
    {
      image_src: images_store.mod6.steps.step4,
      image_orange_src: images_store.mod6.steps_onClick.step4,
      title: stepsTitle_module6[3],
      path: "/module6/lesson4",
      key: 4,
    },
    {
      image_src: images_store.mod6.steps.step5,
      image_orange_src: images_store.mod6.steps_onClick.step5,
      title: stepsTitle_module6[4],
      path: "/module6/lesson5",
      key: 5,
    },
    {
      image_src: images_store.mod6.steps.step6,
      image_orange_src: images_store.mod6.steps_onClick.step6,
      title: "Exercices",
      path: "/module6/exercices",
      key: 6,
    }
  ],
};

export {
  Etapes,
  stepsTitle_module1,
  stepsTitle_module2,
  stepsTitle_module3,
  stepsTitle_module1_exercices,
  stepsTitle_module4_exercices,
  stepsTitle_module6_exercices,
  stepsTitle_module5_exercices,
  stepsTitle_module2_exercices,
  stepsTitle_module3_exercices,
  stepsTitle_module4,
  stepsTitle_module5,
  stepsTitle_module6,
};
