import React, { useEffect, useState } from "react";
import Loader from "./components/Loader";
import Chapter from "./pages/Chapter";
import Home from "./pages/Home";
import Exercice from "./pages/Exercice";
import Error from "./pages/Error";
import "./styles/App.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Africatik from "./components/Africatik";
import Menu from "./pages/Menu";
import Exemple from './pages/exemple'
import { Howl, Howler } from 'howler';


function App() {

  return /* loader ? (<Loader/>) : */ (
    // return (
    <div className="App">
      {/* <Home /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Menu/>}/>
          <Route path="/:moduleId/:pageId" element={<Chapter/>}/>
          <Route path="/:moduleId/:pageId/:exerciceId" element={<Exercice/>}/>
          <Route path="*" element={<Error/>}/>
          <Route path="/africatik" element={<Africatik></Africatik>} />
          <Route path="/mapper" element={<Exemple/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
