import React from "react";
import soundImg from "../assets/icone_son.png";
import { Howler } from "howler";

const StringWithSound = (props) => {
  const handleAudioPlay = () => {
    // Call the onAudioPlay prop function if it exists
    if (props.onAudioPlay) {
      Howler.stop();
      props.onAudioPlay();
    }
  };
  return (
    <div className="instruction">
      <p className="col" style={{ fontSize: '1.5vw', marginBottom: '-5%', fontWeight: "bold" }}>
        <span dangerouslySetInnerHTML={{ __html: stringFormer(props.string) }} />
        {props.hasAudio && (
          <img
          src={soundImg}
          alt="sound"
          style={{ width: "1.8vw", marginLeft: "1vw", cursor: "pointer" }}
          onClick={handleAudioPlay}
        />
        )}
      </p>
    </div>
  );
};

function stringFormer(string) {
  if (string == null) {
    return "";
  } else {
    const txt = string.replace(/<[^>]+>/g, "");
    return txt;
  }
}
export default StringWithSound;
