import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LessonImage from "../components/LessonImage";
import Modal from "../components/Modal";
import StringWithSound from "../components/StringWithSound";
import images_store from "../utils/Images";
import CircleButton from "../components/CircleButton";
import { useNavigate } from "react-router-dom";
import SingleQuote from "../components/SingleQuote";
import { mod1_exercices_content, mod4_consignes, mod6_consignes, validateText } from "../utils/Lesson_Data";
import VerticaleImage from "../components/VerticaleImage";
import HImageMod3 from "../components/HImageMod3";
import {
  stepsTitle_module2_exercices,
  stepsTitle_module3_exercices,
  stepsTitle_module4,
  stepsTitle_module5_exercices,
  stepsTitle_module6,
} from "../utils/Etapes";

import { Howl, Howler } from "howler";
import {
  mod1_audios,
  mod2_audios_lessons,
  mod2_consignes_lessons,
  mod3_consignes,
  mod1_exercice5,
  mod3_audios,
  mod4_audios,
  mod6_audios,
  clicks
} from "../utils/Lesson_Data";
import LANG from "../properties.json";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import HImageMod4 from "../components/HImageMod4";
import QUIZ from "../components/Quizz";
import soundImg from "../assets/icone_son.png";

function Exercice(props) {
  const slideStyles = {
    width: "35vw",
    height: "20vw",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  let slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${props.img})`,
  };

  const { moduleId } = useParams();
  // const { pageId } = useParams();
  const { exerciceId } = useParams();

  useEffect(() => {}, []);
  const [lessonExoId, setLessonExoId] = useState(exo_id(exerciceId));
  const [lessonExoId_focus, setLessonExoId_focus] = useState(
    exoFocus_id(lessonExoId)
  );

  let navigate = useNavigate();

  const [goodAnswerCount, setGoodAnswerCount] = useState(0);
  const [badAnswerCount, setBadAnswerCount] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const [answerQuestions, setAnswerQuestions] = useState([]);

  const validate_Text = stringFormer(validateText);

  function Lesson_img(IdLang, page) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[0].sequences[0].pages[
        page
      ].media_pages[0].img_file;
    });
    return data.toString();
  }
  const lessons = [Lesson_img(0, 0), Lesson_img(0, 1), Lesson_img(0, 2)];
  const Img_and_questionsMod1_lesson1 = {
    img: "/imgs/" + lessons[0],
    questions: mod1_exercices_content.consignes[0],
  };

  const Img_and_questionsMod1_lesson2 = {
    img: "/imgs/" + lessons[1],
    questions: mod1_exercices_content.consignes[1],
  };

  const Img_and_questionsMod1_lesson3 = {
    img: "/imgs/" + lessons[2],
    questions: mod1_exercices_content.consignes[2],
  };

  const questionsMod1_lesson4 = {
    img: "none",
    questions: mod1_exercices_content.consignes[3],
  };

  const question_returner = (bool) => {
    let txt;

    if (bool) {
      txt = questionsMod1[lessonExoId - 1];
    } else {
      txt = questionsMod1[lessonExoId - 1].questions[counter];
    }

    return txt;
  };

  const Img_and_questionsMod2_lesson1 = {
    img: images_store.mod2.lessons,
    questions: [mod2_consignes_lessons.lesson1],
  };

  const Img_and_questionsMod2_lesson2 = {
    img: images_store.mod2.lessons,
    questions: mod2_consignes_lessons.lesson2,
  };

  const Img_and_questionsMod2_lesson3 = {
    img: images_store.mod2.lessons,
    questions: mod2_consignes_lessons.lesson3,
  };

  const Img_and_questionsMod2_lesson4 = {
    img: "none",
    questions: [mod2_consignes_lessons.lesson4],
  };

  const questionsMod2 = [
    Img_and_questionsMod2_lesson1,
    Img_and_questionsMod2_lesson2,
    Img_and_questionsMod2_lesson3,
    Img_and_questionsMod2_lesson4,
  ];

  const Img_and_questionsMod3_lesson1 = {
    // img: images_store.mod2.lessons,
    questions: mod3_consignes.exercice1,
  };

  const Img_and_questionsMod3_lesson2 = {
    // img: images_store.mod2.lessons,
    questions: mod3_consignes.exercice2,
  };

  const Img_and_questionsMod3_lesson3 = {
    // img: images_store.mod2.lessons,
    questions: mod3_consignes.exercice3,
  };

  const questionsMod3 = [
    Img_and_questionsMod3_lesson1,
    Img_and_questionsMod3_lesson2,
    Img_and_questionsMod3_lesson3,
  ];
  //----------------------mod4--------//
  const Img_and_questionsMod4_lesson1 = {
    // img: images_store.mod2.lessons,
    questions: mod4_consignes.exercice1,
  };

  const Img_and_questionsMod4_lesson2 = {
    // img: images_store.mod2.lessons,
    questions: mod4_consignes.exercice2,
  };

  const Img_and_questionsMod4_lesson3 = {
    // img: images_store.mod2.lessons,

    questions: mod4_consignes.exercice3,
  };
  const Img_and_questionsMod4_lesson4 = {
    // img: images_store.mod2.lessons,
    questions: mod4_consignes.exercice4,
  };
  const Img_and_questionsMod4_lesson5 = {
    // img: images_store.mod2.lessons,
    questions: mod4_consignes.exercice5,
  };

  const questionsMod4 = [
    Img_and_questionsMod4_lesson1,
    Img_and_questionsMod4_lesson2,
    Img_and_questionsMod4_lesson3,
    Img_and_questionsMod4_lesson4,
    Img_and_questionsMod4_lesson5,
  ];

  ////////////////////////////////////////

  const questionsMod1_lesson5 = mod1_exercice5.consigne;

  const questionsMod1 = [
    Img_and_questionsMod1_lesson1,
    Img_and_questionsMod1_lesson2,
    Img_and_questionsMod1_lesson3,
    questionsMod1_lesson4,
    questionsMod1_lesson5,
  ];

  const Img_and_questionsMod6_lesson1 = {
    // img: images_store.mod2.lessons,
    questions: mod6_consignes.exercice1,
  };

  const Img_and_questionsMod6_lesson2 = {
    // img: images_store.mod2.lessons,
    questions: mod6_consignes.exercice2,
  };

  const Img_and_questionsMod6_lesson3 = {
    // img: images_store.mod2.lessons,

    questions: mod6_consignes.exercice3,
  };
  const Img_and_questionsMod6_lesson4 = {
    // img: images_store.mod2.lessons,
    questions: mod6_consignes.exercice4,
  };

  const questionsMod6 = [
    Img_and_questionsMod6_lesson1,
    Img_and_questionsMod6_lesson2,
    Img_and_questionsMod6_lesson3,
    Img_and_questionsMod6_lesson4,
  ];

  const everySingleExerciceTitle = (moduleId) => {
    var title_array;

    if (moduleId === "module1") {
      title_array = mod1_exercices_content.title;
    } else if (moduleId === "module2") {
      title_array = stepsTitle_module2_exercices;
    } else if (moduleId === "module3") {
      title_array = stepsTitle_module3_exercices;
    } else if (moduleId === "module4") {
      title_array = stepsTitle_module4;
    }
     else if (moduleId === "module5") {
    title_array = stepsTitle_module5_exercices;
    }else if (moduleId === "module6") {
      title_array = stepsTitle_module6;
      }

    return title_array;
  };

  const exo_question_lenght = (exerciceId) => {
    let lenght;

    if (moduleId === "module1") {
      if (exerciceId === "E1") {
        lenght = Img_and_questionsMod1_lesson1.questions.length;
      } else if (exerciceId === "E2") {
        lenght = Img_and_questionsMod1_lesson2.questions.length;
      } else if (exerciceId === "E3") {
        lenght = 10;
      } else if (exerciceId === "E4") {
        lenght = questionsMod1_lesson4.questions.length;
      } else if (exerciceId === "E5") {
        lenght = quotes.length;
      }
    } else if (moduleId === "module2") {
      if (exerciceId === "E1") {
        lenght = 1;
      } else if (exerciceId === "E2") {
        lenght = 9;
      } else if (exerciceId === "E3") {
        lenght = 5;
      } else if (exerciceId === "E4") {
        lenght = 1;
      }
    } else if (moduleId === "module3") {
      if (exerciceId === "E1") {
        lenght = 3;
      } else if (exerciceId === "E2") {
        lenght = 4;
      } else if (exerciceId === "E3") {
        lenght = 3;
      }
    } else if (moduleId === "module4") {
      if (exerciceId === "E1") {
        lenght = 2;
      } else if (exerciceId === "E2") {
        lenght = 2;
      } else if (exerciceId === "E3") {
        lenght = 4;
      } else if (exerciceId === "E4") {
        lenght = 3;
      } else if (exerciceId === "E5") {
        lenght = 1;
      }
    }  
    else if (moduleId === "module6") {
      if (exerciceId === "E1") {
        lenght = 2;
      } else if (exerciceId === "E2") {
        lenght = 1;
      } else if (exerciceId === "E3") {
        lenght = 3;
      } else if (exerciceId === "E4") {
        lenght = 3;
      } else if (exerciceId === "E5") {
        lenght = 2;
      }
    }else if(moduleId==="module5"){
      if(exerciceId === "E1"){
        lenght=0; 
      }
    }

    return lenght;
  };

  let quotes = mod1_exercices_content.consignes[4].map((quote) =>
    stringFormer(quote)
  );

  //On enleve le dernier element du tableau quotes
  quotes.pop();

  let [visited_quotes, setVisited] = useState([""]);

  let [count_color, setCount_color] = useState([]);
  const [questionNumber, setQuestionNumber] = useState(
    exo_question_lenght(exerciceId)
  );

  useEffect(() => {
    setLessonExoId_focus(exoFocus_id(lessonExoId));
    if (counter === questionNumber) {
      setModalOpen(true);
    }
    if(counter==0){
      setModalOpen(false)
    }
  }, [counter]);

  const handleOnDefault_stateBehaviour = () => {
    var elms = document.getElementsByClassName("exo_pointer");
    [...document.getElementsByClassName("exo_pointer")].map((elm) => {
      elm.classList.contains("goodAnswer")
        ? elm.classList.remove("goodAnswer")
        : elm.classList.remove("badAnswer");
    });
    setCounter(0);
    setVisited([""]);
    setModalOpen(false);
    setCount_color([]);

    if (exerciceId === "E5") {
      [...document.getElementsByClassName("quotes")].map((elm) => {
        if (elm.classList.contains("quote_visited")) {
          elm.classList.remove("quote_visited");
        }
      });
    }
  };

  const goodOrBad_Manager = (itemNumber, focusIdNumber) => {
    let elm = document.getElementById("item" + itemNumber);

    if (counter === focusIdNumber) {
      setGoodAnswerCount((prev) => prev + 1);
      elm.classList.add("goodAnswer");
      setCount_color((color_bool) => {
        const newValues = [...color_bool];
        newValues[itemNumber] = "goodAnswer";
        return newValues;
      });
    } else {
      setBadAnswerCount((prev) => prev + 1);
      elm.classList.add("badAnswer");
      setCount_color((color_bool) => {
        const newValues = [...color_bool];
        newValues[itemNumber] = "badAnswer";
        return newValues;
      });
    }

    setAnswerQuestions((questions) => {
      const newValues = [...questions];
      newValues[counter] = lessonExoId_focus;
      return newValues;
    });
  };

  const handle_phoneApp_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 0);
      return prev + 1;
    });
  };
  const handle_msg_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 1);
      return prev + 1;
    });
  };
  const handle_camera_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 2);
      return prev + 1;
    });
  };
  const handle_galerie_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 3);
      return prev + 1;
    });
  };
  const handle_horloge_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 4);
      return prev + 1;
    });
  };
  const handle_contact_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 5);
      return prev + 1;
    });
  };
  const handle_setting_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 6);
      return prev + 1;
    });
  };
  const handle_calandar_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 7);
      return prev + 1;
    });
  };
  const handle_clc_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 9);
      return prev + 1;
    });
  };

  const handle_goodScreenCap_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 0);
      return prev + 1;
    });
  };

  const handle_fasleScreenCap_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 1);
      return prev + 1;
    });
  };
  //////////////////////

  const onClickExo3_stape0 = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 0);
      return prev + 1;
    });
  };

  const onClickExo3_stape1 = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 1);
      return prev + 1;
    });
  };

  const onClickExo3_stape2 = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 2);
      return prev + 1;
    });
  };

  const onClickExo3_stape3 = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 3);
      return prev + 1;
    });
  };

  const onClickExo3_stape4 = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 4);
      return prev + 1;
    });
  };

  const onClickExo3_stape5 = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 5);
      return prev + 1;
    });
  };

  //////////////////////////

  let sound;
  let clickOn;
  const Handle_onAudioPlay = () => {
    if (moduleId === "module1") {
      if (exo_id(exerciceId) === 1) {
        clickOn = new Howl({
          src: [
            "../../sounds/" + clicks.clickOn
          ],
        });
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson1[0],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson1[1],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 2:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson1[2],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 3:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson2[2],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          default:
            break;
        }
      } else if (exo_id(exerciceId) === 2) {
        clickOn = new Howl({
          src: [
            "../../sounds/" + clicks.clickOn,
          ],
        });
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson2[0],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson2[1],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 2:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson2[2],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          default:
            break;
        }
      } else if (exo_id(exerciceId) === 3) {
        clickOn = new Howl({
          src: [
            "../../sounds/" + clicks.clickOn
          ],
        });
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson3[0],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson3[1],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 2:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson3[2],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 3:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson3[3],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 4:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson3[4],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 5:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson3[5],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 6:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson3[6],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 7:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson3[7],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 8:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson3[8],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 9:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson3[9],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 10:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson3[10],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          default:
            break;
        }
      } else if (exo_id(exerciceId) === 4) {
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson4[0],
              ],
            });
            sound.play();
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson4[1],
              ],
            });
            sound.play();
            break;
          case 2:
            sound = new Howl({
              src: [
                "../../sounds/" + mod1_audios.lesson4[2],
              ],
            });
            sound.play();
            break;
          default:
            break;
        }
      } else if (exo_id(exerciceId) === 5) {
        switch (counter) {
          default:
            sound = new Howl({
              src: ["../../sounds/" + mod1_audios.lesson5],
            });
            sound.play();
            break;
        }
      }
    } else if (moduleId === "module2") {
      if (exo_id(exerciceId) === 1) {
        clickOn = new Howl({
          src: [
            "../../sounds/" + clicks.clickRight,
          ],
        });
        sound = new Howl({
          src: [
            "../../sounds/" + mod2_audios_lessons.exercice1,
          ],
        });

        clickOn.play();

        clickOn.on("end", () => {
          sound.play();
        });
      } else if (exo_id(exerciceId) === 2) {
        clickOn = new Howl({
          src: [
            "../../sounds/" + clicks.clickRight,
          ],
        });
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice2[0],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice2[1],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 2:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice2[2],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 3:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice2[3],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 4:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice2[4],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 5:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice2[5],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 6:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice2[6],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 7:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice2[7],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 8:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice2[8],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          default:
            break;
        }
      } else if (exo_id(exerciceId) === 3) {
        clickOn = new Howl({
          src: [
            "../../sounds/" + clicks.clickOn,
          ],
        });
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice3[0],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice3[1],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 2:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice3[2],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 3:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice3[3],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 4:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice3[4],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          case 5:
            sound = new Howl({
              src: [
                "../../sounds/" +
                  mod2_audios_lessons.exercice3[5],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            });
            break;
          default:
            break;
        }
      } else if (exo_id(exerciceId) === 4) {
        clickOn = new Howl({
          src: [
            "../../sounds/" + clicks.clickRight,
          ],
        });
        switch (counter) {
          default:
            sound = new Howl({
              src: ["../../sounds/" + mod2_audios_lessons.exercice4],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
        }
      } else if (exo_id(exerciceId) === 5) {
        switch (counter) {
          default:
            sound = new Howl({
              src: ["../../sounds/" + mod1_audios.lesson5],
            });
            sound.play();
            break;
        }
      }
    } else if (moduleId === "module3") {
      clickOn = new Howl({
        src: [
          "../../sounds/" + clicks.clickRight,
        ],
      });
      if (exo_id(exerciceId) === 1) {
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod3_audios.exercice1[0],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod3_audios.exercice1[1],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 2:
            sound = new Howl({
              src: [
                "../../sounds/" + mod3_audios.exercice1[2],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;

          default:
            break;
        }
      } else if (exo_id(exerciceId) === 2) {
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod3_audios.exercice2[0],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod3_audios.exercice2[1],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 2:
            sound = new Howl({
              src: [
                "../../sounds/" + mod3_audios.exercice2[2],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 3:
            sound = new Howl({
              src: [
                "../../sounds/" + mod3_audios.exercice2[3],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;

          default:
            break;
        }
      } else if (exo_id(exerciceId) === 3) {
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod3_audios.exercice3[0],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod3_audios.exercice3[1],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 2:
            sound = new Howl({
              src: [
                "../../sounds/" + mod3_audios.exercice3[2],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;

          default:
            break;
        }
      }
    } else if (moduleId === "module4") {
      if (exo_id(exerciceId) === 1) {
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod4_audios.exercice1[0],
              ],
            });
            sound.play();
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod4_audios.exercice1[1],
              ],
            });
            sound.play();
            break;

          default:
            break;
        }
      } else if (exo_id(exerciceId) === 2) {
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod4_audios.exercice2[0],
              ],
            });
            sound.play();
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod4_audios.exercice2[1],
              ],
            });
            sound.play();
            break;

          default:
            break;
        }
      } else if (exo_id(exerciceId) === 3) {
        clickOn = new Howl({
          src: [
            "../../sounds/" + clicks.clickStep,
          ],
        });
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod4_audios.exercice3[0],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod4_audios.exercice3[1],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 2:
            sound = new Howl({
              src: [
                "../../sounds/" + mod4_audios.exercice3[2],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 3:
            sound = new Howl({
              src: [
                "../../sounds/" + mod4_audios.exercice3[3],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;

          default:
            break;
        }
      } else if (exo_id(exerciceId) === 4) {
        clickOn = new Howl({
          src: [
            "../../sounds/" + clicks.clickRight,
          ],
        });
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod4_audios.exercice4[0],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod4_audios.exercice4[1],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 2:
            sound = new Howl({
              src: [
                "../../sounds/" + mod4_audios.exercice4[2],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;

          default:
            break;
        }
      } else if (exo_id(exerciceId) === 5) {
        clickOn = new Howl({
          src: [
            "../../sounds/" + clicks.clickRight,
          ],
        });
        switch (counter) {
          default:
            sound = new Howl({
              src: ["../../sounds/" + mod4_audios.exercice5],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
        }
      } 
    } else if (moduleId === "module6") {
      if (exo_id(exerciceId) === 1) {
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod6_audios.exercice1[0],
              ],
            });
            sound.play();
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod6_audios.exercice1[1],
              ],
            });
            sound.play();
            break;

          default:
            break;
        }
      } else if (exo_id(exerciceId) === 2) {
        clickOn = new Howl({
          src: [
            "../../sounds/" + clicks.clickRight,
          ],
        });

        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod6_audios.exercice2[0],
              ],
            });

            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          default:
            break;
        }
      } else if (exo_id(exerciceId) === 3) {
        clickOn = new Howl({
          src: [
            "../../sounds/" + clicks.clickStep,
          ],
        });
        switch (counter) {
          case 0:
            sound = new Howl({
              src: [
                "../../sounds/" + mod6_audios.exercice3[0],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 1:
            sound = new Howl({
              src: [
                "../../sounds/" + mod6_audios.exercice3[1],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;
          case 2:
            sound = new Howl({
              src: [
                "../../sounds/" + mod6_audios.exercice3[2],
              ],
            });
            
            clickOn.play();

            clickOn.on("end", () => {
              sound.play();
            }
            );
            break;

          default:
            break;
        }
      }
    }
  };

  const onSelectionAction = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 0);
      return prev + 1;
    });
  };

  const onCopyAction = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 1);
      return prev + 1;
    });
  };
  const onPasteAction = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 2);
      return prev + 1;
    });
  };

  ///////////////////////////////

  const onGoToPathAction = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 0);
      return prev + 1;
    });
  };
  
  const onCreateFolderAction = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 1);
      return prev + 1;
    });
  };
  const onNameFolderAction = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 2);
      return prev + 1;
    });
  };
  const onDeleteFolderAction = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 3);
      return prev + 1;
    });
  };

  /////////////////////////?//

  const handel_wifiConnection_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 0);
      return prev + 1;
    });
  };
  const handel_batterie_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 1);
      return prev + 1;
    });
  };
  const handel_sound_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 2);
      return prev + 1;
    });
  };
  const handel_other_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 3);
      return prev + 1;
    });
  };

  //////////////////////////////

  const handle_phoneOpenOnclick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 0);
      return prev + 1;
    });
  };

  const handle_otherThan_phoneOpenOnclick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 1);
      return prev + 1;
    });
  };

  const handle_nav_home_onClick = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 8);
      return prev + 1;
    });
  };

  const handleOnCpuChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 2);
      return prev + 1;
    });
  };

  const handleOnKeyboardChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 0);
      return prev + 1;
    });
  };

  const handleOnMouseChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 3);
      return prev + 1;
    });
  };

  const handleOnScreenChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 1);
      return prev + 1;
    });
  };

  // -------------- lesson 2

  const handleOnKeyboardChoiceEvt_lesson2 = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 0);
      return prev + 1;
    });
  };

  const handleOnMouseChoiceEvt_lesson2 = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 2);
      return prev + 1;
    });
  };

  const handleOnScreenChoiceEvt_lesson2 = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 1);
      return prev + 1;
    });
  };

  // -------------- lesson 3

  const handleOnMotherBoardChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 0);
      return prev + 1;
    });
  };

  const handleOnCPUChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 1);
      return prev + 1;
    });
  };

  const handleOnRamChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 2);
      return prev + 1;
    });
  };

  const handleOnHardDiskChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 3);
      return prev + 1;
    });
  };

  const handleOnPowerChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 4);
      return prev + 1;
    });
  };

  const handleOnGraphikCardChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 5);
      return prev + 1;
    });
  };

  const handleOnFanChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 6);
      return prev + 1;
    });
  };

  const handleOnSoundCardChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 7);
      return prev + 1;
    });
  };

  const handleOnNetworkCardChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 8);
      return prev + 1;
    });
  };

  const handleOnUsbOutputChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 9);
      return prev + 1;
    });
  };

  const handleOnHdmiOutputChoiceEvt = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 10);
      return prev + 1;
    });
  };

  // --------- lesson 4
var index ;
  const handleOnClickExercice4 = () => {
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 0);
      return prev + 1;
    });
  };

  const handleOnDoubleClickExercice4 = () => {
    setCounter((prev) => {
        goodOrBad_Manager(prev.toString(), 2);
        return prev + 1;
      });
    if(index){
      clearTimeout(index);
    }
  };

  const handleOnContextMenuExercice4 = (e) => {
    e.preventDefault();
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 1);
      return prev + 1;
    });
  };
  const handleOnContextMenuSecond = (e) => {
    e.preventDefault();
    setCounter((prev) => {
      goodOrBad_Manager(prev.toString(), 3);
      return prev + 1;
    });
  };

  const handleOnclickSecond =(e)=>{
    if(!index){
      index=setTimeout(()=>{
        setCounter((prev)=>{
          goodOrBad_Manager(prev.toString(),3);
          return prev + 1  
        })
      },500,[])
    }
    
  }

  const handelSetModalOpen = (bool) => {
    setModalOpen(bool);
  };

  const handleReturnHome = () => {
    Howler.stop();
    navigate("/" + moduleId + "/exercices", { replace: true });
  };

  const getTextField_Value = () => {
    var input_value = document.getElementById("input_text").value;

    if (quotes.includes(input_value) && visited_quotes.includes(input_value)) {
      setCounter((prev) => {
        let elm = document.getElementById("item" + prev.toString());
        setBadAnswerCount((prev) => prev + 1);
        setVisited((visited_already) => {
          const newValues = [...visited_already];
          newValues[prev] = input_value;
          return newValues;
        });
        setCount_color((color_bool) => {
          const newValues = [...color_bool];
          newValues[prev] = "badAnswer";
          return newValues;
        });
        elm.classList.add("badAnswer");
        return prev + 1;
      });

      setAnswerQuestions((questions) => {
        const newValues = [...questions];
        newValues[counter] = lessonExoId_focus;
        return newValues;
      });
    } else if (
      quotes.includes(input_value) &&
      !visited_quotes.includes(input_value)
    ) {
      var single_quote = document.getElementById("item_" + input_value);

      setCounter((prev) => {
        let elm = document.getElementById("item" + prev.toString());
        setGoodAnswerCount((prev) => prev + 1);
        setVisited((visited_already) => {
          const newValues = [...visited_already];
          newValues[prev] = input_value;
          return newValues;
        });
        setCount_color((color_bool) => {
          const newValues = [...color_bool];
          newValues[prev] = "goodAnswer";
          return newValues;
        });
        elm.classList.add("goodAnswer");
        return prev + 1;
      });
      single_quote.classList.add("quote_visited");

      setAnswerQuestions((questions) => {
        const newValues = [...questions];
        newValues[counter] = lessonExoId_focus;
        return newValues;
      });
    } else if (
      !quotes.includes(input_value) &&
      !visited_quotes.includes(input_value)
    ) {
      setCounter((prev) => {
        let elm = document.getElementById("item" + prev.toString());
        setBadAnswerCount((prev) => prev + 1);
        setVisited((visited_already) => {
          const newValues = [...visited_already];
          newValues[prev] = input_value;
          return newValues;
        });
        setCount_color((color_bool) => {
          const newValues = [...color_bool];
          newValues[prev] = "badAnswer";
          return newValues;
        });
        elm.classList.add("badAnswer");
        return prev + 1;
      });

      setAnswerQuestions((questions) => {
        const newValues = [...questions];
        newValues[counter] = lessonExoId_focus;
        return newValues;
      });
    }

    document.getElementById("input_text").value = "";
  };

  //Mod 2 Exo 3 random
  const imageIndices = [0, 1, 2, 3, 4];
  
  const [shuffledIndices, setShuffledIndices] = useState([]);
  
  useEffect(() => {
    const newShuffledIndices = [...imageIndices].sort(() => Math.random() - 0.5);
    setShuffledIndices(newShuffledIndices);
  }, []);
  
  const clickFunctions = [
    onClickExo3_stape0,
    onClickExo3_stape1,
    onClickExo3_stape2,
    onClickExo3_stape3,
    onClickExo3_stape4
  ];

  //Mod 3 Exo 1 random
  const imageIndicesMod3 = [1, 2];

  const [shuffledIndicesMod3, setShuffledIndicesMod3] = useState([]);

  useEffect(() => {
    const newShuffledIndicesMod3 = [...imageIndicesMod3].sort(() => Math.random() - 0.5);
    setShuffledIndicesMod3(newShuffledIndicesMod3);
  }, []);

  const clickFunctionsMod3 = [
    onGoToPathAction,
    onCopyAction,
    onPasteAction
  ];

  //Mod 3 Exo 2 random
  const imageIndicesMod3Exo2 = [0, 1, 2, 3];

  const [shuffledIndicesMod3Exo2, setShuffledIndicesMod3Exo2] = useState([]);

  useEffect(() => {
    const newShuffledIndicesMod3Exo2 = [...imageIndicesMod3Exo2].sort(() => Math.random() - 0.5);
    setShuffledIndicesMod3Exo2(newShuffledIndicesMod3Exo2);
  }, []);

  const clickFunctionsMod3Exo2 = [
    onGoToPathAction,
    onCreateFolderAction,
    onNameFolderAction,
    onDeleteFolderAction
  ];

  //Mod 4 Exo 1 random
  const imageIndicesMod4Exo1 = [0, 1];

  const [shuffledIndicesMod4Exo1, setShuffledIndicesMod4Exo1] = useState([]);

  useEffect(() => {
    const newShuffledIndicesMod4Exo1 = [...imageIndicesMod4Exo1].sort(() => Math.random() - 0.5);
    setShuffledIndicesMod4Exo1(newShuffledIndicesMod4Exo1);
  }, []);

  const clickFunctionsMod4Exo1 = [
    onSelectionAction,
    onCopyAction
  ];

  //Mod 4 Exo 2 random
  const imageIndicesMod4Exo2 = [0, 1];

  const [shuffledIndicesMod4Exo2, setShuffledIndicesMod4Exo2] = useState([]);

  useEffect(() => {
    const newShuffledIndicesMod4Exo2 = [...imageIndicesMod4Exo2].sort(() => Math.random() - 0.5);
    setShuffledIndicesMod4Exo2(newShuffledIndicesMod4Exo2);
  }, []);

  const clickFunctionsMod4Exo2 = [
    onGoToPathAction,
    onCreateFolderAction,
  ];

  //Mod 4 Exo 3 random
  const imageIndicesMod4Exo3 = [0, 1, 2, 3];

  const [shuffledIndicesMod4Exo3, setShuffledIndicesMod4Exo3] = useState([]);

  useEffect(() => {
    const newShuffledIndicesMod4Exo3 = [...imageIndicesMod4Exo3].sort(() => Math.random() - 0.5);
    setShuffledIndicesMod4Exo3(newShuffledIndicesMod4Exo3);
  }, []);

  const clickFunctionsMod4Exo3 = [
    onGoToPathAction,
    onCreateFolderAction,
    onNameFolderAction,
    onDeleteFolderAction
  ];

  //Mod 6 Exo 1 random
  const imageIndicesMod6Exo1 = [0, 1];

  const [shuffledIndicesMod6Exo1, setShuffledIndicesMod6Exo1] = useState([]);
  useEffect(() => {
    const newShuffledIndicesMod6Exo1 = [...imageIndicesMod6Exo1].sort(() => Math.random() - 0.5);
    setShuffledIndicesMod6Exo1(newShuffledIndicesMod6Exo1);
  }, []);

  const clickFunctionsMod6Exo1 = [
    onSelectionAction,
    onCopyAction
  ];

  //Mod 6 Exo 2 random
  const imageIndicesMod6Exo2 = [0, 1];
  const shuffledIndicesMod6Exo2 = [...imageIndicesMod6Exo2].sort(() => Math.random() - 0.5);
  const clickFunctionsMod6Exo2 = [
    onGoToPathAction,
    onCreateFolderAction,
  ];

  //Mod 6 Exo 3 random
  const imageIndicesMod6Exo3 = [0, 1, 2];

  const [shuffledIndicesMod6Exo3, setShuffledIndicesMod6Exo3] = useState([]);

  useEffect(() => {
    const newShuffledIndicesMod6Exo3 = [...imageIndicesMod6Exo3].sort(() => Math.random() - 0.5);
    setShuffledIndicesMod6Exo3(newShuffledIndicesMod6Exo3);
  }, []);

  const clickFunctionsMod6Exo3 = [
    onGoToPathAction,
    onCreateFolderAction,
    onNameFolderAction,
  ];


  return (
    <div className="exercice_conteiner">
      <div className="returnHomeBtn">
        <CircleButton
          onClickEvt={handleReturnHome}
          img={images_store.general.backHomeImage}
        />

        <h2 className="exercice_Title">
          <div
            className="col"
            dangerouslySetInnerHTML={{
              __html: stringFormer(
                everySingleExerciceTitle(moduleId)[lessonExoId - 1]
              ),
            }}
          />
        </h2>
      </div>

      {moduleId === "module2" && lessonExoId !== 3 && (
        <div className="flex">
          <div className="flex_column" style={{ width: "35vw", zIndex: "1" }}>
            <div className="exo_count_point_div" style={{ width: "75%" }}>
            {[...Array(questionNumber)].map((x, i) => (
              <div key={i} id={"item" + i.toString()} className="exo_pointer"></div>
            ))}
            </div>

            <div className="question_zone" style={{ width: "75%" }}>
              <StringWithSound
                string={questionsMod2[lessonExoId - 1].questions[counter]}
                onAudioPlay={Handle_onAudioPlay}
                hasAudio={true}
              />
            </div>
          </div>

          <div>
          {lessonExoId === 2 && (
            <div
              className="mod2_lesson1_ImgPatter"
              style={{ marginLeft: "-30%" }}
            >
              <VerticaleImage
                img={"/imgs/" + images_store.mod2.lessons[2].imgs[0]}
                phoneApp_onClick={handle_phoneApp_onClick}
                msg_onClick={handle_msg_onClick}
                camera_onClick={handle_camera_onClick}
                galerie_onClick={handle_galerie_onClick}
                horloge_onClick={handle_horloge_onClick}
                contact_onClick={handle_contact_onClick}
                setting_onClick={handle_setting_onClick}
                calandar_onClick={handle_calandar_onClick}
                clc_onClick={handle_clc_onClick}
                nav_home_onClick={handle_nav_home_onClick}
                requestExercice2={true}
                lessonId_into={3}
                currentIndex={2}
                is_several={false}
              />
            </div>
          )}

          {lessonExoId === 1 && (
            <div
              className="mod2_lesson1_ImgPatter"
              style={{ marginLeft: "-30%" }}
            >
              <VerticaleImage
                phoneOpen_onClick={handle_phoneOpenOnclick}
                airplaine_onClick={handle_otherThan_phoneOpenOnclick}
                shutdown_onClick={handle_otherThan_phoneOpenOnclick}
                isExo1openphone={true}
                img={"/imgs/" + images_store.mod2.lessons[1].imgs[0]}
              />
            </div>
          )}

          {lessonExoId === 4 && (
            <div
              className="mod2_lesson1_ImgPatter"
              style={{ marginLeft: "-30%" }}
            >
              <VerticaleImage
                onExo4screen_capture={true}
                falseScreenCap_onClick={handle_fasleScreenCap_onClick}
                goodScreenCap_onClick={handle_goodScreenCap_onClick}
                img={"/imgs/" + images_store.mod2.lessons[4].imgs[0]}
              />
            </div>
          )}
        </div>
      </div>
    )}

    {moduleId === "module2" && lessonExoId === 3 && (
      <>
        <div className="flex_column">
          <div className="exo_count_point_div" style={{ width: "75%" }}>
          {[...Array(questionNumber)].map((x, i) => (
            <div key={i} id={"item" + i.toString()} className="exo_pointer"></div>
          ))}
          </div>

          <div className="question_zone">
            <StringWithSound
              string={questionsMod2[lessonExoId - 1].questions[counter]}
              hasAudio={true}
              onAudioPlay={Handle_onAudioPlay}
            />
          </div>
        </div>

        {lessonExoId === 3 && (
          <div className="exos_conteiner" style={{ height: "auto", translate: "5% -10% 0", scale: "0.9", gap: "5%" }}>
            {shuffledIndices.map((index) => (
              <div key={index} onClick={clickFunctions[index]}>
                <TransformWrapper>
                  <TransformComponent>
                    <img
                      className="img_exercice_exo"
                      src={"/imgs/" + images_store.mod2.lessons[3].imgs[index]}
                      alt=""
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            ))}
          </div>
        )}
      </>
    )}

    {moduleId !== "module2" && (
      <div className="exo_count_point_div">
        {[...Array(questionNumber)].map((x, i) => (
          <div key={i} id={"item" + i.toString()} className="exo_pointer"></div>
        ))}
      </div>
    )}

      {moduleId === "module1" && (
        <div className="col">
          <div className="col-2"></div>

          <div className="pt-1 question_zone" style={{}}>
            <StringWithSound
              string={stringFormer(question_returner(exo_id_bool(lessonExoId)))}
              onAudioPlay={Handle_onAudioPlay}
              hasAudio={true}
            />
          </div>
          <div className="col-3"></div>
        </div>
      )}

      {moduleId === "module3" && (
        <div
          className="question_zone"
          style={{ marginTop: "1%", marginBottom: "4%" }}
        >
          <StringWithSound
            string={questionsMod3[lessonExoId - 1].questions[counter]}
            onAudioPlay={Handle_onAudioPlay}
            hasAudio={true}
          />
        </div>
      )}
      {moduleId === "module4" && (
        <div
          className="question_zone"
          style={{ marginTop: "1%", marginBottom: "4%" }}
        >
          <StringWithSound
            string={questionsMod4[lessonExoId - 1].questions[counter]}
            onAudioPlay={Handle_onAudioPlay}
            hasAudio={true}
          />
        </div>
      )}
      {moduleId === "module6" && (
        <div
          className="question_zone"
          style={{ marginTop: "1%", marginBottom: "4%" }}
        >
          <StringWithSound
            string={questionsMod6[lessonExoId - 1].questions[counter]}
            onAudioPlay={Handle_onAudioPlay}
            hasAudio={true}
          />
        </div>
      )}

      {/* ==================== The Modal ======================= */}

      <Modal
        isActive={modalOpen}
        color_array={count_color}
        bonneReponse={goodAnswerCount}
        mauvaiseReponse={badAnswerCount}
        onReload={handleOnDefault_stateBehaviour}
        setOpenModal={handelSetModalOpen}
      />

      {/* ======================= The Modal ======================= */}

      {moduleId === "module1" &&
        (exo_id_bool(lessonExoId) ? (
          <div className="exercice5conteiner">
            <div className="quotes_conteiner">
              {quotes.map((x, i) => (
                <div key={i} id={"item_" + x} className="quotes">
                  <SingleQuote title={x} />
                </div>
              ))}
            </div>

            <div className="input_holder">
              <input
                type="text"
                id="input_text"
                className="textfield"
                placeholder={mod1_exercice5.write_here}
              />
              <button onClick={getTextField_Value} className="validate_btn">
                {validate_Text}
              </button>
            </div>
          </div>
        ) : (
          <LessonImage
            img={questionsMod1[lessonExoId - 1].img}
            // --------------- bool -----------------
            isExo={true}
            isLesson1Current={lessonExoId === 1 ? true : false}
            isLesson2Current={lessonExoId === 2 ? true : false}
            isLesson3Current={lessonExoId === 3 ? true : false}
            isLesson4Current={false}
            isLesson4ExerciceCurrent={lessonExoId === 4 ? true : false}
            isLesson4DoubleClickExerciceCurrent={counter === 2 ? true : false}
            // --------------- lesson 1 -----------------
            handleScreenChoiceEvt={handleOnScreenChoiceEvt}
            handleCentralUnitChoiceEvt={handleOnCpuChoiceEvt}
            handleKeyboardChoiceEvt={handleOnKeyboardChoiceEvt}
            handleMouseChoiceEvt={handleOnMouseChoiceEvt}
            // --------------- lesson 2 -----------------
            handleScreenChoiceEvt_lesson2={handleOnScreenChoiceEvt_lesson2}
            handleKeyboardChoiceEvt_lesson2={handleOnKeyboardChoiceEvt_lesson2}
            handleMouseChoiceEvt_lesson2={handleOnMouseChoiceEvt_lesson2}
            // --------------- lesson 3 -----------------
            handleMotherBoardEvt={handleOnMotherBoardChoiceEvt}
            handleCPUEvt={handleOnCPUChoiceEvt}
            handleRamEvt={handleOnRamChoiceEvt}
            handleHardDiskEvt={handleOnHardDiskChoiceEvt}
            handlePowerEvt={handleOnPowerChoiceEvt}
            handleGraphikCardEvt={handleOnGraphikCardChoiceEvt}
            handleFanEvt={handleOnFanChoiceEvt}
            handleSoundCardEvt={handleOnSoundCardChoiceEvt}
            handleNetworkCardEvt={handleOnNetworkCardChoiceEvt}
            handleUsbOutputEvt={handleOnUsbOutputChoiceEvt}
            handleHdmiOutputEvt={handleOnHdmiOutputChoiceEvt}
            // --------------- lesson 3 -----------------
            exercice4FirstDivOnClick={handleOnClickExercice4}
            exercice4SecondOnclick ={handleOnclickSecond}
            exercice4MenuSecond={handleOnContextMenuSecond}
            exercice4SecondDivOnDoubleClick={handleOnDoubleClickExercice4}
            exercice4FirstDivOnContexMenuClick={(e) =>
              handleOnContextMenuExercice4(e)
            }
          />
        ))}

      {moduleId === "module3" && (
        <div>
          {lessonExoId === 1 && (
            <div className="exos_conteiner">
              <div onClick={onSelectionAction} className="ExerciceCards">
                <TransformWrapper>
                  <TransformComponent>
                    <img
                      className="img_exercice_exo3"
                      src={"/imgs/" + images_store.mod3.lessons[0].imgs[0]}
                      alt=""
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
              {shuffledIndicesMod3.map((index) => (
                <div key={index} onClick={clickFunctionsMod3[index]} className="ExerciceCards">
                  <TransformWrapper>
                    <TransformComponent>
                      <img
                        className="img_exercice_exo3"
                        src={"/imgs/" + images_store.mod3.lessons[0].imgs[index]}
                        alt=""
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              ))}
            </div>
          )}
          {lessonExoId === 2 && (
            <div style={{ marginTop: "-3%" }}>
              <div className="exos_conteiner">
                {shuffledIndicesMod3Exo2.map((index) => (
                  <div key={index} onClick={clickFunctionsMod3Exo2[index]} className="ExerciceCards33">
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          className="img_exercice_exo3"
                          src={"/imgs/" + images_store.mod3.lessons[1].imgs[index]}
                          alt=""
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                ))}
              </div>
            </div>
          )}

          {lessonExoId === 3 && (
            <div>
              <div className="mod2_ImgPatter">
                <HImageMod3
                  is_several={false}
                  isExercice3={true}
                  wifiConnection_onClick={handel_wifiConnection_onClick}
                  batterie_onClick={handel_batterie_onClick}
                  sound_onClick={handel_sound_onClick}
                  other_onClick={handel_other_onClick}
                  img={"/imgs/" + images_store.mod3.lessons[3].imgs[0]}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {moduleId === "module4" && (
        <div>
          {lessonExoId === 1 && (
            <div className="exos_conteiner" style={{ justifyContent: "center" }}>
              {shuffledIndicesMod4Exo1.map((index) => (
                  <div key={index} onClick={clickFunctionsMod4Exo1[index]} className="ExerciceCards">
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          className="img_exercice_exo3"
                          src={"/imgs/" + images_store.mod4.lessons[0].imgs[index]}
                          alt=""
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                ))}
            </div>
          )}
          {lessonExoId === 2 && (
            <div className="exos_conteiner" style={{ justifyContent: "center" }}>
              {shuffledIndicesMod4Exo2.map((index) => (
                  <div key={index} onClick={clickFunctionsMod4Exo2[index]} className="ExerciceCards33">
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          className="img_exercice_exo33"
                          src={"/imgs/" + images_store.mod4.lessons[1].imgs[index]}
                          alt=""
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
              ))}
            </div>
          )}
          {lessonExoId === 3 && (
            <div style={{ marginTop: "-3%" }}>
              <div className="exos_conteiner">
                {shuffledIndicesMod4Exo3.map((index) => (
                  <div key={index} onClick={clickFunctionsMod4Exo3[index]} className="ExerciceCards33">
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          className="img_exercice_exo33"
                          src={"/imgs/" + images_store.mod4.lessons[2].imgs[index]}
                          alt=""
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
              ))}
              </div>
            </div>
          )}
          {lessonExoId === 4 && (
            <div>
              <div className="mod2_ImgPatter" style={{width:'1%',marginLeft:'38%'}}>
                <HImageMod4
                  is_several={false}
                  isExercice3={true}
                  wifiConnection_onClick={handel_wifiConnection_onClick}
                  batterie_onClick={handel_batterie_onClick}
                  sound_onClick={handel_sound_onClick}
                //  other_onClick={handel_other_onClick}
                  img={"/imgs/" + images_store.mod4.lessons[3]}
                />
              </div>
            </div>
          )}
          {lessonExoId === 5 && (
            <div>
              <div className="mod2_ImgPatter" style={{width:'1%',marginLeft:'38%'}}>
                <HImageMod4
                  is_several={false}
                  isExercice4={true}
                  wifiConnection_onClick={handel_wifiConnection_onClick}
                  other_onClick={handel_other_onClick}
                  img={"/imgs/" + images_store.mod4.lessons[4].imgs[0]}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {
        moduleId == "module5" &&(
          <div className="col ">
            <QUIZ/>
          </div>
        )
      }
      {
        moduleId === "module6" &&(
          <div className="col">
            <div>
          {lessonExoId === 1 && (
            <div className="exos_conteiner" style={{ justifyContent: "center" }}>
              {shuffledIndicesMod6Exo1.map((index) => (
                  <div key={index} onClick={clickFunctionsMod6Exo1[index]} className="ExerciceCards">
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          className="img_exercice_exo3"
                          src={"/imgs/" + images_store.mod6.lessons[0].imgs[index]}
                          alt=""
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
              ))}
            </div>
          )}
          {lessonExoId === 2 && (
            <div style={{ marginTop: "3%" }}>
              <div className="exos_conteiner" style={{ justifyContent: "center" }}>
                <div onClick={onGoToPathAction} className="ExerciceCards33">
                  <TransformWrapper>
                      <TransformComponent>
                      <img
                        className="img_exercice_exo33"
                        src={"/imgs/" + images_store.mod6.lessons[1]}
                        alt=""
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
                <div onClick={onCreateFolderAction} className="ExerciceCards33">
                  <TransformWrapper>
                      <TransformComponent>
                      <img className="img_exercice_exo33"
                      src={"/imgs/" + images_store.mod4.lessons[1].imgs[1]}
                      alt="" />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              </div>
            </div>
          )}
          {lessonExoId === 3 && (
            <div style={{ marginTop: "5%" }}>
              <div className="exos_conteiner">
              {shuffledIndicesMod6Exo3.map((index) => (
                  <div key={index} onClick={clickFunctionsMod6Exo3[index]} className="ExerciceCards33">
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          className="img_exercice_exo33"
                          src={"/imgs/" + images_store.mod3.lessons[0].imgs[index]}
                          alt=""
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                ))}
              </div>
            </div>
          )}
          {lessonExoId === 4 && (
            <div>
              <div className="mod2_ImgPatter" style={{width:'1%',marginLeft:'30%',marginTop:'5%'}}>
                <HImageMod4
                  is_several={false}
                  //isExercice3={true}
                  // wifiConnection_onClick={handel_wifiConnection_onClick}
                  // batterie_onClick={handel_batterie_onClick}
                  // sound_onClick={handel_sound_onClick}
                //  other_onClick={handel_other_onClick}
                  img={"/imgs/" + images_store.mod6.lessons[3]}
                />
              </div>
            </div>
          )}
          {lessonExoId === 5 && (
            <div style={{ marginTop: "5%" }}>
            <div className="exos_conteiner">
            
              <div onClick={onCreateFolderAction} className="ExerciceCards33">
                <TransformWrapper>
                  <TransformComponent>
                    <img
                      className="img_exercice_exo33"
                      src={"/imgs/" + images_store.mod6.lessons[4].imgs[1]}
                      alt=""
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
              
              <div onClick={onGoToPathAction} className="ExerciceCards33">
                <TransformWrapper>
                  <TransformComponent>
                    <img
                      className="img_exercice_exo33"
                      src={"/imgs/" + images_store.mod6.lessons[4].imgs[0]}
                      alt=""
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            </div>
          </div>
          )}
        </div>
          </div>
        )
      }
    </div>
  );
}

const exo_id = (exerciceId) => {
  let id;

  if (exerciceId === "E1") {
    id = 1;
  } else if (exerciceId === "E2") {
    id = 2;
  } else if (exerciceId === "E3") {
    id = 3;
  } else if (exerciceId === "E4") {
    id = 4;
  } else if (exerciceId === "E5") {
    id = 5;
  } else if (exerciceId === "E6") {
    id = 6;
  } else if (exerciceId === "E7") {
    id = 7;
  } else if (exerciceId === "E8") {
    id = 8;
  }

  return id;
};

const exo_id_bool = (exerciceId) => {
  let id;

  if (exerciceId === 5) {
    id = true;
  } else {
    id = false;
  }

  return id;
};

const exoFocus_id = (exerciceId) => {
  let id;

  if (exerciceId === 1) {
    id = getRandomInt(4);
  } else if (exerciceId === 2) {
    id = getRandomInt(3);
  } else if (exerciceId === 3) {
    id = getRandomInt(11);
  } else if (exerciceId === 4) {
    id = getRandomInt(3);
  }
  return id;
};

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function stringFormer(string) {
  if (string == null) {
    return "";
  } else {
    const txt = string.replace(/<[^>]+>/g, "");
    return txt;
  }
}

export default Exercice;
