import React from 'react'

function SingleQuote(props) {
  return (
    <div className='singleQuote'>
        {props.title}
    </div>
  )
}

export default SingleQuote