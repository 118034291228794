import React from "react";
import images_store from "../utils/Images";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function HImageMod3(props) {
  const applyDisplayNone = {
    display: "none",
  };
  const slideStyles = {
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  let slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${props.img})`,
  };

  const goToPrevious = () => {
    const isFirstSlide = props.currentIndex === 0;
    const newIndex = isFirstSlide
      ? props.lessonArrayLenght - 1
      : props.currentIndex - 1;
    props.setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = props.currentIndex === props.lessonArrayLenght - 1;
    const newIndex = isLastSlide ? 0 : props.currentIndex + 1;
    props.setCurrentIndex(newIndex);
  };

  const goToPrevious_inside = () => {
    const isFirstSlide = props.currentIndex_several === 0;
    const newIndex = isFirstSlide
      ? props.several_lenght
      : props.currentIndex_several - 1;
    props.setCurrentIndex_several(newIndex);
  };

  const goToNext_inside = () => {
    const isLastSlide = props.currentIndex_several === props.several_lenght;
    const newIndex = isLastSlide ? 0 : props.currentIndex_several + 1;
    props.setCurrentIndex_several(newIndex);
  };

  return (
    <div className="pattern">
      <div
        style={props.is_several ? null : applyDisplayNone}
        onClick={goToPrevious}
        className="leftArrow_VerticalImage_Styles"
      >
        ❰
      </div>

      <div
        style={
          props.is_several && props.several_focus ? null : applyDisplayNone
        }
        onClick={goToPrevious_inside}
        className="leftArrow_VerticalImage_Styles_inside"
      >
        ❰
      </div>

      <div className="Vertical_lessonImageConteiner">
        <div
          style={props.isExercice3 ? null : applyDisplayNone}
          className="circleRedImg_appsIcn wifi_connection"
          onClick={props.wifiConnection_onClick}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "2vw" }}
          />
        </div>

        <div
          style={props.isExercice3 ? null : applyDisplayNone}
          className="circleRedImg_appsIcn batterie"
          onClick={props.batterie_onClick}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "2vw" }}
          />
        </div>
        <div
          style={props.isExercice3 ? null : applyDisplayNone}
          className="circleRedImg_appsIcn sound"
          onClick={props.sound_onClick}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "2vw" }}
          />
        </div>
        <div
          style={props.isExercice3 ? null : applyDisplayNone}
          className="circleRedImg_appsIcn other"
          onClick={props.other_onClick}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "2vw" }}
          />
        </div>
        {/*Miise en forme*/}

        <div className="col" style={{}}>
          <div
            style={props.lessonId_into === 4 ? null : applyDisplayNone}
            className="circleRedImg text_gras"
            onClick={props.handleKeyboardChoiceEvt}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "2vw" }}
            />
          </div>
          <div
            style={props.lessonId_into === 4 ? null : applyDisplayNone}
            className="circleRedImg choix_police"
            onClick={props.handleScreenChoiceEvt_lesson2}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "2vw" }}
            />
          </div>
          <div
            style={props.lessonId_into === 4 ? null : applyDisplayNone}
            className="circleRedImg surligner_texte"
            onClick={props.handleMouseChoiceEvt}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={props.lessonId_into === 4 ? null : applyDisplayNone}
            className="circleRedImg aligner_texte"
            
            onClick={props.handleCentralUnitChoiceEvt}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "2vw" }}
            />
          </div>
          <div
            style={props.lessonId_into === 4 ? null : applyDisplayNone}
            className="circleRedImg inserer_liste"
            onClick={props.handleScreenChoiceEvt}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "2vw" }}
            />
          </div>
          {/* <div
            style={props.isLesson4Current ? null : applyDisplayNone}
            className="circleRedImg deplacer_gauche"
            onClick={props.setting_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div> */}
          <div
            style={props.lessonId_into === 4 ? null : applyDisplayNone}
            className="circleRedImg choisir_couleur_txt"
            onClick={props.handleKeyboardChoiceEvt_lesson2}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
        </div>
        {/*Miise en forme*/}

        <div>
              <img
                width="100%"
                className="Vertical_Imag"
                style={slideStylesWidthBackground}
                src={props.img}
                alt="keyboard"
              />
        </div>
      </div>

      <div
        style={
          props.is_several && props.several_focus ? null : applyDisplayNone
        }
        onClick={goToNext_inside}
        className="rightArrow_VerticalImage_Styles_inside"
      >
        ❱
      </div>

      <div
        style={props.is_several ? null : applyDisplayNone}
        onClick={goToNext}
        className="rightArrow_VerticalImage_Styles"
      >
        ❱
      </div>
    </div>
  );
}

export default HImageMod3;
