import React from "react";
import images_store from "../utils/Images";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import choix from "../assets/rond_choix.png";
import { Howl, Howler } from "howler";

function HImageMod4(props) {
  const applyDisplayNone = {
    display: "none",
  };
  const slideStyles = {
    width: "43vw",
    height: "25vw",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
  };

  let slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${props.img})`,
  };


  const goToPrevious = () => {
    const isFirstSlide = props.currentIndex === 0;
    const newIndex = isFirstSlide
      ? props.lessonArrayLenght - 1
      : props.currentIndex - 1;
    props.setCurrentIndex(newIndex);
    Howler.stop();
  };

  const goToNext = () => {
    const isLastSlide = props.currentIndex === props.lessonArrayLenght - 1;
    const newIndex = isLastSlide ? 0 : props.currentIndex + 1;
    props.setCurrentIndex(newIndex);
    Howler.stop();
  };

  const goToPrevious_inside = () => {
    const isFirstSlide = props.currentIndex_several === 0;
    const newIndex = isFirstSlide
      ? props.several_lenght
      : props.currentIndex_several - 1;
    props.setCurrentIndex_several(newIndex);
  };

  const goToNext_inside = () => {
    const isLastSlide = props.currentIndex_several === props.several_lenght;
    const newIndex = isLastSlide ? 0 : props.currentIndex_several + 1;
    props.setCurrentIndex_several(newIndex);
  };

  return (
    <div className="vertical_pattern" >
      <div
        style={props.is_several ? null : applyDisplayNone}
        onClick={goToPrevious}
        className="leftArrow_VerticalImage_Styles1"
      >
        ❰
      </div>

      {/*<div
        style={
          props.is_several && props.several_focus ? null : applyDisplayNone
        }
        //onClick={goToPrevious_inside}
        className="leftArrow_VerticalImage_Styles_inside"
      >
        ❰
      </div>*/}

      <div className="Vertical_lessonImageConteiner">
        <div
          style={props.isExercice3 ? null : applyDisplayNone}
          className="circleRedImg_appsIcn imprimante_cible"
          onClick={props.wifiConnection_onClick}
        >
          <img src={choix} alt="" style={{ width: "1.3vw"}} />
        </div>

        <div
          style={props.isExercice3 ? null : applyDisplayNone}
          className="circleRedImg_appsIcn nbre_copies"
          onClick={props.batterie_onClick}
        >
          <img src={choix} alt="" style={{ width: "1.3vw" }} />
        </div>
        <div
          style={props.isExercice3 ? null : applyDisplayNone}
          className="circleRedImg_appsIcn rectro_verso"
          onClick={props.sound_onClick}
        >
          <img src={choix} alt="" style={{ width: "1.3vw" }}/>
        </div>
        <div
          style={props.isExercice4 ? null : applyDisplayNone}
          className="circleRedImg_appsIcn usb"
          onClick={props.wifiConnection_onClick}
        >
          <img src={choix} alt="" style={{ width: "2vw" }}/>
        </div>
        <div
          style={props.isExercice4 ? null : applyDisplayNone}
          className="circleRedImg_appsIcn others"
          onClick={props.other_onClick}
        >
          <img src={choix} alt="" style={{ width: "2vw" }}/>
        </div>

        <div>
          <img width="100%" style={slideStylesWidthBackground} src={props.img} alt="keyboard"/>
        </div>

      </div>
{
      /*<div
        style={
          props.is_several && props.several_focus ? null : applyDisplayNone
        }
        onClick={goToNext_inside}
        className="rightArrow_VerticalImage_Styles_inside"
      >
        ❱
      </div>*/}

      <div
        style={props.is_several ? null : applyDisplayNone}
        onClick={goToNext}
        className="rightArrow_VerticalImage_Styles"
      >
        ❱
      </div>
    </div>
  );
}

export default HImageMod4;
