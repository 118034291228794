import React from "react";

function IndicateurEtape(props) {
  
  // let activeStyle = {
  //   borderBottom: "3px solid #FE8D00",
  //   textDecoration: "none",
  // };

  return (
    <div
      onClick={props.handleOnclickBarEvt}
      className="stepBox"
    >
      <img src={props.image_src} alt="" style={{width:'2vw',height:"2vw"}} />
      <p style={{fontSize:'1vw'}}>{props.title}</p>
    </div>
  );
}

export default IndicateurEtape;
