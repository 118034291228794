import images_store from "./Images";
import bo_content from "../properties.json";
import { useEffect } from "react";

const card_content = [];


if (sessionStorage.getItem("Lang") === null) {
  sessionStorage.setItem("Lang", 0);
}
var LangID = sessionStorage.getItem("Lang")
//
function ChoisirCat(nbre) {
  sessionStorage.setItem("cat", nbre);
  if (nbre === 5) {
    sessionStorage.setItem("Exercice", "none");
  } else {
    sessionStorage.setItem("Exercice", "");
  }
}
//

bo_content[0].languages[1].domains[0].subdomains[0].sequences.map(
  (boContent, index) => {
    let cardIndex = index + 1;
    card_content.push({
      sequence_id: boContent.sequence_id,
      title: boContent.title,
      description: boContent.introduction,
      path: "/module" + cardIndex+"/lesson1",
      img_file: images_store.home[index],
    });
    return card_content;
  }
);



export default card_content;