import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { stepsTitle_module1, stepsTitle_module1_exercices } from "../utils/Etapes";
import images_store from "../utils/Images";

const ExercicesBoard = () => {
  let navigate = useNavigate();

  const { moduleId } = useParams();
  const { pageId } = useParams();

  const indexPlusOne = (index) => {
    return index + 1;
  };

  return (
    <div className="exos_board">
      {stepsTitle_module1_exercices.map((elt, index) => (
        <div key={index} className="ExerciceCards">
          <img
            className="listImg"
            onClick={() => {
              navigate(
                "/" +
                  moduleId +
                  "/" +
                  pageId +
                  "/E" +
                  indexPlusOne(index).toString(),
                { replace: true }
              );
            }}
            src='../img/schedule.png'
            alt=""
          />
          <p>{elt}</p>
        </div>
      ))}
    </div>
  );
};

export default ExercicesBoard;
