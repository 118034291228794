import React from "react";
import choix from "../assets/rond_choix.png";
import choix_circle from "../assets/cercle.png";
// import ReactPanZoom from "react-image-pan-zoom-rotate";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Alert } from "bootstrap";

function LessonImage(props) {
  
  const slideStyles = {
    width: "35vw",
    height: "20vw",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  let slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${props.img})`,
  };

  const applyDisplayNone = {
    display: "none",
  };

  let images = [];
  const value = props.isLesson4Current;

  if (value) {
    images = props.img;

    slideStylesWidthBackground = {
      ...slideStyles,
      backgroundImage: `url(${images[props.currentIndex]})`,
    };
  }

  const redButton = {
    backgroundColor: "#EB1D36",
    borderColor: "#EB1D36",
  };

  const grayButton = {
    backgroundColor: "#EEEEEE",
    borderColor: "#EEEEEE",
  };

  const goToPrevious = () => {
    clearDots();
    const isFirstSlide = props.currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : props.currentIndex - 1;
    props.setCurrentIndex(newIndex);

    var element = document.getElementById("dot" + newIndex.toString());
    element.classList.add("activeDot");
  };

  const goToNext = () => {
    clearDots();
    const isLastSlide = props.currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : props.currentIndex + 1;
    props.setCurrentIndex(newIndex);

    var element = document.getElementById("dot" + newIndex.toString());
    element.classList.add("activeDot");
  };

  const clearDots = () => {
    const dots = Array.from(document.getElementsByClassName("dot"));

    dots.forEach((dot) => {
      // ✅ Remove class from each element
      dot.classList.remove("activeDot");

      // ✅ Add class to each element
      // dot.classList.add('activeDot');
    });
  };

  const goToSlide = (evt, slideIndex) => {
    clearDots();
    evt.currentTarget.classList.add("activeDot");
    props.setCurrentIndex(slideIndex);
  };

  return (
    <div
      className={
        props.isExo ? "lessonImageConteiner_exo" : "lessonImageConteiner"
      }
    >
      {/* lesson1 divs */}

      <div className="">

      <div style={props.isLesson1Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg centralUnitChoice"
          onClick={props.handleCentralUnitChoiceEvt}
          src={choix}
          alt=""
        />
      </div>

      <div style={props.isLesson1Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg keyboardChoice"
          onClick={props.handleKeyboardChoiceEvt}
          src={choix}
          alt=""
        />
      </div>

      <div style={props.isLesson1Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg mouseChoice"
          onClick={props.handleMouseChoiceEvt}
          src={choix}
          alt=""
        />
      </div>
        </div>
      <div style={props.isLesson1Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg screenChoice"
          onClick={props.handleScreenChoiceEvt}
          src={choix}
          alt=""
        />
      </div>

      <div style={props.isLesson1Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg centralUnitChoice"
          onClick={props.handleCentralUnitChoiceEvt}
          src={choix}
          alt=""
        />
      </div>

      <div style={props.isLesson1Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg keyboardChoice"
          onClick={props.handleKeyboardChoiceEvt}
          src={choix}
          alt=""
        />
      </div>

      <div style={props.isLesson1Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg mouseChoice"
          onClick={props.handleMouseChoiceEvt}
          src={choix}
          alt=""
        />
      </div>

      {/* lesson2 divs */}

      <div style={props.isLesson2Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg screenChoice_lesson2"
          onClick={props.handleScreenChoiceEvt_lesson2}
          src={choix}
          alt=""
          
        />
      </div>

      <div style={props.isLesson2Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg keyboard_lesson2"
          onClick={props.handleKeyboardChoiceEvt_lesson2}
          src={choix}
          alt=""
        />
      </div>

      <div style={props.isLesson2Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg mouseChoice_lesson2"
          onClick={props.handleMouseChoiceEvt_lesson2}
          src={choix}
          alt=""
        />
      </div>

      {/* l'unite centrale */}

      <div style={props.isLesson3Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg_centralUnit motherboardChoice"
          onClick={props.handleMotherBoardEvt}
          src={choix_circle}
          alt=""
        />
      </div>

      <div style={props.isLesson3Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg_centralUnit cpuChoice"
          onClick={props.handleCPUEvt}
          src={choix_circle}
          alt=""
        />
      </div>

      <div style={props.isLesson3Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg_centralUnit ramChoice"
          onClick={props.handleRamEvt}
          src={choix_circle}
          alt=""
        />
      </div>

      <div style={props.isLesson3Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg_centralUnit hardDiskChoice"
          onClick={props.handleHardDiskEvt}
          src={choix_circle}
          alt=""
        />
      </div>

      <div style={props.isLesson3Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg_centralUnit powerChoice"
          onClick={props.handlePowerEvt}
          src={choix_circle}
          alt=""
        />
      </div>

      <div style={props.isLesson3Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg_centralUnit graphikCardChoice"
          onClick={props.handleGraphikCardEvt}
          src={choix_circle}
          alt=""
        />
      </div>

      <div style={props.isLesson3Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg_centralUnit fanChoice"
          onClick={props.handleFanEvt}
          src={choix_circle}
          alt=""
        />
      </div>

      <div style={props.isLesson3Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg_centralUnit soundCardChoice"
          onClick={props.handleSoundCardEvt}
          src={choix_circle}
          alt=""
        />
      </div>

      <div style={props.isLesson3Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg_centralUnit networkCardChoice"
          onClick={props.handleNetworkCardEvt}
          src={choix_circle}
          alt=""
        />
      </div>

      <div style={props.isLesson3Current ? null : applyDisplayNone}>
        {/* <img
          className="circleRedImg_centralUnit usbOutputChoice"
          onClick={props.handleUsbOutputEvt}
          src={choix_circle}
          alt=""
        /> */}
      </div>

      <div style={props.isLesson3Current ? null : applyDisplayNone}>
        <img
          className="circleRedImg_centralUnit hdmiOutputChoice"
          onClick={props.handleHdmiOutputEvt}
          src={choix_circle}
          alt=""
        />
      </div>

      {/* les divs qui apparaissent lors de l'exercice 4 */}
      <div
        style={props.isLesson4ExerciceCurrent ? null : applyDisplayNone}
        className="lesson4ExercicesDivs"
      >
        <button
          className="lesson4ExercicesDiv1"
          style={
            props.isLesson4DoubleClickExerciceCurrent ? grayButton : redButton
          }
          onClick={props.exercice4FirstDivOnClick}
          onContextMenu={props.exercice4FirstDivOnContexMenuClick}
        ></button>
        <button
          className="lesson4ExercicesDiv2"
          style={
            props.isLesson4DoubleClickExerciceCurrent ? redButton : grayButton
          }
          onDoubleClick={props.exercice4SecondDivOnDoubleClick}
          onClick={props.exercice4SecondOnclick}
          onContextMenu={props.exercice4MenuSecond}
          
          
        ></button>
      </div>

      <div className="imageLessonStyle">
        <div style={props.isLesson4Current ? null : applyDisplayNone}>
          <div onClick={goToPrevious} className="leftArrowStyles">
            ❰
          </div>
          <div onClick={goToNext} className="rightArrowStyles">
            ❱
          </div>
        </div>

        {props.isLesson5Current ? (
          <div>
            <TransformWrapper>
              <TransformComponent>
                <img width="100%" style={slideStylesWidthBackground} src={props.img} alt="keyboard"/>
              </TransformComponent>
            </TransformWrapper>
          </div>
        ) : (
        <div style={slideStylesWidthBackground}></div>
        )}

        {/* <ReactPanZoom
          image={slideStylesWidthBackground}
          alt="test"
        /> */}

        <ul
          className="dotsContainer"
          style={props.isLesson4Current ? null : applyDisplayNone}
        >
          {images.map((slide, slideIndex) => (
            <li
              id={"dot" + slideIndex}
              className="dot"
              key={slideIndex}
              onClick={(e) => goToSlide(e, slideIndex)}
            ></li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default LessonImage;
