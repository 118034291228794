import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Etapes, stepsTitle_module3_exercices } from "../utils/Etapes";
import images_store from "../utils/Images";
import { audio_consignes_Mod3, consignes_Mod3, module3_content, Mod_Titles } from "../utils/Lesson_Data";
import AfricatikLogo from "./AfricatikLogo";
import CircleButton from "./CircleButton";
import HImage from "./HImage";
import HImageMod3 from "./HImageMod3";
import IndicateurEtape from "./IndicateurEtape";
import LessonContent from "./LessonContent";
import StringWithSound from "./StringWithSound";
import LANG from "../properties.json";
import ModImage from "./ModImage";
import { Howl, Howler } from "howler";

function Mod3() {
  let navigate = useNavigate();
  var LangId = sessionStorage.getItem('Lang');
  const { moduleId } = useParams();
  const { pageId } = useParams();

  const indexPlusOne = (index) => {
    return index + 1;
  };

  const [lessonId, setLessonId] = useState(page_id(pageId));
  const [lessonFocusId, setLessonFocusId] = useState(0);

  const [lessonFocusId_onSeveral, setLessonFocusId_onSeveral] = useState(0);

  const handleOnHomeBtnClickEvt = () => {
    navigate("/", { replace: true });
    Howler.stop();
  };

  const handleOnBarClickEvt = (path, key) => {
    navigate(path, { replace: true });
    setLessonId(key);
    setLessonFocusId(0);
    setLessonFocusId_onSeveral(0);
    Howler.stop();
  };

  function handleChange(newValue) {
    setLessonFocusId(newValue);
    setLessonFocusId_onSeveral(0);
  }

  function handleChange_several(newValue) {
    setLessonFocusId_onSeveral(newValue);
  }

  let array = [];

  switch (lessonId - 1) {
    case 0:
      array = [
        Etapes.module3[0].image_orange_src,
        Etapes.module3[1].image_src,
        Etapes.module3[2].image_src,
        Etapes.module3[3].image_src,
        Etapes.module3[4].image_src,
        Etapes.module3[5].image_src,
      ];
      break;
    case 1:
      array = [
        Etapes.module3[0].image_src,
        Etapes.module3[1].image_orange_src,
        Etapes.module3[2].image_src,
        Etapes.module3[3].image_src,
        Etapes.module3[4].image_src,
        Etapes.module3[5].image_src,
      ];
      break;
    case 2:
      array = [
        Etapes.module3[0].image_src,
        Etapes.module3[1].image_src,
        Etapes.module3[2].image_orange_src,
        Etapes.module3[3].image_src,
        Etapes.module3[4].image_src,
        Etapes.module3[5].image_src,
      ];
      break;
    case 3:
      array = [
        Etapes.module3[0].image_src,
        Etapes.module3[1].image_src,
        Etapes.module3[2].image_src,
        Etapes.module3[3].image_orange_src,
        Etapes.module3[4].image_src,
        Etapes.module3[5].image_src,
      ];
      break;
    case 4:
      array = [
        Etapes.module3[0].image_src,
        Etapes.module3[1].image_src,
        Etapes.module3[2].image_src,
        Etapes.module3[3].image_src,
        Etapes.module3[4].image_orange_src,
        Etapes.module3[5].image_src,
      ];
      break;
    case 5:
      array = [
        Etapes.module3[0].image_src,
        Etapes.module3[1].image_src,
        Etapes.module3[2].image_src,
        Etapes.module3[3].image_src,
        Etapes.module3[4].image_src,
        Etapes.module3[5].image_orange_src,
      ];
      break;
    default:
      break;
  }
  //
  function titre_Question(IdLang, page) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[0].sequences[2].pages[
        page
      ].text
    });
    return data.toString();
  }
  function titre_Quest(IdLang) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[0].sequences[2].pages[
        0
      ].questions[3].text
    });
    return data.toString();
  }
  let consigne;
  switch (lessonId - 1) {
    case 0:
      consigne = consignes_Mod3.lesson1;
      break;
    case 1:
      consigne = consignes_Mod3.lesson2;
      break;
    case 2:
      consigne = consignes_Mod3.lesson3;
      break;
    case 3:
      consigne = consignes_Mod3.lesson4;
      break;
    case 4:
      consigne = consignes_Mod3.lesson5;
      break;
    default:
      break;
  }
  
  const [lessonId_specificData, SetLessonId_specificData] = useState(15);
  const islessonTreeFirst = (pageId, lessonId_specificData) => {
    let response;
  
    if (page_id(pageId) - 1 === 4 && lessonId_specificData === 0) {
      response = false;
    } 
   else {
      response = true;
    }
  
    return response;
  };

  const HandleOnAudioPlay = () => {
    if (page_id(pageId) === 1) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod3.lesson1],
      });
      sound.play();
    }
    if (page_id(pageId) === 2) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod3.lesson2],
      });
      sound.play();
    }
    if (page_id(pageId) === 3) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod3.lesson3],
      });
      sound.play();
    }
    if (page_id(pageId) === 4) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod3.lesson4],
      });
      sound.play();
    }
    if (page_id(pageId) === 5) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod3.lesson5],
      });
      sound.play();
    }
  };
  
  const isDefault = (index) => {
    if (index === 15) {
      return true;
    } else {
      return false;
    }
  };
  //

  return (
    <div className="moduleConteiner">
      <div className="moduleHead">
        <CircleButton
          className="homeBtn"
          img={images_store.general.backHomeImage}
          onClickEvt={handleOnHomeBtnClickEvt}
        />
        <h2 className="chapter_Title">{Mod_Titles.module3}</h2>
      </div>
      <div className="stepConteiner">
        {Etapes.module3.map((element, index) => (
          <IndicateurEtape
            key={index}
            image_src={array[index]}
            title={element.title}
            handleOnclickBarEvt={() =>
              handleOnBarClickEvt(element.path, element.key)
            }
          />
        ))}
      </div>
      {isExercices(lessonId) ? (
        <div className="exos_board">
          {stepsTitle_module3_exercices.map((elt, index) => (
            <div key={index} className="ExerciceCards">
              <img
                className="listImg"
                onClick={() => {
                  navigate(
                    "/" +
                      moduleId +
                      "/" +
                      pageId +
                      "/E" +
                      indexPlusOne(index).toString(),
                    { replace: true }
                  );
                }}
                src='../img/schedule.png'
                alt=""
              />
              <p>{elt}</p>
            </div>
          ))}
        </div>
      ) : (
        <div >
          <div className="moduleBody" >
                <div className="" style={{ marginLeft: "1%",marginTop:'-2%' }}>
                  <StringWithSound
                    string={consigne}
                    onAudioPlay={HandleOnAudioPlay}
                    hasAudio={true}
                  />
                </div>
              <div className={"contentAndImage"}>
                <div className="part_image" style={{ marginLeft: 30 }}>
                  {lessonId === 1 && (
                    <div className="mod2_ImgPatter" style={{ transform: "scale(0.9) translateX(-5%)" }}>
                      <ModImage
                        setCurrentIndex={handleChange}
                        setCurrentIndex_several={handleChange_several}
                        is_several={true}
                        currentIndex_several={lessonFocusId_onSeveral}
                        several_lenght={2}
                        several_focus={lessonFocusId === 0 ? true : false}
                        currentIndex={lessonFocusId}
                        lessonArrayLenght={images_store.mod3.lessons[lessonId - 1].imgs.length}
                        img={lessonFocusId === 0
                          ? '../imgs/' + images_store.mod3.lessons[lessonId - 1].imgs[lessonFocusId]
                          : '../imgs/' + images_store.mod3.lessons[lessonId - 1].imgs[lessonFocusId]}
                          is_notFirst={lessonFocusId === 0 ? false : true}
                        is_notLast={
                          lessonFocusId ===
                          images_store.mod3.lessons[lessonId - 1].imgs.length - 1
                            ? false
                            : true
                        }
                          />
                    </div>
                  )}

                  {lessonId === 2 && (
                    <div className="mod2_ImgPatter" style={{ transform: "scale(0.9) translateX(-5%)" }}>
                      <ModImage
                        is_several={true}
                        setCurrentIndex={handleChange}
                        currentIndex={lessonFocusId}
                        
                        img={'/imgs/' + images_store.mod3.lessons[lessonId - 1].imgs[lessonFocusId]}
                        lessonArrayLenght={
                          images_store.mod3.lessons[lessonId - 1].imgs.length
                        }
                        is_notFirst={lessonFocusId === 0 ? false : true}
                        is_notLast={
                          lessonFocusId ===
                          images_store.mod3.lessons[lessonId - 1].imgs.length - 1
                            ? false
                            : true
                        }
                        />
                    </div>
                  )}

                  {lessonId === 3 && (
                    <div className="mod2_ImgPatter" style={{ transform: "translateX(-5%)" }}>
                      <HImageMod3
                        is_several={false}
                        img={'/imgs/' + images_store.mod3.lessons[2]} />
                    </div>
                  )}

                  {lessonId === 4 && (
                    <div className="mod2_ImgPatter ">
                      <ModImage
                        is_several={true}
                        setCurrentIndex={handleChange}
                        currentIndex={lessonFocusId}
                        
                        img={'../imgs/' + images_store.mod3.lessons[lessonId - 1].imgs[lessonFocusId]}
                        lessonArrayLenght={
                          images_store.mod3.lessons[lessonId - 1].imgs.length
                        }
                        is_notFirst={lessonFocusId === 0 ? false : true}
                        is_notLast={
                          lessonFocusId ===
                          images_store.mod3.lessons[lessonId - 1].imgs.length - 1
                            ? false
                            : true
                        } />
                    </div>
                  )}

                  {lessonId === 5 && (
                    <div className="mod2_ImgPatter">
                      <ModImage
                        is_several={false}
                        img={'../imgs/' + images_store.mod3.lessons[4]} />
                    </div>
                  )}
                </div>
                <div className="part_content" style={{ marginLeft: '10%' }}>
                  {lessonId === 1 && (
                    <LessonContent
                      title={module3_content[lessonId - 1].onPreciseClickData[lessonFocusId].title}
                      content={module3_content[lessonId - 1].onPreciseClickData[lessonFocusId].content}
                      sub_content={module3_content[lessonId - 1].onPreciseClickData[lessonFocusId].sub_content} 
                      onAudioPlay={() => {
                        Howler.stop();
                        var sound = new Howl({
                          src: [
                            "../sounds/" +
                              module3_content[lessonId - 1].onPreciseClickData[
                                lessonFocusId
                              ].audio,
                          ],
                        });
                        sound.play();
                      }}
                      hasAudio={true}
                    />
                  )}

                  {lessonId === 2 && (
                    <LessonContent
                      title={module3_content[lessonId - 1].onPreciseClickData[lessonFocusId].title}
                      content={module3_content[lessonId - 1].onPreciseClickData[lessonFocusId].content}
                      sub_content={module3_content[lessonId - 1].onPreciseClickData[lessonFocusId].sub_content}
                      onAudioPlay={() => {
                        Howler.stop();
                        var sound = new Howl({
                          src: [
                            "../sounds/" +
                              module3_content[lessonId - 1].onPreciseClickData[
                                lessonFocusId
                              ].audio,
                          ],
                        });
                        sound.play();
                        }
                      }
                      hasAudio={true} 
                      />
                      
                  )}

                  {lessonId === 3 && (
                      <LessonContent
                        title={module3_content[2].initialData.title}
                        content={module3_content[2].initialData.content}
                        sub_content={module3_content[2].initialData.sub_content}
                        />
                  )}

                  {lessonId === 4 && (
                    <LessonContent
                      title={module3_content[lessonId - 1].onPreciseClickData[lessonFocusId].title}
                      content={module3_content[lessonId - 1].onPreciseClickData[lessonFocusId].content}
                      sub_content={module3_content[lessonId - 1].onPreciseClickData[lessonFocusId].sub_content}
                      {...(module3_content[lessonId - 1].onPreciseClickData[lessonFocusId].audio !== undefined && {
                        onAudioPlay: () => {
                          Howler.stop();
                          var sound = new Howl({
                            src: [
                              "../sounds/" +
                                module3_content[lessonId - 1].onPreciseClickData[
                                  lessonFocusId
                                ].audio,
                            ],
                          });
                          sound.play();
                        },
                      })}
                      {...(module3_content[lessonId - 1].onPreciseClickData[lessonFocusId].audio !== undefined && {
                        hasAudio: true,
                      })}
                      />
                  )}

                  {lessonId === 5 && (
                    <div className="col" >

                      <LessonContent
                        title={module3_content[4].initialData.title}
                        content={module3_content[4].initialData.content}
                        sub_content={module3_content[4].initialData.sub_content}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div></div>
      )}
    </div>
  );
}

const page_id = (page) => {
  let id;

  if (page === "lesson1") {
    id = 1;
  } else if (page === "lesson2") {
    id = 2;
  } else if (page === "lesson3") {
    id = 3;
  } else if (page === "lesson4") {
    id = 4;
  } else if (page === "lesson5") {
    id = 5;
  } else if (page === "exercices") {
    id = 6;
  }

  return id;
};

const isExercices = (lesson) => {
  if (lesson === 6) {
    return true;
  } else {
    return false;
  }
};

export default Mod3;




