import React from "react";

const Africatik = () => {
  return (
    <div>
      {document.write(
        '<script>window.open("https://africatik.com", "_top")</script>'
      )}
    </div>
  );
};

export default Africatik;
