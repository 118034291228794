import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Etapes, stepsTitle_module4_exercices, stepsTitle_module5, stepsTitle_module5_exercices } from "../utils/Etapes";
import images_store from "../utils/Images";
import AfricatikLogo from "./AfricatikLogo";
import CircleButton from "./CircleButton";
import IndicateurEtape from "./IndicateurEtape";
import LessonContent from "./LessonContent";
import StringWithSound from "./StringWithSound";
import ModImage from "./ModImage";
import { module3_content, module4_content, Mod_Titles,module5_content, consignes_Mod5, audio_consignes_Mod5 } from "../utils/Lesson_Data";
import LANG from "../properties.json";
import { Howl, Howler } from "howler";

function Mod5() {
  let navigate = useNavigate();

  const { moduleId } = useParams();
  const { pageId } = useParams();

  const [lessonId, setLessonId] = useState(page_id(pageId));
  const [lessonFocusId, setLessonFocusId] = useState(0);
  const [lessonFocusId_onSeveral, setLessonFocusId_onSeveral] = useState(0);

  const handleOnHomeBtnClickEvt = () => {
    navigate("/", { replace: true });
    Howler.stop();
  };

  const handleOnBarClickEvt = (path, key) => {
    navigate(path, { replace: true });
    setLessonId(key);
    setLessonFocusId(0);
    Howler.stop();
    // SetLessonId_specificData(15);
  };
  function handleChange(newValue) {
    setLessonFocusId(newValue);
    setLessonFocusId_onSeveral(0);
  }

  const indexPlusOne = (index) => {
    return index + 1;
  };

  let array = [];

  switch (lessonId - 1) {
    case 0:
      array = [
        Etapes.module5[0].image_orange_src,
        Etapes.module5[1].image_src,
        Etapes.module5[2].image_src,
        Etapes.module5[3].image_src,
        Etapes.module5[4].image_src,
        Etapes.module5[5].image_src
      ];
      break;
    case 1:
      array = [
        Etapes.module5[0].image_src,
        Etapes.module5[1].image_orange_src,
        Etapes.module5[2].image_src,
        Etapes.module5[3].image_src,
        Etapes.module5[4].image_src,
        Etapes.module5[5].image_src
      ];
      break;
    case 2:
      array = [
        Etapes.module5[0].image_src,
        Etapes.module5[1].image_src,
        Etapes.module5[2].image_orange_src,
        Etapes.module5[3].image_src,
        Etapes.module5[4].image_src,
        Etapes.module5[5].image_src
      ];
      break;
    case 3:
      array = [
        Etapes.module5[0].image_src,
        Etapes.module5[1].image_src,
        Etapes.module5[2].image_src,
        Etapes.module5[3].image_orange_src,
        Etapes.module5[4].image_src,
        Etapes.module5[5].image_src
      ];
      break;
    case 4:
      array = [
        Etapes.module5[0].image_src,
        Etapes.module5[1].image_src,
        Etapes.module5[2].image_src,
        Etapes.module5[3].image_src,
        Etapes.module5[4].image_orange_src,
        Etapes.module5[5].image_src
      ];
      break;
    case 5:
      array = [
        Etapes.module5[0].image_src,
        Etapes.module5[1].image_src,
        Etapes.module5[2].image_src,
        Etapes.module5[3].image_src,
        Etapes.module5[4].image_src,
        Etapes.module5[5].image_orange_src,
      ];
      break;
    default:
      break;
  }
  var LangId = sessionStorage.getItem('Lang');
  function titre_Question(IdLang, page) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[0].sequences[4].pages[
        page
      ].text
    });
    return data.toString();
  }
  function titre_Quest(IdLang,page,quest) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[0].sequences[4].pages[
        page
      ].questions[quest].text
    });
    return data.toString();
  }

  let consigne;
  switch (lessonId - 1) {
    case 0:
      consigne = consignes_Mod5.lesson1;
      break;
    case 1:
      consigne = consignes_Mod5.lesson2;
      break;
      
      case 2:
        consigne = consignes_Mod5.lesson3;
        break;
      case 3:
        consigne = consignes_Mod5.lesson4;
        break;
      case 4:
        consigne = consignes_Mod5.lesson5;
        break;
    default:
      break;
  }

  const HandleOnAudioPlay = () => {
    if (page_id(pageId) === 1) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod5.lesson1],
      });
      sound.play();
    } else if (page_id(pageId) === 2) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod5.lesson2],
      });
      sound.play();
    } else if (page_id(pageId) === 3) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod5.lesson3],
      });
      sound.play();
    } else if (page_id(pageId) === 4) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod5.lesson4],
      });
      sound.play();
    } else if (page_id(pageId) === 5) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod5.lesson5],
      });
      sound.play();
    }
  };
  
  const [lessonId_specificData, SetLessonId_specificData] = useState(15);
  const islessonTreeFirst = (pageId, lessonId_specificData) => {
    let response;
  
    if (page_id(pageId) - 1 === 4 && lessonId_specificData === 0) {
      response = false;
    } 
   else {
      response = true;
    }
  
    return response;
  };
  return (
    <div className="moduleConteiner">
      <div className="moduleHead">
        <CircleButton
          className="homeBtn"
          img={images_store.general.backHomeImage}
          onClickEvt={handleOnHomeBtnClickEvt}
        />
        <h2 className="chapter_Title">{Mod_Titles.module5}</h2>
      </div>

      <div className="stepConteiner">
        {Etapes.module5.map((element, index) => (
          <IndicateurEtape
            key={index}
            image_src={array[index]}
            title={element.title}
            handleOnclickBarEvt={() =>
              handleOnBarClickEvt(element.path, element.key)
            }
          />
        ))}
      </div>
      {isExercices(lessonId) ? (
        <div className="exos_board">
          {stepsTitle_module5_exercices.map((elt, index) => (
            <div key={index} className="ExerciceCards">
              <img
                className="listImg"
                onClick={() => {
                  navigate(
                    "/" +
                      moduleId +
                      "/" +
                      pageId +
                      "/E" +
                      indexPlusOne(index).toString(),
                    { replace: true }
                  );
                }}
                src='../img/schedule.png'
                alt=""
              />
               <p>{elt}</p>
            </div>
          ))}
        </div>
      ) : (
        <>
            <div className="moduleBody">
            {page_id(pageId) - 1 !== 6 &&
              islessonTreeFirst(pageId, lessonId_specificData) && (
                <div className="" style={{ marginLeft: "2.5%",marginBottom:'5%',marginTop:'-3%' }}>
                  <StringWithSound
                    string={consigne}
                    onAudioPlay={HandleOnAudioPlay}
                    hasAudio={true}
                  />
                </div>
              )}
              <div className={" row contentAndImage"}>
                <div className="col part_image" style={{ marginLeft: 10, width: '1%', }}>
                  {lessonId === 1 && (
                    <div className="mod2_ImgPatter">
                      <ModImage
                        setCurrentIndex={handleChange}
                        currentIndex={lessonFocusId}

                        img={'/imgs/' + images_store.mod5.lessons[lessonId - 1].imgs[lessonFocusId]}
                        lessonArrayLenght={images_store.mod5.lessons[lessonId - 1].imgs.length}
                        is_several={true}
                        is_notFirst={lessonFocusId === 0 ? false : true}
                        is_notLast={lessonFocusId ===
                          images_store.mod5.lessons[lessonId - 1].imgs.length - 1
                          ? false
                          : true} />
                    </div>
                  )}

                  {lessonId === 2 && (
                    <div className="mod2_ImgPatter">
                      <ModImage
                        is_several={true}
                        setCurrentIndex={handleChange}
                        currentIndex={lessonFocusId}

                        img={'/imgs/' + images_store.mod5.lessons[lessonId - 1].imgs[lessonFocusId]}
                        lessonArrayLenght={images_store.mod5.lessons[lessonId - 1].imgs.length}
                        is_notFirst={lessonFocusId === 0 ? false : true}
                        is_notLast={lessonFocusId ===
                          images_store.mod5.lessons[lessonId - 1].imgs.length - 1
                          ? false
                          : true} />
                    </div>
                  )}

                  {lessonId === 3 && (
                    <div className="mod2_ImgPatter">
                      <ModImage
                        is_several={true}
                        setCurrentIndex={handleChange}
                        currentIndex={lessonFocusId}

                        img={'/imgs/' + images_store.mod5.lessons[lessonId - 1].imgs[lessonFocusId]}
                        lessonArrayLenght={images_store.mod5.lessons[lessonId - 1].imgs.length}
                        is_notFirst={lessonFocusId === 0 ? false : true}
                        is_notLast={lessonFocusId ===
                          images_store.mod5.lessons[lessonId - 1].imgs.length - 1
                          ? false
                          : true} />
                    </div>
                  )}

                  {lessonId === 4 && (
                    <div className="mod2_ImgPatter ">
                      <ModImage
                        is_several={true}
                        setCurrentIndex={handleChange}
                        currentIndex={lessonFocusId}

                        img={'/imgs/' + images_store.mod5.lessons[lessonId - 1].imgs[lessonFocusId]}
                        lessonArrayLenght={images_store.mod5.lessons[lessonId - 1].imgs.length}
                        is_notFirst={lessonFocusId === 0 ? false : true}
                        is_notLast={lessonFocusId ===
                          images_store.mod5.lessons[lessonId - 1].imgs.length - 1
                          ? false
                          : true} />
                    </div>
                  )}


                  {lessonId === 5 && (
                    <div className="mod2_ImgPatter">
                      <ModImage
                        is_several={true}
                        setCurrentIndex={handleChange}
                        currentIndex={lessonFocusId}

                        img={'/imgs/' + images_store.mod5.lessons[lessonId - 1].imgs[lessonFocusId]}
                        lessonArrayLenght={images_store.mod5.lessons[lessonId - 1].imgs.length}
                        is_notFirst={lessonFocusId === 0 ? false : true}
                        is_notLast={lessonFocusId ===
                          images_store.mod5.lessons[lessonId - 1].imgs.length - 1
                          ? false
                          : true} />
                    </div>
                  )}
                </div>
                <div className="col part_content" style={{ marginLeft: '2%', }}>
                  {lessonId === 1 && (
                    <LessonContent
                      title={module5_content[0].onPreciseClickData[lessonFocusId].title}
                      content={lessonFocusId === 0 ? module5_content[0].initialData.content : null}
                      sub_content={lessonFocusId === 0
                        ? module5_content[0].onPreciseClickData[lessonFocusId].content[lessonFocusId_onSeveral] :
                        module5_content[0].onPreciseClickData[lessonFocusId].content}
                      onAudioPlay={() => {
                        Howler.stop();
                        var sound = new Howl({
                          src: [
                            "../sounds/" +
                              module5_content[0].onPreciseClickData[
                                lessonFocusId
                              ].audio,
                          ],
                        });
                        sound.play();
                        }
                      }
                      hasAudio={true} />
                  )}

                  {lessonId === 2 && (
                    <LessonContent
                      title={module5_content[ 1].onPreciseClickData[lessonFocusId].title}
                      content={lessonFocusId === 0 ? module5_content[ 1].initialData.content : null}
                      sub_content={lessonFocusId === 0
                        ? module5_content[ 1].onPreciseClickData[lessonFocusId].content[lessonFocusId_onSeveral]
                        : module5_content[ 1].onPreciseClickData[lessonFocusId].content }
                      onAudioPlay={() => {
                        Howler.stop();
                        var sound = new Howl({
                          src: [
                            "../sounds/" +
                              module5_content[ 1].onPreciseClickData[
                                lessonFocusId
                              ].audio,
                          ],
                        });
                        sound.play();
                        }
                      }
                      hasAudio={true} />
                  )}

                  {lessonId === 3 && (
                    <LessonContent
                      title={module5_content[2].onPreciseClickData[lessonFocusId].title}
                      content={lessonFocusId === 0 ? module5_content[2].initialData.content : null}
                      sub_content={lessonFocusId === 0
                        ? module5_content[2].onPreciseClickData[lessonFocusId].content[lessonFocusId_onSeveral]
                        : module5_content[2].onPreciseClickData[lessonFocusId].content}
                      onAudioPlay={() => {
                        Howler.stop();
                        var sound = new Howl({
                          src: [
                            "../sounds/" +
                              module5_content[2].onPreciseClickData[
                                lessonFocusId
                              ].audio,
                          ],
                        });
                        sound.play();
                        }
                      }
                      hasAudio={true} />

                  )}

                  {lessonId === 4 && (
                    <LessonContent
                      title={module5_content[3].onPreciseClickData[lessonFocusId].title}
                      content={module5_content[3].onPreciseClickData[lessonFocusId].content}
                      sub_content={module5_content[3].onPreciseClickData[lessonFocusId].sub_content}
                      onAudioPlay={() => {
                        Howler.stop();
                        var sound = new Howl({
                          src: [
                            "../sounds/" +
                              module5_content[3].onPreciseClickData[
                                lessonFocusId
                              ].audio,
                          ],
                        });
                        sound.play();
                        }
                      }
                      hasAudio={true} 
                    />
                  )}

                  {lessonId === 5 && (
                    <LessonContent
                      title={module5_content[4].onPreciseClickData[lessonFocusId].title}
                      sub_content={module5_content[4].onPreciseClickData[lessonFocusId].sub_content} 
                      content={module5_content[4].onPreciseClickData[lessonFocusId].content}
                      onAudioPlay={() => {
                        Howler.stop();
                        var sound = new Howl({
                          src: [
                            "../sounds/" +
                              module5_content[4].onPreciseClickData[
                                lessonFocusId
                              ].audio,
                          ],
                        });
                        sound.play();
                        }
                      }
                      hasAudio={true}
                    />
                  )}
                </div>
              </div>

            </div></>
      )}

    </div>
  );
}

const isExercices = (lesson) => {
  if (lesson === 6) {
    return true;
  } else {
    return false;
  }
};

const page_id = (page) => {
  let id;

  if (page === "lesson1") {
    id = 1;
  } else if (page === "lesson2") {
    id = 2;
  } else if (page === "lesson3") {
    id = 3;
  } else if (page === "lesson4") {
    id = 4;
  } else if (page === "lesson5") {
    id = 5;
  } else if (page === "exercices") {
    id = 6;
  }

  return id;
};

export default Mod5;
