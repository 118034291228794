import React, { useEffect, useState } from "react";
import { Etapes } from "../utils/Etapes";
import IndicateurEtape from "../components/IndicateurEtape";
import CircleButton from "../components/CircleButton";
import AfricatikLogo from "../components/AfricatikLogo";
import { useNavigate, useParams } from "react-router-dom";
import { module1_content, Mod_Titles } from "../utils/Lesson_Data";
import StringWithSound from "../components/StringWithSound";
import LessonContent from "../components/LessonContent";
import LessonImage from "../components/LessonImage";
import images_store from "../utils/Images";
import ExercicesBoard from "../components/ExercicesBoard";
import Mod2 from "../components/Mod2";
import Mod3 from "../components/Mod3";
import Mod4 from "../components/Mod4";
import Mod5 from "../components/Mod5";
import Mod6 from "../components/Mod6";
import { Howl, Howler } from "howler";
import {
  audio_consignes_Mod1,
  mod1_audios_lessons,
  mod1_consignes_lessons,
} from "../utils/Lesson_Data";

// ------------------------ images -----------------------

import mod1_lesson1 from "../assets/mod1/MOD1_ordinateur_fixe_raqmi.png";
import mod1_lesson2 from "../assets/mod1/MOD1_ordinateur_portable_raqmi.png";
import mod1_lesson3 from "../assets/mod1/MOD1_unité_centrale_raqmi.png";

import mod1_lesson5 from "../assets/mod1/MOD1_clavier_raqmi.png";
import LANG from "../properties.json";

// --------------------------- images ------------------------------

function Chapter() {
  let navigate = useNavigate();

  var LangId = sessionStorage.getItem("Lang");

  const { moduleId } = useParams();
  const { pageId } = useParams();
  const [lessonFocusId, setLessonFocusId] = useState(0);
  
  const [lessonFocusId_onSeveral, setLessonFocusId_onSeveral] = useState(0);
  const [lessonId, setLessonId] = useState(page_id(pageId));
  const [lessonId_specificData, SetLessonId_specificData] = useState(15);

  var properties = module1_content[lessonId - 1].initialData;
  var specific_properties = module1_content[lessonId - 1].onPreciseClickData;

  // --------------- lesson 1

  function Lesson_images(IdLang, page) {
    const data = LANG.map((info) => {
      return "/imgs/" +info.languages[IdLang].domains[0].subdomains[0].sequences[0].pages[
        page
      ].media_pages[0].img_file;
    });
    return data;
  }
  
  function Les_images(IdLang, page) {
    const data = LANG.map((info) => {
      return "/imgs/" +info.languages[IdLang].domains[0].subdomains[0].sequences[0]
        .pages[3].questions[page].media_questions[0].img_file;
    });
    return data;
  }

  const Mod1_lesson4 = [
     Lesson_images(0, 3),
    Les_images(0, 0),
    Les_images(0, 1),
    Les_images(0, 2),
    Les_images(0, 3),
  ];

  const lessons = [
    Lesson_images(0, 0),
    Lesson_images(0, 1),
    Lesson_images(0, 2),
    Mod1_lesson4,
    Lesson_images(0, 4),
  ];

  const handleOnCpuChoiceEvt = () => {
    SetLessonId_specificData(3);
  };

  const handleOnKeyboardChoiceEvt = () => {
    SetLessonId_specificData(0);
  };

  const handleOnMouseChoiceEvt = () => {
    SetLessonId_specificData(2);
  };

  const handleOnScreenChoiceEvt = () => {
    SetLessonId_specificData(1);
  };

  // -------------- lesson 2

  const handleOnKeyboardChoiceEvt_lesson2 = () => {
    SetLessonId_specificData(0);
  };

  const handleOnMouseChoiceEvt_lesson2 = () => {
    SetLessonId_specificData(2);
  };

  const handleOnScreenChoiceEvt_lesson2 = () => {
    SetLessonId_specificData(1);
  };

  // -------------- lesson 3

  const handleOnMotherBoardChoiceEvt = () => {
    SetLessonId_specificData(0);
  };

  const handleOnCPUChoiceEvt = () => {
    SetLessonId_specificData(1);
  };

  const handleOnRamChoiceEvt = () => {
    SetLessonId_specificData(2);
  };

  const handleOnHardDiskChoiceEvt = () => {
    SetLessonId_specificData(3);
  };

  const handleOnPowerChoiceEvt = () => {
    SetLessonId_specificData(4);
  };

  const handleOnGraphikCardChoiceEvt = () => {
    SetLessonId_specificData(5);
  };

  const handleOnFanChoiceEvt = () => {
    SetLessonId_specificData(6);
  };

  const handleOnSoundCardChoiceEvt = () => {
    SetLessonId_specificData(7);
  };

  const handleOnNetworkCardChoiceEvt = () => {
    SetLessonId_specificData(8);
  };

  const handleOnUsbOutputChoiceEvt = () => {
    SetLessonId_specificData(9);
  };

  const handleOnHdmiOutputChoiceEvt = () => {
    SetLessonId_specificData(10);
  };

  function handleChange(newValue) {
    SetLessonId_specificData(newValue);
  }

  const handleOnHomeBtnClickEvt = () => {
    navigate("/", { replace: true });
    Howler.stop();
  };

  const handleOnBarClickEvt = (path, key) => {
    navigate(path, { replace: true });
    setLessonId(key);
    setLessonFocusId(0);
    SetLessonId_specificData(15);
    Howler.stop();

    /////////////////////////////////////////////////

    // ici selectioner tous les boutons du chemin de fer et appliquer just pour l'actif enlever pour tout les autres

    /////////////////////////////////////////////////
  };

  let array = [];

  switch (lessonId - 1) {
    case 0:
      array = [
        Etapes.module1[0].image_orange_src,
        Etapes.module1[1].image_src,
        Etapes.module1[2].image_src,
        Etapes.module1[3].image_src,
        Etapes.module1[4].image_src,
        Etapes.module1[5].image_src,
      ];
      break;
    case 1:
      array = [
        Etapes.module1[0].image_src,
        Etapes.module1[1].image_orange_src,
        Etapes.module1[2].image_src,
        Etapes.module1[3].image_src,
        Etapes.module1[4].image_src,
        Etapes.module1[5].image_src,
      ];
      break;
    case 2:
      array = [
        Etapes.module1[0].image_src,
        Etapes.module1[1].image_src,
        Etapes.module1[2].image_orange_src,
        Etapes.module1[3].image_src,
        Etapes.module1[4].image_src,
        Etapes.module1[5].image_src,
      ];
      break;
    case 3:
      array = [
        Etapes.module1[0].image_src,
        Etapes.module1[1].image_src,
        Etapes.module1[2].image_src,
        Etapes.module1[3].image_orange_src,
        Etapes.module1[4].image_src,
        Etapes.module1[5].image_src,
      ];
      break;
    case 4:
      array = [
        Etapes.module1[0].image_src,
        Etapes.module1[1].image_src,
        Etapes.module1[2].image_src,
        Etapes.module1[3].image_src,
        Etapes.module1[4].image_orange_src,
        Etapes.module1[5].image_src,
      ];
      break;
    case 5:
      array = [
        Etapes.module1[0].image_src,
        Etapes.module1[1].image_src,
        Etapes.module1[2].image_src,
        Etapes.module1[3].image_src,
        Etapes.module1[4].image_src,
        Etapes.module1[5].image_orange_src,
      ];
      break;
    default:
      break;
  }

  let consigne;
  let audio;
  switch (lessonId - 1) {
    case 0:
      consigne = mod1_consignes_lessons.lesson1;
      audio = audio_consignes_Mod1.lesson1;
      break;
    case 1:
      consigne = mod1_consignes_lessons.lesson2;
      audio = audio_consignes_Mod1.lesson2;
      break;
    case 2:
      consigne = mod1_consignes_lessons.lesson3;
      audio = audio_consignes_Mod1.lesson3;
      break;
    case 3:
      consigne = mod1_consignes_lessons.lesson4;
      audio = audio_consignes_Mod1.lesson4;
      break;
    case 4:
      consigne = mod1_consignes_lessons.lesson5;
      audio = audio_consignes_Mod1.lesson5;
      break;
    default:
      break;
  }

  let sound;
  const Handle_onAudioPlay = () => {
    if (moduleId === "module1") {
      if (page_id(pageId) === 1) {
        sound = new Howl({
          src: [
            "../sounds/" + mod1_audios_lessons.lesson1,
          ],
        });
        sound.play();
      } else if (page_id(pageId) === 2) {
        sound = new Howl({
          src: [
            "../sounds/" + mod1_audios_lessons.lesson2,
          ],
        });
        sound.play();
      } else if (page_id(pageId) === 3) {
        sound = new Howl({
          src: [
            "../sounds/" + mod1_audios_lessons.lesson3,
          ],
        });
        sound.play();
      } else if (page_id(pageId) === 4) {
        sound = new Howl({
          src: [
            process.env.PUBLIC_URL +
              "/sounds/" +
              mod1_audios_lessons.lesson4[
                isDefault(lessonId_specificData) ? 0 : lessonId_specificData - 1
              ],
          ],
        });
        sound.play();
      } else if (page_id(pageId) === 5) {
        sound = new Howl({
          src: [
            "../sounds/" + mod1_audios_lessons.lesson5,
          ],
        });
        sound.play();
      }
    }
  };

  // ---------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    var element = document.getElementById("dot0");
    // var isContain = element.classList.contains("activeDot");

    // console.log(isContain);

    if (module_id(moduleId) === 1 && lessonId === 4) {
      isDefault(lessonId_specificData) && element.classList.add("activeDot");
      isDefault(lessonId_specificData) === false &&
        lessonId_specificData !== 0 &&
        element.classList.remove("activeDot");
    }
  }, [lessonId, lessonId_specificData]);
  //------------------------------------------------------------------------------------------------------------------
  if (module_id(moduleId) === 1) {
    return (
      <div className="moduleConteiner">
        <div className="moduleHead">
          <CircleButton
            img={images_store.general.backHomeImage}
            onClickEvt={handleOnHomeBtnClickEvt}
          />
          <h2 className="chapter_Title">{Mod_Titles.module1}</h2>
        </div>

        <div className="stepConteiner">
          {Etapes.module1.map((element, index) => (
            <IndicateurEtape
              key={index}
              image_src={array[index]}
              title={element.title}
              handleOnclickBarEvt={() =>
                handleOnBarClickEvt(element.path, element.key)
              }
            />
          ))}
        </div>

        {isExercices(lessonId) ? (
          <ExercicesBoard />
        ) : (
          <div className="moduleBody">
            <div className="" style={{ marginLeft: "10%",marginTop:'-2%' }}>&nbsp;
              <StringWithSound
                string={consigne}
                hasAudio={true}
                onAudioPlay={() => {
                  sound = new Howl({
                    src: [
                      "../sounds/" + audio,
                    ],
                  });
                  sound.play();
                }}
              />
            </div>
            <div className="contentAndImage">
              <div className="part_image" style={{}}>
                <LessonImage
                  img={lessons[page_id(pageId) - 1]}
                  currentIndex={
                    isDefault(lessonId_specificData) ? 0 : lessonId_specificData
                  }
                  setCurrentIndex={handleChange}
                  // --------------- bool -----------------
                  isLesson1Current={page_id(pageId) - 1 === 0 ? true : false}
                  isLesson2Current={page_id(pageId) - 1 === 1 ? true : false}
                  isLesson3Current={page_id(pageId) - 1 === 2 ? true : false}
                  isLesson4Current={page_id(pageId) - 1 === 3 ? true : false}
                  isLesson5Current={page_id(pageId) - 1 === 4 ? true : false}
                  // --------------- lesson 1 -----------------
                  handleScreenChoiceEvt={handleOnScreenChoiceEvt}
                  handleCentralUnitChoiceEvt={handleOnCpuChoiceEvt}
                  handleKeyboardChoiceEvt={handleOnKeyboardChoiceEvt}
                  handleMouseChoiceEvt={handleOnMouseChoiceEvt}
                  // --------------- lesson 2 -----------------
                  handleScreenChoiceEvt_lesson2={
                    handleOnScreenChoiceEvt_lesson2
                  }
                  handleKeyboardChoiceEvt_lesson2={
                    handleOnKeyboardChoiceEvt_lesson2
                  }
                  handleMouseChoiceEvt_lesson2={handleOnMouseChoiceEvt_lesson2}
                  // --------------- lesson 3 -----------------
                  handleMotherBoardEvt={handleOnMotherBoardChoiceEvt}
                  handleCPUEvt={handleOnCPUChoiceEvt}
                  handleRamEvt={handleOnRamChoiceEvt}
                  handleHardDiskEvt={handleOnHardDiskChoiceEvt}
                  handlePowerEvt={handleOnPowerChoiceEvt}
                  handleGraphikCardEvt={handleOnGraphikCardChoiceEvt}
                  handleFanEvt={handleOnFanChoiceEvt}
                  handleSoundCardEvt={handleOnSoundCardChoiceEvt}
                  handleNetworkCardEvt={handleOnNetworkCardChoiceEvt}
                  handleUsbOutputEvt={handleOnUsbOutputChoiceEvt}
                  handleHdmiOutputEvt={handleOnHdmiOutputChoiceEvt}
                />
              </div>
              <div className="part_content">
                <LessonContent
                  title={
                    isRedClicked(lessonId_specificData)
                      ? specific_properties[lessonId_specificData].title
                      : properties.title
                  }
                  content={
                    isRedClicked(lessonId_specificData)
                      ? specific_properties[lessonId_specificData].content
                      : properties.content
                  }
                  sub_content={
                    isRedClicked(lessonId_specificData)
                      ? specific_properties[lessonId_specificData].sub_content
                      : properties.sub_content
                  }
                  {...(isRedClicked(lessonId_specificData) &&  {
                    onAudioPlay: () => {
                      sound = new Howl({
                        src: [
                            "../sounds/" +
                            specific_properties[lessonId_specificData].audio,
                        ],
                      });
                      sound.play();
                    }
                  })}
                  {...(page_id(pageId) - 1 === 3 || page_id(pageId) - 1 === 4) && {
                    onAudioPlay: () => {
                      sound = new Howl({
                        src: [
                            "../sounds/" + specific_properties[isDefault(lessonId_specificData) ? 0 : lessonId_specificData ].audio,
                        ],
                      });
                      sound.play();
                    }
                  }}
                  hasAudio={ (pageId === "lesson4" || pageId === "lesson5") || isRedClicked(lessonId_specificData) ? true : false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else if (module_id(moduleId) === 2) {
    return <Mod2 />;
  } else if (module_id(moduleId) === 3) {
    return <Mod3 />;
  } else if (module_id(moduleId) === 4) {
    return <Mod4 />;
  } else if (module_id(moduleId) === 5) {
    return <Mod5 />;
  } else if (module_id(moduleId) === 6) {
    return <Mod6 />;
  }
}

const isExercices = (lesson) => {
  if (lesson === 6) {
    return true;
  } else {
    return false;
  }
};

// retourne l'entier definisant le module
const module_id = (module) => {
  let id;

  if (module === "module1") {
    id = 1;
  } else if (module === "module2") {
    id = 2;
  } else if (module === "module3") {
    id = 3;
  } else if (module === "module4") {
    id = 4;
  } else if (module === "module5") {
    id = 5;
  } else if (module === "module6") {
    id = 6;
  }

  return id;
};

// retourne l'entier definnisant la lesson de maniere particuliere
const page_id = (page) => {
  let id;

  if (page === "lesson1") {
    id = 1;
  } else if (page === "lesson2") {
    id = 2;
  } else if (page === "lesson3") {
    id = 3;
  } else if (page === "lesson4") {
    id = 4;
  } else if (page === "lesson5") {
    id = 5;
  } else if (page === "exercices") {
    id = 6;
  }

  return id;
};

// retourne l'entier definnisant la lesson de maniere particuliere
const isRedClicked = (value) => {
  let response;

  if (value === 15) {
    response = false;
  } else {
    response = true;
  }

  return response;
};

// const islessonTreeFirst = (pageId, lessonId_specificData) => {
//   let response;

//   if (page_id(pageId) - 1 === 3 && lessonId_specificData === 0) {
//     response = false;
//   } else if (page_id(pageId) - 1 === 3 && lessonId_specificData === 15) {
//     response = false;
//   } else {
//     response = true;
//   }

//   return response;
// };

const isDefault = (index) => {
  if (index === 15) {
    return true;
  } else {
    return false;
  }
};

export default Chapter;
