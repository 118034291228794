import { Alert } from "bootstrap";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import AfricatikLogo from "../components/AfricatikLogo";
import Parametre from "../components/setting";
import LANG from '../properties.json'


//Langue par défaut Français
if (sessionStorage.getItem("Lang") === null) {
    sessionStorage.setItem("Lang", 0);
  }
  
  const Menu = () => {
    sessionStorage.setItem("onglet", "one");
  
    // < Fonction module>
  
    // var langID = sessionStorage.getItem("Lang");
  
    function MODULE_TITRE(Idlangue, Idmodule) {
      const data = LANG.map((info) => {
        return info.languages[Idlangue].domains[0].subdomains[0].sequences[
          Idmodule].title;
      });
  
      return data;
    }
    function MODULE_DESCR(Idlangue, descr) {
        const data = LANG.map((info) => {
          return info.languages[Idlangue].domains[0].subdomains[0].sequences[
            descr
          ].introduction;
        });
    
        return data;
      }
  
    // </Fonction module>
  
    function choixCat(nbre) {
      sessionStorage.setItem("theme", nbre);
      if (nbre === 5) {
        sessionStorage.setItem("visibiliteExercice", "none");
      } else {
        sessionStorage.setItem("visibiliteExercice", "");
      }
    }

  //
  const [alert, setAlert] = React.useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })

  function onCloseAlert() {
    setAlert({
      type: '',
      text: '',
      show: false
    })
  }

  function onShowAlert(type) {
    setAlert({
      type: type,
      text: 'Demo alert',
      show: true
    })
  }
    //
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col">
              <img src="/img/logo.png" alt="" className="logo-appli" />
            </div>
            <div className="col marge-haut" style={{marginTop:'2%'}}>
              <Parametre />
            </div>
          </div>
        </div>
        <div className="row menu">
              <div className="col ">
                <div className="row align-items-center justify-content-center groupe-module" 
                style={{}}>
                  <div className="col-sm-2 ">
                  <NavLink className="card card_link" to="/module1/lesson1" onClick={() => choixCat(0)}>
                  <div className="card_header">
                    <p className="card_title">{MODULE_TITRE(sessionStorage.getItem("Lang"), 0)}</p>
                  </div>

                  <div className="card_body">
                    <img
                      src="./img/ordinateur_portable.png"
                      alt=""
                      style={{ width: "5vw", height: "3vw" }}
                    />
                  </div>

                  <div className="card_line"></div>

                  <div className="card_footer">
                    <p className="card_description">{MODULE_DESCR(sessionStorage.getItem("Lang"), 0)}</p>
                  </div>
                </NavLink>
                  </div>
                  <div className="col-sm-2 ">
                  <NavLink className="card card_link" to="/module2/lesson1" onClick={() => choixCat(1)}>
                  <div className="card_header">
                    <p className="card_title">{MODULE_TITRE(sessionStorage.getItem("Lang"), 1)}</p>
                  </div>

                  <div className="card_body">
                    <img
                      src="./img/tablette_smartphone.png"
                      alt=""
                      style={{ width: "5vw", height: "3vw" }}
                    />
                  </div>

                  <div className="card_line"></div>

                  <div className="card_footer">
                    <p className="card_description">{MODULE_DESCR(sessionStorage.getItem("Lang"), 1)}</p>
                  </div>
                </NavLink>
                  </div>
                  <div className="col-sm-2 ">
                  <NavLink className="card card_link" to="/module3/lesson1" onClick={() => choixCat(2)}>
                  <div className="card_header">
                    <p className="card_title">{MODULE_TITRE(sessionStorage.getItem("Lang"), 2)}</p>
                  </div>

                  <div className="card_body">
                    <img
                      src="./img/bureau.png"
                      alt=""
                      style={{ width: "5vw", height: "3vw" }}
                    />
                  </div>

                  <div className="card_line"></div>

                  <div className="card_footer">
                    <p className="card_description">{MODULE_DESCR(sessionStorage.getItem("Lang"), 2)}</p>
                  </div>
                </NavLink>
                  </div>
                </div>
                <div className="row align-items-center justify-content-center groupe-module" 
                style={{marginTop:"2%" }}>
                  <div className="col-sm-2 ">
                  <NavLink className="card card_link" to="/module4/lesson1" onClick={() => choixCat(3)}>
                  <div className="card_header">
                    <p className="card_title">{MODULE_TITRE(sessionStorage.getItem("Lang"), 3)}</p>
                  </div>

                  <div className="card_body">
                    <img
                      src="./img/accessoires.png"
                      alt=""
                      style={{ width: "5vw", height: "3vw" }}
                    />
                  </div>

                  <div className="card_line"></div>

                  <div className="card_footer">
                    <p className="card_description">{MODULE_DESCR(sessionStorage.getItem("Lang"), 3)}</p>
                  </div>
                </NavLink>
                  </div>
                  <div className="col-sm-2 ">
                  <NavLink className="card card_link" to="/module5/lesson1" onClick={() => choixCat(4)}>
                  <div className="card_header">
                    <p className="card_title">{MODULE_TITRE(sessionStorage.getItem("Lang"), 4)}</p>
                  </div>

                  <div className="card_body">
                    <img
                      src="./img/internet.png"
                      alt=""
                      style={{ width: "5vw", height: "3vw" }}
                    />
                  </div>

                  <div className="card_line"></div>

                  <div className="card_footer">
                    <p className="card_description">{MODULE_DESCR(sessionStorage.getItem("Lang"), 4)}</p>
                  </div>
                </NavLink>
                  </div>
                  <div className="col-sm-2 ">
                  <NavLink className="card card_link" to="/module6/lesson1" onClick={() => choixCat(5)}>
                  <div className="card_header">
                    <p className="card_title">{MODULE_TITRE(sessionStorage.getItem("Lang"), 5)}</p>
                  </div>

                  <div className="card_body">
                    <img
                      src="./img/traitement_texte.png"
                      alt=""
                      style={{ width: "5vw", height: "3vw" }}
                    />
                  </div>

                  <div className="card_line"></div>

                  <div className="card_footer">
                    <p className="card_description">{MODULE_DESCR(sessionStorage.getItem("Lang"), 5)}</p>
                  </div>
                </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col africatik-logo mt-1">
              <AfricatikLogo />
            </div>

      </div>
      
    </div>
  );
};
export default Menu;
