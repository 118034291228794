import React from "react";
import Logo from "../assets/africatik.png";

function AfricatikLogo(props) {
  function lienUrl(lien) {
    const win = window.open(lien, "_blank");
    win.focus();
    //console.log(lien);
  }
  return (
    <div className="logoAfricatik">
      <a target="_blank" rel="noopener noreferrer" >
        <img src={Logo} alt="Africatik" onClick={() => window.open("/africatik", "_blank", "Africatik")}/>
      </a>
    </div>
  );
}

export default AfricatikLogo;
