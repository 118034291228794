import React from 'react'
import images_store from '../utils/Images'

function LogoApp(props) {
  return (
    <div className={props.isLoading?"logoAppConteiner_loading":'logoAppConteiner'}>
        {props.isLoading && <img src={images_store.general.logo_loading} alt="Africatik"
         style={{width:'10vw',height:'10vw'}} />}
        {props.isMain && <img src={images_store.general.logo_main} alt="Africatik" style={{width:'8vw',height:'10vw'}}/>}
    </div>
  )
}

export default LogoApp