import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Etapes, stepsTitle_module2_exercices } from "../utils/Etapes";
import images_store from "../utils/Images";
import { audio_consignes_Mod2, consignes_Mod2, mod1_consignes_lessons, module2_content, Mod_Titles } from "../utils/Lesson_Data";
import AfricatikLogo from "./AfricatikLogo";
import CircleButton from "./CircleButton";
import IndicateurEtape from "./IndicateurEtape";
import LessonContent from "./LessonContent";
import VerticaleImage from "./VerticaleImage";
import LANG from "../properties.json";
import { useEffect } from "react";
import StringWithSound from "./StringWithSound";
import { Howl, Howler } from "howler";

function Mod2() {
  let navigate = useNavigate();
  var LangId = sessionStorage.getItem('Lang');

  const { moduleId } = useParams();

  const page_flex_bool = () => {
    let bool;

    if (page_id(pageId) === 1 || page_id(pageId) === 5) {
      bool = true;
    } else {
      bool = false;
    }

    return bool;
  };

  const style = {
    display: "flex",
  };

  const indexPlusOne = (index) => {
    return index + 1;
  };
  const { pageId } = useParams();

  const [lessonId, setLessonId] = useState(page_id(pageId));
  const [lessonFocusId, setLessonFocusId] = useState(0);

  const [lessonFocusId_onSeveral, setLessonFocusId_onSeveral] = useState(0);

  const handleOnHomeBtnClickEvt = () => {
    navigate("/", { replace: true });
    Howler.stop();
  };

  function handleChange(newValue) {
    setLessonFocusId(newValue);
    setLessonFocusId_onSeveral(0);
  }

  const handle_phoneApp_onClick = () => {
    setLessonFocusId_onSeveral(1);
  };
  const handle_msg_onClick = () => {
    setLessonFocusId_onSeveral(2);
  };
  const handle_camera_onClick = () => {
    setLessonFocusId_onSeveral(3);
  };
  const handle_galerie_onClick = () => {
    setLessonFocusId_onSeveral(4);
  };
  const handle_horloge_onClick = () => {
    setLessonFocusId_onSeveral(5);
  };
  const handle_contact_onClick = () => {
    setLessonFocusId_onSeveral(6);
  };
  const handle_setting_onClick = () => {
    setLessonFocusId_onSeveral(7);
  };
  const handle_calandar_onClick = () => {
    setLessonFocusId_onSeveral(8);
  };
  const handle_clc_onClick = () => {
    setLessonFocusId_onSeveral(9);
  };

  const handle_nav_goBack_onClick = () => {
    setLessonFocusId_onSeveral(2);
  };

  const handle_nav_home_onClick = () => {
    setLessonFocusId_onSeveral(1);
  };
  const handle_nav_home_onClick1 = () => {
    setLessonFocusId_onSeveral(1);
  };

  const handle_nav_returnToApps_onClick = () => {
    setLessonFocusId_onSeveral(3);
  };

  const content_several_returner = (page) => {
    let content;

    if (page === 0) {
      content = "";
    } else {
      content =
        module2_content[lessonId - 1].onPreciseClickData[lessonFocusId].content[
          page - 1
        ];
    }

    return content;
  };

  const handleOnBarClickEvt = (path, key) => {
    navigate(path, { replace: true });
    setLessonId(key);
    setLessonFocusId(0);
    setLessonFocusId_onSeveral(0);
    Howler.stop();
  };

  let array = [];

  switch (lessonId - 1) {
    case 0:
      array = [
        Etapes.module2[0].image_orange_src,
        Etapes.module2[1].image_src,
        Etapes.module2[2].image_src,
        Etapes.module2[3].image_src,
        Etapes.module2[4].image_src,
        Etapes.module2[5].image_src,
      ];
      break;
    case 1:
      array = [
        Etapes.module2[0].image_src,
        Etapes.module2[1].image_orange_src,
        Etapes.module2[2].image_src,
        Etapes.module2[3].image_src,
        Etapes.module2[4].image_src,
        Etapes.module2[5].image_src,
      ];
      break;
    case 2:
      array = [
        Etapes.module2[0].image_src,
        Etapes.module2[1].image_src,
        Etapes.module2[2].image_orange_src,
        Etapes.module2[3].image_src,
        Etapes.module2[4].image_src,
        Etapes.module2[5].image_src,
      ];
      break;
    case 3:
      array = [
        Etapes.module2[0].image_src,
        Etapes.module2[1].image_src,
        Etapes.module2[2].image_src,
        Etapes.module2[3].image_orange_src,
        Etapes.module2[4].image_src,
        Etapes.module2[5].image_src,
      ];
      break;
    case 4:
      array = [
        Etapes.module2[0].image_src,
        Etapes.module2[1].image_src,
        Etapes.module2[2].image_src,
        Etapes.module2[3].image_src,
        Etapes.module2[4].image_orange_src,
        Etapes.module2[5].image_src,
      ];
      break;
    case 5:
      array = [
        Etapes.module2[0].image_src,
        Etapes.module2[1].image_src,
        Etapes.module2[2].image_src,
        Etapes.module2[3].image_src,
        Etapes.module2[4].image_src,
        Etapes.module2[5].image_orange_src,
      ];
      break;
    default:
      break;
  }
  let consigne;
  switch (lessonId - 1) {
    case 0:
      consigne = consignes_Mod2.lesson1;
      break;
    case 1:
      consigne = consignes_Mod2.lesson2;
      break;
      
      case 2:
        consigne = consignes_Mod2.lesson3;
        break;
      case 3:consigne =
      consignes_Mod2.lesson4;
        break;
      case 4:
        consigne = consignes_Mod2.lesson5;
        break;
    default:
      break;
  }
  
  const [lessonId_specificData, SetLessonId_specificData] = useState(15);
  const islessonTreeFirst = (pageId, lessonId_specificData) => {
    let response;
  
    if (page_id(pageId) - 1 === 4 && lessonId_specificData === 0) {
      response = false;
    } 
   else {
      response = true;
    }
  
    return response;
  };
  
  const isDefault = (index) => {
    if (index === 15) {
      return true;
    } else {
      return false;
    }
  };

  const HandleOnAudioPlay = () => {
    Howler.stop();
    if (page_id(pageId) === 1) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod2.lesson1],
      });
      sound.play();
    }
    if (page_id(pageId) === 2) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod2.lesson2],
      });
      sound.play();
    }
    if (page_id(pageId) === 3) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod2.lesson3],
      });
      sound.play();
    }
    if (page_id(pageId) === 4) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod2.lesson4],
      });
      sound.play();
    }
    if (page_id(pageId) === 5) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod2.lesson5],
      });
      sound.play();
    }
  };

  function titre_Question(IdLang, page) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[0].sequences[1].pages[
        page
      ].text
    });
    return data.toString();
  }
  //--------------insertion des images ----------------//

  return (
    <div className="moduleConteiner">
      <div className="moduleHead">
        <CircleButton
          className="homeBtn"
          img={images_store.general.backHomeImage}
          onClickEvt={handleOnHomeBtnClickEvt}
        />
        <h2 className="chapter_Title">{Mod_Titles.module2}</h2>
      </div>

      <div className="stepConteiner">
        {Etapes.module2.map((element, index) => (
          <IndicateurEtape
            key={index}
            image_src={array[index]}
            title={element.title}
            handleOnclickBarEvt={() =>
              handleOnBarClickEvt(element.path, element.key)
            }
          />
        ))}
      </div>

      {isExercices(lessonId) ? (
        <div className="exos_board">
          {stepsTitle_module2_exercices.map((elt, index) => (
            <div key={index} className="ExerciceCards">
              <img
                className="listImg"
                onClick={() => {
                  navigate(
                    "/" +
                      moduleId +
                      "/" +
                      
                      pageId +
                      "/E" +
                      indexPlusOne(index).toString(),
                    { replace: true }
                  );
                }}
                src="../img/schedule.png"
                alt=""
              />
              <p>{elt}</p>
            </div>
          ))}
        </div>
      ) : (
        <div >
          
          <div className={"moduleBody"} style={{}}>
          {page_id(pageId) - 1 !== 6 &&
              islessonTreeFirst(pageId, lessonId_specificData) && (
                <div className="" style={{ marginLeft: "10%",marginTop:'-2%' }}>
                  <StringWithSound
                    string={consigne}
                    onAudioPlay={HandleOnAudioPlay}
                    hasAudio={true}
                  />
                </div>
              )}
            <div className={"contentAndImage"}>
              <div className="col part_image">
                {lessonId === 1 && (
                  <div className="mod2_lesson1_ImgPatter transform_mod2_1" style={{marginTop:'-2%'}}>
                    <VerticaleImage
                      img={
                        "../imgs/" +
                        images_store.mod2.lessons[lessonId - 1].imgs[0]
                      }
                    />
                  </div>
                )}

                {lessonId === 2 && (
                  <div className="mod2_lesson1_ImgPatter transform_mod2_2" >
                    <VerticaleImage
                      img={
                        "../imgs/" +
                        images_store.mod2.lessons[lessonId - 1].imgs[
                          lessonFocusId
                        ]
                      }
                      lessonId_into={lessonId}
                      setCurrentIndex={handleChange}
                      currentIndex={lessonFocusId}
                      is_notFirst={lessonFocusId === 0 ? false : true}
                      is_notLast={
                        lessonFocusId ===
                        images_store.mod2.lessons[lessonId - 1].imgs.length - 1
                          ? false
                          : true
                      }
                      lessonArrayLenght={
                        images_store.mod2.lessons[lessonId - 1].imgs.length
                      }
                      is_several={true}
                      style={{
                        width: lessonId === 3 ? "50vw" : "auto",
                      }}
                    />
                  </div>
                )}

                {lessonId === 3 && (
                  <div className="mod2_lesson1_ImgPatter transform_mod2_3">
                    <VerticaleImage
                      img={
                        "../imgs/" +
                        images_store.mod2.lessons[lessonId - 1].imgs[
                          lessonFocusId
                        ]
                      }
                      phoneApp_onClick={handle_phoneApp_onClick}
                      msg_onClick={handle_msg_onClick}
                      camera_onClick={handle_camera_onClick}
                      galerie_onClick={handle_galerie_onClick}
                      horloge_onClick={handle_horloge_onClick}
                      contact_onClick={handle_contact_onClick}
                      setting_onClick={handle_setting_onClick}
                      calandar_onClick={handle_calandar_onClick}
                      clc_onClick={handle_clc_onClick}
                      setCurrentIndex={handleChange}
                      nav_goBack_onClick={handle_nav_goBack_onClick}
                      nav_home_onClick={handle_nav_home_onClick}
                      nav_returnToApps_onClick={handle_nav_returnToApps_onClick}
                      lessonId_intos={lessonId}
                      lessonId_intox={lessonId}
                      currentIndex={lessonFocusId}
                      lessonArrayLenght={
                        images_store.mod2.lessons[lessonId - 1].imgs.length
                      }
                      is_several={true}
                      is_notFirst={lessonFocusId === 0 ? false : true}
                      is_notLast={
                        lessonFocusId ===
                        images_store.mod2.lessons[lessonId - 1].imgs.length - 1
                          ? false
                          : true
                      }
                    />
                  </div>
                )}

                {lessonId === 4 && (
                  <div className="mod2_lesson1_ImgPatter transform_mod2_4" style={{}}>
                    <VerticaleImage
                      img={
                        "../imgs/" +
                        images_store.mod2.lessons[lessonId - 1].imgs[
                          lessonFocusId
                        ]
                      }
                      lessonId_into={lessonId}
                      setCurrentIndex={handleChange}
                      currentIndex={lessonFocusId}
                      is_notFirst={lessonFocusId === 0 ? false : true}
                      is_notLast={
                        lessonFocusId ===
                        images_store.mod2.lessons[lessonId - 1].imgs.length - 1
                          ? false
                          : true
                      }
                      lessonArrayLenght={
                        images_store.mod2.lessons[lessonId - 1].imgs.length
                      }
                      is_several={true}
                    />
                  </div>
                )}

                {lessonId === 5 && (
                  <div className="mod2_lesson1_ImgPatter transform_mod2_5">
                    <VerticaleImage
                      img={
                        "../imgs/" +
                        images_store.mod2.lessons[lessonId - 1].imgs[
                          lessonFocusId
                        ]
                      }
                      lessonId_into={lessonId}
                      setCurrentIndex={handleChange}
                      currentIndex={lessonFocusId}
                      lessonArrayLenght={
                        images_store.mod2.lessons[lessonId - 1].imgs.length
                      }
                      is_several={true}
                      is_notFirst={lessonFocusId === 0 ? false : true}
                      is_notLast={
                        lessonFocusId ===
                        images_store.mod2.lessons[lessonId - 1].imgs.length - 1
                          ? false
                          : true
                      }
                    />
                  </div>
                )}
              </div>
              <div className="col part_content" style={{zIndex:"100"}}>
                {lessonId === 1 && (
                  <div className="col transform_mod2_1_text" style={{marginTop:'-2%'}}>
                    <LessonContent
                      isMod2Flex={page_flex_bool()}
                      img={images_store.mod2.lessons[lessonId - 1].imgs[1]}
                      title={module2_content[0].initialData.title}
                      content={module2_content[0].initialData.content}
                      sub_content={module2_content[0].initialData.sub_content}
                    />
                  </div>
                )}

                {lessonId === 2 && (
                  <LessonContent
                    isMod2Flex={page_flex_bool()}
                    title={
                      module2_content[lessonId - 1].onPreciseClickData[
                        lessonFocusId
                      ].title
                    }
                    content={
                      module2_content[lessonId - 1].onPreciseClickData[
                        lessonFocusId
                      ].content
                    }
                    sub_content={
                      module2_content[lessonId - 1].onPreciseClickData[
                        lessonFocusId
                      ].sub_content
                    }
                    onAudioPlay={() => {
                      Howler.stop();
                      var sound = new Howl({
                        src: [
                          "../sounds/" +
                            module2_content[lessonId - 1].onPreciseClickData[
                              lessonFocusId
                            ].audio,
                        ],
                      });
                      sound.play();
                      }
                    }
                    hasAudio={true}
                  />
                )}

                {lessonId === 3 && (
                  <LessonContent
                    isMod2Flex={page_flex_bool()}
                    title={
                      module2_content[lessonId - 1].onPreciseClickData[
                        lessonFocusId
                      ].title
                    }
                    content={
                      lessonFocusId === 2 || lessonFocusId === 3
                        ? content_several_returner(lessonFocusId_onSeveral)
                        : module2_content[lessonId - 1].onPreciseClickData[
                            lessonFocusId
                          ].content
                    }
                    sub_content={
                      module2_content[lessonId - 1].onPreciseClickData[
                        lessonFocusId
                      ].sub_content
                    }
                    onAudioPlay={() => {
                      Howler.stop();
                      var sound = new Howl({
                        src: [
                          "../sounds/" +
                            module2_content[lessonId - 1].onPreciseClickData[
                              lessonFocusId
                            ].audio,
                        ],
                      });
                      sound.play();
                      }
                    }
                    hasAudio={true}
                  />
                )}

                {lessonId === 4 && (
                  <LessonContent
                    isMod2Flex={page_flex_bool()}
                    title={
                      module2_content[lessonId - 1].onPreciseClickData[
                        lessonFocusId
                      ].title
                    }
                    content={
                      module2_content[lessonId - 1].onPreciseClickData[
                        lessonFocusId
                      ].content
                    }
                    sub_content={
                      module2_content[lessonId - 1].onPreciseClickData[
                        lessonFocusId
                      ].sub_content
                    }
                    onAudioPlay={() => {
                      Howler.stop();
                      var sound = new Howl({
                        src: [
                          "../sounds/" +
                            module2_content[lessonId - 1].onPreciseClickData[
                              lessonFocusId
                            ].audio,
                        ],
                      });
                      sound.play();
                      }
                    }
                    hasAudio={true}
                  />
                )}

                {lessonId === 5 && (
                  <LessonContent
                    isMod2Flex={page_flex_bool()}
                    title={
                      module2_content[lessonId - 1].onPreciseClickData[
                        lessonFocusId
                      ].title
                    }
                    content={
                      module2_content[lessonId - 1].onPreciseClickData[
                        lessonFocusId
                      ].content
                    }
                    sub_content={
                      module2_content[lessonId - 1].onPreciseClickData[
                        lessonFocusId
                      ].sub_content
                    }
                    onAudioPlay={() => {
                      Howler.stop();
                      var sound = new Howl({
                        src: [
                          "../sounds/" +
                            module2_content[lessonId - 1].onPreciseClickData[
                              lessonFocusId
                            ].audio,
                        ],
                      });
                      sound.play();
                      }
                    }
                    hasAudio={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const page_id = (page) => {
  let id;

  if (page === "lesson1") {
    id = 1;
  } else if (page === "lesson2") {
    id = 2;
  } else if (page === "lesson3") {
    id = 3;
  } else if (page === "lesson4") {
    id = 4;
  } else if (page === "lesson5") {
    id = 5;
  } else if (page === "exercices") {
    id = 6;
  }

  return id;
};

const isExercices = (lesson) => {
  if (lesson === 6) {
    return true;
  } else {
    return false;
  }
};

export default Mod2;
