import React, { useRef, useState } from "react";
import AfricatikLogo from "../components/AfricatikLogo";
import Card from "../components/Card";
import CircleButton from "../components/CircleButton";
import LogoApp from "../components/LogoApp";
import card_content from "../utils/Card_Data";
import images_store from "../utils/Images";
import { CSSTransition } from "react-transition-group";
import LANG from '../properties.json'
import { useEffect } from "react";

if (sessionStorage.getItem("Lang") === null) {
  sessionStorage.setItem("Lang", 0);
}


const Home = () => {

  sessionStorage.setItem("onglet", "one");

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className="home">
      <div className="homeHeat">
        <div className="homeHeat_left">
          <LogoApp isMain={true} />
        </div>

        <div className="homeHeat_right">
          <CircleButton
            img={images_store.general.settingsImage}
            onClickEvt={() => setOpenMenu(!openMenu)}
          />
          {openMenu && <DropdownMenu openState={openMenu} />}
        </div>
      </div>

      <div className="card_conteiner">
        {card_content.map((card_data, index) => (
          <Card
            path={card_data.path}
            key={card_data.sequence_id}
            title={card_data.title}
            img_src={card_data.img_file}
            description={card_data.description}
          />
        ))}
      </div>
      <AfricatikLogo />
    </div>
  );
};

function Languages(Idlangue){
  const data = LANG.map((info) => {
    return info.languages[Idlangue].name;
  });

  return data;
}
const handleSelect = (e) => {
  sessionStorage.setItem("Lang", e);
};

function DropdownMenu(props) {
  
  return (
    <CSSTransition in={props.openState} timeout={500} classNames="menu-primary">
      <div className="dropdown" size="lg" title=""
       onSelect={(e) => handleSelect(e)} >
        <button className="dropdown_item" eventKey="0" href="/" onClick={(e)=>handleSelect(e)}>{Languages(0)}</button>
        <button className="dropdown_item" eventKey="1" href="/" onClick={(e)=>handleSelect(e)}>{Languages(1)}</button>
        <button className="dropdown_item" eventKey="2" href="/">{Languages(2)}</button>
        <button className="dropdown_item" eventKey="3" href="/">{Languages(3)}</button>
        <button className="dropdown_item" eventKey="4" href="/">{Languages(4)}</button>
        <button className="dropdown_item" eventKey="5" href="/">{Languages(5)}</button>
      </div>
    </CSSTransition>
  );
}

export default Home;
