import React from "react";
import { CSSTransition } from "react-transition-group";
import HImage from "./HImage";
import soundImg from "../assets/icone_son.png";

const LessonContent = (props) => {
  const tle = props.title;
  const text = props.content;
  const sub_text = props.sub_content;

  const applyDisplayNone = {
    display: "none",
  };

  const applyCenter = {
    textAlign: "center",
  };

  // const prop

  var title = stringFormer(tle);
  var content = stringFormer(text);
  var sub_content = stringFormer(sub_text);

  return (
    <div className={props.isMod2Flex ? "textConteiner_Mod2" : "textConteiner"} >
      <div
        in={isNotNull(title)}
        timeout={200}
        unmountonexit="true"
        classNames="content"
      >
        <div style={{ display: "inline-block"}}>
          <p
            className="content_title_style"
          >
            {title}
          {props.hasAudio && (
            <img
              src={soundImg}
              alt="sound"
              style={{marginLeft: "0.5vw", width: "1.7vw", height: "1.7vw", cursor: "pointer", verticalAlign: "middle"}}
              onClick={props.onAudioPlay}
            />
          )}
          </p>
        </div>
      </div>
      <div
        in={isNotNull(content)}
        timeout={200}
        unmountonexit="true"
        classNames="content"
      >
         <div className="" style={{marginBottom:'-2%'}}>
        <p
          className="content_style"
          dangerouslySetInnerHTML={{ __html: text }}
        />
          </div> 
      </div>
      <div
        in={isNotNull(sub_content)}
        timeout={200}
        unmountonexit="true"
        classNames="content"
      >
        <div className="content_style_sub">
          <p
            className="content_style"
            dangerouslySetInnerHTML={{ __html: sub_content }}
          />
        </div>
      </div>
      <div style={props.is_mod2_lesson1 ? applyCenter : applyDisplayNone}>
        <HImage img={props.img} />
      </div>
    </div>
  );
};

function isNotNull(string) {
  if (string !== "") {
    return true;
  } else {
    return false;
  }
}

function stringFormer(string) {
  if (string == null) {
    return "";
  } else {
    const txt = string.replace(/<[^>]+>/g, "");
    return txt;
  }
}

export default LessonContent;
