import React from 'react'

function CircleButton(props) {
  return (
    <div className='circleBtnConteiner'>
        <button onClick={props.onClickEvt}>
            <img src={props.img} alt="circle button"
            style={{width:'3vw'}} 
            />
        </button>
    </div>
  )
}

export default CircleButton