import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Etapes, stepsTitle_module6, stepsTitle_module6_exercices } from "../utils/Etapes";
import images_store from "../utils/Images";
import { consignes_Mod6, module3_content, module6_content, Mod_Titles, audio_consignes_Mod6 } from "../utils/Lesson_Data";
import AfricatikLogo from "./AfricatikLogo";
import CircleButton from "./CircleButton";
import HImageMod3 from "./HImageMod3";
import IndicateurEtape from "./IndicateurEtape";
import LessonContent from "./LessonContent";
import ModImage from "./ModImage";
import StringWithSound from "./StringWithSound";
import LANG from "../properties.json";
import { Howl,Howler } from "howler";

function Mod6() {
  let navigate = useNavigate();

  const { moduleId } = useParams();
  const { pageId } = useParams();
  const [lessonId, setLessonId] = useState(page_id(pageId));
  const [lessonFocusId, setLessonFocusId] = useState(0);
  
  const [lessonFocusId_onSeveral, setLessonFocusId_onSeveral] = useState(0);
  var LangId = sessionStorage.getItem('Lang');

  function titre_Question(IdLang, page) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[0].sequences[5].pages[
        page
      ].text
    });
    return data.toString();
  }
  function titre_Quest(IdLang,page,quest) {
    const data = LANG.map((info) => {
      return info.languages[IdLang].domains[0].subdomains[0].sequences[5].pages[
        page
      ].questions[quest].text
    });
    return data.toString();
  }

  const handleOnHomeBtnClickEvt = () => {
    navigate("/", { replace: true });
    Howler.stop();
  };

  const handleOnBarClickEvt = (path, key) => {
    navigate(path, { replace: true });
    setLessonId(key);
    setLessonFocusId(0);
    setLessonFocusId_onSeveral(0);
    Howler.stop();
    // SetLessonId_specificData(15);
  };
  function handleChange(newValue) {
    setLessonFocusId(newValue);
    setLessonFocusId_onSeveral(0);
  }
  function handleChange_several(newValue) {
    setLessonFocusId_onSeveral(newValue);
  }

  const HandleOnAudioPlay = () => {
    if (page_id(pageId) === 1) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod6.lesson1],
      });
      sound.play();
    }
    if (page_id(pageId) === 2) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod6.lesson2],
      });
      sound.play();
    }
    if (page_id(pageId) === 3) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod6.lesson3],
      });
      sound.play();
    }
    if (page_id(pageId) === 4) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod6.lesson4],
      });
      sound.play();
    }
    if (page_id(pageId) === 5) {
      var sound = new Howl({
        src: ["../sounds/" + audio_consignes_Mod6.lesson5],
      });
      sound.play();
    }
  };


  const indexPlusOne = (index) => {
    return index + 1;
  };

  let array = [];

  switch (lessonId - 1) {
    case 0:
      array = [
        Etapes.module6[0].image_orange_src,
        Etapes.module6[1].image_src,
        Etapes.module6[2].image_src,
        Etapes.module6[3].image_src,
        Etapes.module6[4].image_src,
        Etapes.module6[5].image_src,
      ];
      break;
    case 1:
      array = [
        Etapes.module6[0].image_src,
        Etapes.module6[1].image_orange_src,
        Etapes.module6[2].image_src,
        Etapes.module6[3].image_src,
        Etapes.module6[4].image_src,
        Etapes.module6[5].image_src,
      ];
      break;
    case 2:
      array = [
        Etapes.module6[0].image_src,
        Etapes.module6[1].image_src,
        Etapes.module6[2].image_orange_src,
        Etapes.module6[3].image_src,
        Etapes.module6[4].image_src,
        Etapes.module6[5].image_src,
      ];
      break;
    case 3:
      array = [
        Etapes.module6[0].image_src,
        Etapes.module6[1].image_src,
        Etapes.module6[2].image_src,
        Etapes.module6[3].image_orange_src,
        Etapes.module6[4].image_src,
        Etapes.module6[5].image_src,
      ];
      break;
    case 4:
      array = [
        Etapes.module6[0].image_src,
        Etapes.module6[1].image_src,
        Etapes.module6[2].image_src,
        Etapes.module6[3].image_src,
        Etapes.module6[4].image_orange_src,
        Etapes.module6[5].image_src,
      ];
      break;
    case 5:
      array = [
        Etapes.module6[0].image_src,
        Etapes.module6[1].image_src,
        Etapes.module6[2].image_src,
        Etapes.module6[3].image_src,
        Etapes.module6[4].image_src,
        Etapes.module6[5].image_orange_src,
      ];
      break;
    default:
      break;
  }
  let consigne;
  switch (lessonId - 1) {
    case 0:
      consigne = consignes_Mod6.lesson1;
      break;
    case 1:
      consigne = consignes_Mod6.lesson2;
      break;
      
      case 4:
        consigne = consignes_Mod6.lesson3
        ;
        break;
      case 3:
        consigne = consignes_Mod6.lesson4;
        break;
      case 2:
        consigne = consignes_Mod6.lesson5;
        break;
    default:
      break;
  }
  
  const [lessonId_specificData, SetLessonId_specificData] = useState(15);
  const islessonTreeFirst = (pageId, lessonId_specificData) => {
    let response;
  
    if (page_id(pageId) - 1 === 4 && lessonId_specificData === 0) {
      response = false;
    } 
   else {
      response = true;
    }
  
    return response;
  };

  var properties = module6_content[lessonId - 1].initialData;
  var specific_properties = module6_content[lessonId - 1].onPreciseClickData;

  const isRedClicked = (value) => {
    let response;

    if (value === 15) {
      response = false;
    } else {
      response = true;
    }

    return response;
  };

  const handleOnCpuChoiceEvt = () => {
    SetLessonId_specificData(3);
  };

  const handleOnKeyboardChoiceEvt = () => {
    SetLessonId_specificData(0);
  };

  const handleOnMouseChoiceEvt = () => {
    SetLessonId_specificData(2);
  };

  const handleOnScreenChoiceEvt = () => {
    SetLessonId_specificData(4);
  };

  const handleOnKeyboardChoiceEvt_lesson2 = () => {
    SetLessonId_specificData(6);
  };

  const handleOnMouseChoiceEvt_lesson2 = () => {
    SetLessonId_specificData(2);
  };

  const handleOnScreenChoiceEvt_lesson2 = () => {
    SetLessonId_specificData(1);
  };

  return (
    <div className="moduleConteiner">
      <div className="moduleHead">
        <CircleButton
          className="homeBtn"
          img={images_store.general.backHomeImage}
          onClickEvt={handleOnHomeBtnClickEvt}
        />
        <h2 className="chapter_Title">{Mod_Titles.module6}</h2>
      </div>

      <div className="stepConteiner">
        {Etapes.module6.map((element, index) => (
          <IndicateurEtape
            key={index}
            image_src={array[index]}
            title={element.title}
            handleOnclickBarEvt={() =>
              handleOnBarClickEvt(element.path, element.key)
            }
          />
        ))}
      </div>
      {isExercices(lessonId) ? (
        <div className="exos_board">
          {stepsTitle_module6_exercices.map((elt, index) => (
            <div key={index} className="ExerciceCards">
              <img
                className="listImg"
                onClick={() => {
                  navigate(
                    "/" +
                      moduleId +
                      "/" +
                      pageId +
                      "/E" +
                      indexPlusOne(index).toString(),
                    { replace: true }
                  );
                }}
                src='../img/schedule.png'
                alt=""
              />
              <p>{elt}</p>
            </div>
          ))}
        </div>
      ) : (
        <>
        <div className="moduleBody" >
        {page_id(pageId) - 1 !== 6 &&
              islessonTreeFirst(pageId, lessonId_specificData) && (
                <div className="" style={{ marginLeft: "2.5%",marginBottom:'5%',marginTop:'-3%' }}>
                  <StringWithSound
                    string={consigne}
                    onAudioPlay={HandleOnAudioPlay}
                    hasAudio={true}
                  />
                </div>
              )}
              <div className={"contentAndImage"}>
                <div className="part_image" style={{ marginLeft: '4%' }}>
                  {lessonId === 1 && (
                    <div className="mod2_ImgPatter">
                      <ModImage

                        setCurrentIndex={handleChange}
                        setCurrentIndex_several={handleChange_several}
                        is_several={true}
                        currentIndex_several={lessonFocusId_onSeveral}
                        several_lenght={2}
                        several_focus={lessonFocusId === 0 ? true : false}
                        currentIndex={lessonFocusId}
                        lessonArrayLenght={images_store.mod6.lessons[lessonId - 1].imgs.length}
                        img={lessonFocusId === 0
                          ? '../imgs/' + images_store.mod6.lessons[lessonId - 1].imgs[lessonFocusId][lessonFocusId_onSeveral]
                          : '../imgs/' + images_store.mod6.lessons[lessonId - 1].imgs[lessonFocusId]}
                        is_notFirst={lessonFocusId === 0 ? false : true}
                        is_notLast={lessonFocusId ===
                          images_store.mod6.lessons[lessonId - 1].imgs.length - 1
                          ? false
                          : true} />
                    </div>
                  )}

                  {lessonId === 2 && (
                    <div className="mod2_ImgPatter" style={{ width: "100%" }}>
                      <ModImage
                        is_several={false}
                        img={'../imgs/' + images_store.mod6.lessons[1]} />
                    </div>
                  )}

                  {lessonId === 3 && (
                    <div className="mod2_ImgPatter">
                      <ModImage

                        setCurrentIndex={handleChange}
                        setCurrentIndex_several={handleChange_several}
                        is_several={true}
                        currentIndex_several={lessonFocusId_onSeveral}
                        several_lenght={2}
                        several_focus={lessonFocusId === 0 ? true : false}
                        currentIndex={lessonFocusId}
                        lessonArrayLenght={images_store.mod6.lessons[lessonId - 1].imgs.length}
                        img={lessonFocusId === 0
                          ? '../imgs/' + images_store.mod6.lessons[lessonId - 1].imgs[lessonFocusId][lessonFocusId_onSeveral]
                          : '../imgs/' + images_store.mod6.lessons[lessonId - 1].imgs[lessonFocusId]}
                        is_notFirst={lessonFocusId === 0 ? false : true}
                        is_notLast={lessonFocusId ===
                          images_store.mod6.lessons[lessonId - 1].imgs.length - 1
                          ? false
                          : true} />
                    </div>
                  )}

                  {lessonId === 4 && (
                    <div className="mod2_ImgPatter " style={{ width: "100%", marginLeft: "-5%"}}>
                      <HImageMod3
                      is_several={false}
                      img={"../imgs/" + images_store.mod6.lessons[3]}
                      currentIndex={lessonFocusId}
                      lessonId_into={lessonId}
                      
                      handleKeyboardChoiceEvt={handleOnKeyboardChoiceEvt}
                      handleScreenChoiceEvt_lesson2={
                        handleOnScreenChoiceEvt_lesson2
                      }
                      handleMouseChoiceEvt={handleOnMouseChoiceEvt}
                      handleCentralUnitChoiceEvt={handleOnCpuChoiceEvt}
                      handleScreenChoiceEvt={handleOnScreenChoiceEvt}
                      handleKeyboardChoiceEvt_lesson2={
                        handleOnKeyboardChoiceEvt_lesson2
                      }
                      handleMouseChoiceEvt_lesson2={handleOnMouseChoiceEvt_lesson2}
                      
                    />
                    </div>
                  )}

                  {lessonId === 5 && (
                    <div className="mod2_ImgPatter">
                      <ModImage

                        setCurrentIndex={handleChange}
                        setCurrentIndex_several={handleChange_several}
                        is_several={true}
                        currentIndex_several={lessonFocusId_onSeveral}
                        several_lenght={2}
                        several_focus={lessonFocusId === 0 ? true : false}
                        currentIndex={lessonFocusId}
                        lessonArrayLenght={images_store.mod6.lessons[lessonId - 1].imgs.length}
                        img={lessonFocusId === 0
                          ? '../imgs/' + images_store.mod6.lessons[lessonId - 1].imgs[lessonFocusId][lessonFocusId_onSeveral]
                          : '../imgs/' + images_store.mod6.lessons[lessonId - 1].imgs[lessonFocusId]}
                        is_notFirst={lessonFocusId === 0 ? false : true}
                        is_notLast={lessonFocusId ===
                          images_store.mod6.lessons[lessonId - 1].imgs.length - 1
                          ? false
                          : true} />
                    </div>
                  )}
                </div>
                <div className="part_content" style={{ marginLeft: '10%' }}>
                  {lessonId === 1 && (
                    <LessonContent
                      title={module6_content[lessonId - 1].onPreciseClickData[lessonFocusId].title}
                      content={module6_content[lessonId - 1].onPreciseClickData[lessonFocusId].content}
                      sub_content={module6_content[lessonId - 1].onPreciseClickData[lessonFocusId].sub_content}
                      onAudioPlay={() => {
                        Howler.stop();
                        var sound = new Howl({
                          src: [
                            "../sounds/" +
                              module6_content[lessonId - 1].onPreciseClickData[
                                lessonFocusId
                              ].audio,
                          ],
                        });
                        sound.play();
                        }
                      } 
                      hasAudio={true}
                      />
                  )}

                  {lessonId === 2 && (
                    <div className="col" style={{}}>

                      <LessonContent
                        title={module6_content[1].initialData.title}
                        content={module6_content[1].initialData.content}
                        sub_content={module6_content[1].initialData.sub_content}
                      />
                    </div>
                  )}

                  {lessonId === 3 && (
                    <LessonContent
                      title={module6_content[lessonId - 1].onPreciseClickData[lessonFocusId].title}
                      content={module6_content[lessonId - 1].onPreciseClickData[lessonFocusId].content}
                      sub_content={module6_content[lessonId - 1].onPreciseClickData[lessonFocusId].sub_content}
                      onAudioPlay={() => {
                        Howler.stop();
                        var sound = new Howl({
                          src: [
                            "../sounds/" +
                              module6_content[lessonId - 1].onPreciseClickData[
                                lessonFocusId
                              ].audio,
                          ],
                        });
                        sound.play();
                        }
                      } 
                      hasAudio={true}
                      />
                  )}

                  {lessonId === 4 && (
                    <div className="col" >

                      <LessonContent
                        title={module6_content[3].initialData.title}
                        content={module6_content[3].initialData.content}
                        sub_content={
                          isRedClicked(lessonId_specificData)
                            ? specific_properties[lessonId_specificData].content
                            : null
                        }
                        />
                    </div>
                  )}

                  {lessonId === 5 && (
                    <LessonContent
                      title={module6_content[4].onPreciseClickData[lessonFocusId].title}
                      content={module6_content[4].onPreciseClickData[lessonFocusId].content}
                      sub_content={module6_content[4].onPreciseClickData[lessonFocusId].sub_content}
                      onAudioPlay={() => {
                        Howler.stop();
                        var sound = new Howl({
                          src: [
                            "../sounds/" +
                              module6_content[4].onPreciseClickData[
                                lessonFocusId
                              ].audio,
                          ],
                        });
                        sound.play();
                        }
                      }
                      hasAudio={true}
                    />
                  )}
                </div>
              </div>
            </div></>
      )}

    </div>
  );
}

const isExercices = (lesson) => {
  if (lesson === 6) {
    return true;
  } else {
    return false;
  }
};

const page_id = (page) => {
  let id;

  if (page === "lesson1") {
    id = 1;
  } else if (page === "lesson2") {
    id = 2;
  } else if (page === "lesson3") {
    id = 3;
  } else if (page === "lesson4") {
    id = 4;
  } else if (page === "lesson5") {
    id = 5;
  } else if (page === "exercices") {
    id = 6;
  }

  return id;
};

export default Mod6;
