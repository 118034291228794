import bo_content from "../properties.json";

const pages_data = [];
const pages_data_mod2 = [];
const pages_data_mod3 = [];
const pages_data_mod4 = [];
const pages_data_mod5 = [];
const pages_data_mod6 = [];

if (sessionStorage.getItem("Lang") === null) {
  sessionStorage.setItem("Lang", 0);
}

var LangId = sessionStorage.getItem('Lang');

const Mod_Titles = {
  module1:
    bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[0].title,
  module2:
    bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[1].title,
  module3:
    bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[2].title,
  module4:
    bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[3].title,
  module5:
    bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[4].title,
  module6:
    bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[5].title,
};

bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[0].pages.map(
  (boContent, index) => {
    return pages_data.push({
      id: boContent.page_id,
      title: boContent.title,
      text: boContent.text,
      questions: boContent.questions,
      medias: boContent.media_pages,
    });
  }
);

bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[1].pages.map(
  (boContent, index) => {
    return pages_data_mod2.push({
      id: boContent.page_id,
      title: boContent.title,
      text: boContent.text,
      questions: boContent.questions,
      medias: boContent.media_pages,
    });
  }
);

bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[2].pages.map(
  (boContent, index) => {
    return pages_data_mod3.push({
      id: boContent.page_id,
      title: boContent.title,
      text: boContent.text,
      questions: boContent.questions,
      medias: boContent.media_pages,
    });
  }
);

bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[3].pages.map(
  (boContent, index) => {
    return pages_data_mod4.push({
      id: boContent.page_id,
      title: boContent.title,
      text: boContent.text,
      questions: boContent.questions,
      medias: boContent.media_pages,
    });
  }
);

bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[4].pages.map(
  (boContent, index) => {
    return pages_data_mod5.push({
      id: boContent.page_id,
      title: boContent.title,
      text: boContent.text,
      questions: boContent.questions,
      medias: boContent.media_pages,
    });
  }
);

bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[5].pages.map(
  (boContent, index) => {
    return pages_data_mod6.push({
      id: boContent.page_id,
      title: boContent.title,
      text: boContent.text,
      questions: boContent.questions,
      medias: boContent.media_pages,
    });
  }
);

const clicks = {
  clickOn: bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[0].pages[5].media_pages[0].audio_file,
  clickRight: bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[1].pages[5].media_pages[0].audio_file,
  clickStep: bo_content[0].languages[parseInt(LangId)].domains[0].subdomains[0].sequences[1].pages[5].media_pages[1].audio_file
};

// ============= donnees du module 1 ===============

// ============= donnees du module 1 première lecon ===============

const mod1_lesson1_keyboard_title = pages_data[0].questions[2];
const mod1_lesson1_screen_title = pages_data[0].questions[0];
const mod1_lesson1_mouse_title = pages_data[0].questions[3];
const mod1_lesson1_cpu_title = pages_data[0].questions[1];

const mod1_lesson1_keyboard_corresponding_text =
  pages_data[0].questions[2].responses[0];
const mod1_lesson1_screen_corresponding_text =
  pages_data[0].questions[0].responses[0];
const mod1_lesson1_mouse_corresponding_text =
  pages_data[0].questions[3].responses[0];
const mod1_lesson1_cpu_corresponding_text =
  pages_data[0].questions[1].responses[0];

const mod1_lesson1_content = {
  initialData: { title: null, content: null, sub_content: null, audio: null },
  onPreciseClickData: [
    {
      title: mod1_lesson1_keyboard_title.text,
      content: mod1_lesson1_keyboard_corresponding_text.text,
      sub_content: null,
      audio: pages_data[0].questions[2].media_questions[0].audio_file,
    },
    {
      title: mod1_lesson1_screen_title.text,
      content: mod1_lesson1_screen_corresponding_text.text,
      sub_content: null,
      audio: pages_data[0].questions[0].media_questions[0].audio_file,
    },
    {
      title: mod1_lesson1_mouse_title.text,
      content: mod1_lesson1_mouse_corresponding_text.text,
      sub_content: null,
      audio: pages_data[0].questions[3].media_questions[0].audio_file,
    },
    {
      title: mod1_lesson1_cpu_title.text,
      content: mod1_lesson1_cpu_corresponding_text.text,
      sub_content: null,
      audio: pages_data[0].questions[1].media_questions[0].audio_file,
    },
  ],
};

// ===================================================

// ============= donnees du module 1 deuxième lesson  ===============

const mod1_lesson2_keyboard_title = pages_data[1].questions[2];
const mod1_lesson2_screen_title = pages_data[1].questions[0];
const mod1_lesson2_mouse_title = pages_data[1].questions[1];

const mod1_lesson2_keyboard_corresponding_text =
  pages_data[1].questions[2].responses[0];
const mod1_lesson2_screen_corresponding_text =
  pages_data[1].questions[0].responses[0];
const mod1_lesson2_mouse_corresponding_text =
  pages_data[1].questions[1].responses[0];

const mod1_lesson2_content = {
  initialData: { title: null, content: null, sub_content: null, /*audio: null*/ },
  onPreciseClickData: [
    {
      title: mod1_lesson2_keyboard_title.text,
      content: mod1_lesson2_keyboard_corresponding_text.text,
      sub_content: null,
      audio: pages_data[0].questions[2].media_questions[0].audio_file, //a modifier
    },
    {
      title: mod1_lesson2_screen_title.text,
      content: mod1_lesson2_screen_corresponding_text.text,
      sub_content: null,
      audio: pages_data[0].questions[0].media_questions[0].audio_file, //a modifier
    },
    {
      title: mod1_lesson2_mouse_title.text,
      content: mod1_lesson2_mouse_corresponding_text.text,
      sub_content: null,
      audio: pages_data[0].questions[3].media_questions[0].audio_file, //a modifier
    },
  ],
};

const mod1_lesson3_cartemere_corres = pages_data[2].questions[0];
const mod1_lesson3_process_corres = pages_data[2].questions[1];
const mod1_lesson3_memoire_vive_corres = pages_data[2].questions[2];
const mod1_lesson3_disqueDur_corress = pages_data[2].questions[3];
const mod1_lesson3_alimentation_corres = pages_data[2].questions[4];
const mod1_lesson3_carteGraphique_corres = pages_data[2].questions[5];
const mod1_lesson3_ventilateur_corres = pages_data[2].questions[6];
const mod1_lesson3_cartesonnore_corres = pages_data[2].questions[7];
const mod1_lesson3_carteresau_corres = pages_data[2].questions[8];
const mod1_lesson3_USB_corres = pages_data[2].questions[9];
const mod1_lesson3_HDMI_corress = pages_data[2].questions[10];

const mod1_lesson3_cartemere=
pages_data[2].questions[0].responses[0];
const mod1_lesson3_process =
pages_data[2].questions[1].responses[0];
const mod1_lesson3_memoire_vive =
pages_data[2].questions[2].responses[0];
const mod1_lesson3_disqueDur =
pages_data[2].questions[3].responses[0];
const mod1_lesson3_alimentation =
pages_data[2].questions[4].responses[0];
const mod1_lesson3_carteGraphique =
pages_data[2].questions[5].responses[0];
const mod1_lesson3_ventilateur =
pages_data[2].questions[6].responses[0];
const mod1_lesson3_cartesonnore =
pages_data[2].questions[7].responses[0];
const mod1_lesson3_carteresau =
pages_data[2].questions[8].responses[0];

const mod1_lessons3_content = {
  initialData: { title: null, content: null, sub_content: null, audio: null },
  onPreciseClickData: [
    {
      title: mod1_lesson3_cartemere_corres.text,
      content: mod1_lesson3_cartemere.text,
      sub_content: null,
      audio: pages_data[2].questions[0].media_questions[0].audio_file,
    },
    {
      title: mod1_lesson3_process_corres.text,
      content: mod1_lesson3_process.text,
      sub_content: null,
      audio: pages_data[2].questions[1].media_questions[0].audio_file,
    },
    {
      title:  mod1_lesson3_memoire_vive_corres.text,
      content: mod1_lesson3_memoire_vive.text,
      sub_content: null,
      audio: pages_data[2].questions[2].media_questions[0].audio_file,
    },
    {
      title:  mod1_lesson3_disqueDur_corress.text,
      content: mod1_lesson3_disqueDur.text,
      sub_content: null,
      audio: pages_data[2].questions[3].media_questions[0].audio_file,
    },
    {
      title:  mod1_lesson3_alimentation_corres.text,
      content: mod1_lesson3_alimentation.text,
      sub_content: null,
      audio: pages_data[2].questions[4].media_questions[0].audio_file,
    },
    {
      title:  mod1_lesson3_carteGraphique_corres.text,
      content: mod1_lesson3_carteGraphique.text,
      sub_content: null,
      audio: pages_data[2].questions[5].media_questions[0].audio_file,
    },
    {
      title:  mod1_lesson3_ventilateur_corres.text,
      content: mod1_lesson3_ventilateur.text,
      sub_content: null,
      audio: pages_data[2].questions[6].media_questions[0].audio_file,
    },
    {
      title:  mod1_lesson3_cartesonnore_corres.text,
      content: mod1_lesson3_cartesonnore.text,
      sub_content: null,
      audio: pages_data[2].questions[7].media_questions[0].audio_file,
    },
    {
      title:  mod1_lesson3_carteresau_corres.text,
      content: mod1_lesson3_carteresau.text,
      sub_content: null,
      audio: pages_data[2].questions[8].media_questions[0].audio_file,
    },
    {
      title: mod1_lesson3_USB_corres,
     // content: mod1_lesson3_USB_corres.text,
      sub_content: null,
    },{
      title: mod1_lesson3_HDMI_corress.text,
      //content: mod1_lesson3_HDMI_corress.text,
      sub_content: null,
      audio: pages_data[2].questions[10].media_questions[0].audio_file,
    },
  ],
};

// ===================================================

// ============= donnees du module 1 troisieme lesson  ===============

const mother_board_title_text = pages_data[2].questions[0];
const cpu_lesson3_title_text = pages_data[2].questions[1];
const ram_title_text = pages_data[2].questions[2];
const hard_disk_title_text = pages_data[2].questions[3];
const power_title_text = pages_data[2].questions[4];
const graphik_card_title_text = pages_data[2].questions[5];
const fan_title_text = pages_data[2].questions[6];
const sound_card_title_text = pages_data[2].questions[7];
const network_card_title_text = pages_data[2].questions[8];
const usb_output_title_text = pages_data[2].questions[9];
const hdmi_output_title_text = pages_data[2].questions[10];

const mother_board_corresponding_text = pages_data[2].questions[0].responses[0];
const cpu_lesson3_corresponding_text = pages_data[2].questions[1].responses[0];
const ram_corresponding_text = pages_data[2].questions[2].responses[0];
const hard_disk_corresponding_text = pages_data[2].questions[3].responses[0];
const power_corresponding_text = pages_data[2].questions[4].responses[0];
const graphik_card_corresponding_text = pages_data[2].questions[5].responses[0];
const fan_corresponding_text = pages_data[2].questions[6].responses[0];
const sound_card_corresponding_text = pages_data[2].questions[7].responses[0];
const network_card_corresponding_text = pages_data[2].questions[8].responses[0];
// const usb_output_corresponding_text = pages_data[2].questions[9].responses[0];
// const hdmi_output_corresponding_text = pages_data[2].questions[10].responses[0];

const mother_board_text =
  mother_board_title_text.text + " : " + mother_board_corresponding_text.text;
const cpu_lesson3_text =
  cpu_lesson3_title_text.text + " : " + cpu_lesson3_corresponding_text.text;
const ram_text = ram_title_text.text + " : " + ram_corresponding_text.text;
const hard_disk_text =
  hard_disk_title_text.text + " : " + hard_disk_corresponding_text.text;
const power_text =
  power_title_text.text + " :" + power_corresponding_text.text;
const graphik_card_text =
  graphik_card_title_text.text + " : " + graphik_card_corresponding_text.text;
const fan_text = fan_title_text.text + " : " + fan_corresponding_text.text;
const sound_card_text =
  sound_card_title_text.text + " : " + sound_card_corresponding_text.text;
const network_card_text =
  network_card_title_text.text + " : " + network_card_corresponding_text.text;
const usb_output_text = usb_output_title_text.text;
const hdmi_output_text = hdmi_output_title_text.text;

const mod1_lesson3_subContent = [
  mother_board_text,
  cpu_lesson3_text,
  ram_text,
  hard_disk_text,
  power_text,
  graphik_card_text,
  fan_text,
  sound_card_text,
  network_card_text,
  usb_output_text,
  hdmi_output_text,
];

let mod1_lesson3_onPreciseClickData = mod1_lesson3_subContent.map((elt) => {
  return {
    title: null,
    // content: pages_data[2].text,
    content: null,
    sub_content: elt,
  };
});

// const mod1_lesson3_content = {
//   initialData: { title: null, content: pages_data[2].text, sub_content: null },
//   onPreciseClickData: mod1_lesson3_onPreciseClickData,
// };

const mod1_lesson3_content = {
  initialData: { title: null, content: null, sub_content: null },
  onPreciseClickData: mod1_lesson3_onPreciseClickData,
};

// ===================================================

// ============= donnees du module 1 quatrieme lesson  ===============

const mod1_lesson4_content = {
  initialData: { title: pages_data[3].questions[5].text, content: null, sub_content: pages_data[3].text, audio: pages_data[0].questions[3].media_questions[0].audio_file },
  onPreciseClickData: [
    {
      title: pages_data[3].questions[5].text,
      content: null,
      sub_content: pages_data[3].text,
      audio: pages_data[0].questions[3].media_questions[0].audio_file,
    },
    {
      title: pages_data[3].questions[0].text,
      content: null,
      sub_content: pages_data[3].text,
      audio: pages_data[3].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data[3].questions[1].text,
      content: null,
      sub_content: pages_data[3].text,
      audio: pages_data[3].questions[1].media_questions[1].audio_file,
    },
    {
      title: pages_data[3].questions[2].text,
      content: null,
      sub_content: pages_data[3].text,
      audio: pages_data[3].questions[2].media_questions[1].audio_file,
    },
    {
      title: pages_data[3].questions[3].text,
      content: null,
      sub_content: pages_data[3].text,
      audio: pages_data[3].questions[3].media_questions[1].audio_file,
    },
  ],
};

// ===================================================

// ============= donnees du module 1 cinquieme lesson  ===============

const mod1_lesson5_content = {
  initialData: {
    title: pages_data[0].questions[2].text,
    content: pages_data[4].text,
    sub_content: null,
    audio: pages_data[0].questions[2].media_questions[0].audio_file,
  },
  onPreciseClickData: [
    {
      title: pages_data[0].questions[2].text,
      content: pages_data[4].text,
      sub_content: null,
      audio: pages_data[0].questions[2].media_questions[0].audio_file,
    },
  ]
};

// ===================================================

// ============= donnees par defaut dans l'affichage des exercices  ===============

const mod1_lesson6_content = {
  initialData: {
    title: null,
    content: null,
    sub_content: null,
  },
};

// ===================================================

// ============= toutes les donnees du module 1 sans les exercices ===============
const module1_content = [
  mod1_lesson1_content,
  mod1_lesson2_content,
  mod1_lessons3_content,
  mod1_lesson4_content,
  mod1_lesson5_content,
  mod1_lesson6_content,
];
// ===================================================

// ============= donnees des exercices module 1 ===============

const exercices_titles = pages_data[5].questions.map((title) => title.text);

const consignes_lesson1 = pages_data[5].questions[0].responses.map(
  (consigne) => consigne.text
);
const consignes_lesson2 = pages_data[5].questions[1].responses.map(
  (consigne) => consigne.text
);
const consignes_lesson3 = pages_data[5].questions[2].responses.map(
  (consigne) => consigne.text
);
const consignes_lesson4 = pages_data[5].questions[3].responses.map(
  (consigne) => consigne.text
);
const consignes_lesson5 = pages_data[5].questions[4].responses.map(
  (consigne) => consigne.text
);



const consigne_lesson5 = stringFormer(consignes_lesson5.shift());
const consigne_lesson5_write_here= stringFormer(consignes_lesson5.shift());

const consigne_lesson1_clickOnEveryPart = pages_data[0].text;
const consigne_lesson2_clickOnEveryPart = pages_data[1].text;
const consigne_lesson3_clickOnEveryPart = pages_data[2].text;
const consigne_lesson4_mouse = pages_data[3].questions[4].text;
const consigne_lesson5_keyboard = pages_data[4].questions[0].text;

const consigne_lesson1_module2 = pages_data_mod2[0].questions[0].text;
const consigne_lesson2_module2 = pages_data_mod2[1].text;
const consigne_lesson3_module2 = pages_data_mod2[2].text;
const consigne_lesson4_module2 = pages_data_mod2[3].text;
const consigne_lesson5_module2 = pages_data_mod2[4].text;

//audio consignes module 1
const audio_consigne_lesson1_module1 = pages_data[0].medias[1].audio_file;
const audio_consigne_lesson2_module1 = pages_data[0].medias[1].audio_file;
const audio_consigne_lesson3_module1 = pages_data[0].medias[1].audio_file;
const audio_consigne_lesson4_module1 = pages_data[3].questions[4].media_questions[0].audio_file;
const audio_consigne_lesson5_module1 = pages_data[4].questions[0].media_questions[0].audio_file;

//audio consignes module 2
const audio_consigne_lesson1_module2 = pages_data_mod2[0].questions[0].media_questions[0].audio_file;
const audio_consigne_lesson2_module2 = pages_data_mod2[1].medias[0].audio_file;
const audio_consigne_lesson3_module2 = pages_data_mod2[2].medias[0].audio_file;
const audio_consigne_lesson4_module2 = pages_data_mod2[3].medias[0].audio_file;
const audio_consigne_lesson5_module2 = pages_data_mod2[4].medias[0].audio_file;

const consigne_lesson1_module3 = pages_data_mod3[0].questions[3].text;
const consigne_lesson2_module3 = pages_data_mod3[1].text;
const consigne_lesson3_module3 = pages_data_mod3[2].title
const consigne_lesson4_module3 = pages_data_mod3[3].title;
const consigne_lesson5_module3 = pages_data_mod3[4].title;

//audio consignes module 3
const audio_consigne_lesson1_module3 = pages_data_mod3[0].questions[3].media_questions[0].audio_file;
const audio_consigne_lesson2_module3 = pages_data_mod3[1].medias[0].audio_file;
const audio_consigne_lesson3_module3 = pages_data_mod3[2].medias[1].audio_file;
const audio_consigne_lesson4_module3 = pages_data_mod3[3].medias[0].audio_file;
const audio_consigne_lesson5_module3 = pages_data_mod3[4].medias[0].audio_file;

const consigne_lesson1_module4 = pages_data_mod4[0].text;
const consigne_lesson2_module4 = pages_data_mod4[1].text;
const consigne_lesson3_module4 = pages_data_mod4[2].questions[4].text;
const consigne_lesson4_module4 = pages_data_mod4[3].questions[0].text;
const consigne_lesson5_module4 = pages_data_mod4[4].questions[4].text;

//audio consignes module 4
const audio_consigne_lesson1_module4 = pages_data_mod4[0].medias[0].audio_file;
const audio_consigne_lesson2_module4 = pages_data_mod4[1].medias[0].audio_file;
const audio_consigne_lesson3_module4 = pages_data_mod4[2].questions[4].media_questions[0].audio_file;
const audio_consigne_lesson4_module4 = pages_data_mod4[3].questions[0].media_questions[0].audio_file;
const audio_consigne_lesson5_module4 = pages_data_mod4[4].questions[4].media_questions[0].audio_file;

const consigne_lesson1_module5 = pages_data_mod5[0].questions[6].text;
const consigne_lesson2_module5 = pages_data_mod5[1].questions[3].text;
const consigne_lesson3_module5 = pages_data_mod5[2].questions[8].text;
const consigne_lesson5_module5 = pages_data_mod5[4].text;
const consigne_lesson4_module5 = pages_data_mod5[3].text;

//audio consignes module 5
const audio_consigne_lesson1_module5 = pages_data_mod5[0].questions[6].media_questions[0].audio_file;
const audio_consigne_lesson2_module5 = pages_data_mod5[1].questions[3].media_questions[0].audio_file;
const audio_consigne_lesson3_module5 = pages_data_mod5[2].questions[8].media_questions[0].audio_file;
const audio_consigne_lesson4_module5 = pages_data_mod5[3].medias[0].audio_file;
const audio_consigne_lesson5_module5 = pages_data_mod5[4].medias[0].audio_file;

const consigne_lesson1_module6= pages_data_mod6[0].text;
const consigne_lesson2_module6 = pages_data_mod6[1].title;
const consigne_lesson3_module6 = pages_data_mod6[4].text;
const consigne_lesson4_module6 = pages_data_mod6[3].title;
const consigne_lesson5_module6 = pages_data_mod6[2].text;

//audio consignes module 6
const audio_consigne_lesson1_module6 = pages_data_mod6[0].medias[0].audio_file;
const audio_consigne_lesson2_module6 = pages_data_mod6[1].medias[1].audio_file;
const audio_consigne_lesson3_module6 = pages_data_mod6[2].medias[0].audio_file;
const audio_consigne_lesson4_module6 = pages_data_mod6[3].medias[1].audio_file;
const audio_consigne_lesson5_module6 = pages_data_mod6[4].medias[0].audio_file;

const consigne_lesson3_click = pages_data[3].questions[0].text;
const consigne_lesson3_rightClick = pages_data[3].questions[1].text;
const consigne_lesson3_leftClick = pages_data[3].questions[2].text;
const consigne_lesson3_molette = pages_data[3].questions[3].text;

const consignes_lesson4_lessons = [
  consigne_lesson3_click,
  consigne_lesson3_rightClick,
  consigne_lesson3_leftClick,
  consigne_lesson3_molette,
];
const consignes_Mod4={
  lesson1: consigne_lesson1_module4,
  lesson2: consigne_lesson2_module4,
  lesson3: consigne_lesson3_module4,
  lesson4: consigne_lesson4_module4,
  lesson5: consigne_lesson5_module4
}

const audio_consignes_Mod4={
  lesson1: audio_consigne_lesson1_module4,
  lesson2: audio_consigne_lesson2_module4,
  lesson3: audio_consigne_lesson3_module4,
  lesson4: audio_consigne_lesson4_module4,
  lesson5: audio_consigne_lesson5_module4
}

const consignes_Mod3={
  lesson1: consigne_lesson1_module3,
  lesson2: consigne_lesson2_module3,
  lesson3: consigne_lesson3_module3,
  lesson4: consigne_lesson4_module3,
  lesson5: consigne_lesson5_module3
}

const audio_consignes_Mod3={
    lesson1: audio_consigne_lesson1_module3,
    lesson2: audio_consigne_lesson2_module3,
    lesson3: audio_consigne_lesson3_module3,
    lesson4: audio_consigne_lesson4_module3,
    lesson5: audio_consigne_lesson5_module3
  }


const consignes_Mod2={
  lesson1: consigne_lesson1_module2,
  lesson2: consigne_lesson2_module2,
  lesson3: consigne_lesson3_module2,
  lesson4: consigne_lesson4_module2,
  lesson5: consigne_lesson5_module2
}

const audio_consignes_Mod2={
  lesson1: audio_consigne_lesson1_module2,
  lesson2: audio_consigne_lesson2_module2,
  lesson3: audio_consigne_lesson3_module2,
  lesson4: audio_consigne_lesson4_module2,
  lesson5: audio_consigne_lesson5_module2
}

const consignes_Mod5={
  lesson1: consigne_lesson1_module5,
  lesson2: consigne_lesson2_module5,
  lesson3: consigne_lesson3_module5,
  lesson4: consigne_lesson4_module5,
  lesson5: consigne_lesson5_module5
}

const audio_consignes_Mod5={
  lesson1: audio_consigne_lesson1_module5,
  lesson2: audio_consigne_lesson2_module5,
  lesson3: audio_consigne_lesson3_module5,
  lesson4: audio_consigne_lesson4_module5,
  lesson5: audio_consigne_lesson5_module5
}


const consignes_Mod6={
  lesson1: consigne_lesson1_module6,
  lesson2: consigne_lesson2_module6,
  lesson3: consigne_lesson3_module6,
  lesson4: consigne_lesson4_module6,
  lesson5: consigne_lesson5_module6
}

const audio_consignes_Mod6={
  lesson1: audio_consigne_lesson1_module6,
  lesson2: audio_consigne_lesson2_module6,
  lesson3: audio_consigne_lesson3_module6,
  lesson4: audio_consigne_lesson4_module6,
  lesson5: audio_consigne_lesson5_module6
}

const mod1_consignes_lessons = {
  lesson1: consigne_lesson1_clickOnEveryPart,
  lesson2: consigne_lesson2_clickOnEveryPart,
  lesson3: consigne_lesson3_clickOnEveryPart,
  lesson4: consigne_lesson4_mouse,
  lesson5: consigne_lesson5_keyboard,
};

const audio_consignes_Mod1 = {
  lesson1: audio_consigne_lesson1_module1,
  lesson2: audio_consigne_lesson2_module1,
  lesson3: audio_consigne_lesson3_module1,
  lesson4: audio_consigne_lesson4_module1,
  lesson5: audio_consigne_lesson5_module1,
};


// ------------------ audios files --------------------

const audios_lesson1_clickOnEveryPart = pages_data[0].medias[0].audio_file;
const audios_lesson2_clickOnEveryPart = pages_data[1].medias[0].audio_file;
const audios_lesson3_clickOnEveryPart = pages_data[2].medias[0].audio_file;

const audios_lesson3_click =
  pages_data[3].questions[0].media_questions[0].audio_file;
const audios_lesson3_rightClick =
  pages_data[3].questions[1].media_questions[0].audio_file;
const audios_lesson3_leftClick =
  pages_data[3].questions[2].media_questions[0].audio_file;
const audios_lesson3_molette =
  pages_data[3].questions[3].media_questions[0].audio_file;

const audios_lesson4_lessons = [
  mod1_lesson2_content.onPreciseClickData[2].audio,
  audios_lesson3_click,
  audios_lesson3_rightClick,
  audios_lesson3_leftClick,
  audios_lesson3_molette,
];

const audios_lesson1 = pages_data[5].questions[0].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);
const audios_lesson2 = pages_data[5].questions[1].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);
const audios_lesson3 = pages_data[5].questions[2].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const audios_lesson4_1 =
  pages_data[5].questions[3].responses[0].media_responses[0].audio_file;
const audios_lesson4_2 =
  pages_data[5].questions[3].responses[1].media_responses[0].audio_file;
const audios_lesson4_3 =
  pages_data[5].questions[3].responses[2].media_responses[0].audio_file;

const audios_lesson4 = [audios_lesson4_1, audios_lesson4_2, audios_lesson4_3];

const audios_lesson5 = pages_data[5].questions[4].responses[0].media_responses[0].audio_file;
//console.log(audios_lesson5);

const mod1_audios = {
  lesson1: audios_lesson1,
  lesson2: audios_lesson2,
  lesson3: audios_lesson3,
  lesson4: audios_lesson4,
  lesson5: audios_lesson5,
};

const mod1_audios_lessons = {
  lesson1: audios_lesson1_clickOnEveryPart,
  lesson2: audios_lesson2_clickOnEveryPart,
  lesson3: audios_lesson3_clickOnEveryPart,
  lesson4: audios_lesson4_lessons,
  lesson5: mod1_lesson2_content.onPreciseClickData[0].audio,
};

// ------------------ audios files --------------------

const mod1_exercices_content = {
  title: exercices_titles,
  consignes: [
    consignes_lesson1,
    consignes_lesson2,
    consignes_lesson3,
    consignes_lesson4,
    consignes_lesson5,
  ],
};

//get last elment of an array
const validateText = (mod1_exercices_content.consignes[4].pop());

const mod1_exercice5={
  consigne: consigne_lesson5,
  write_here: consigne_lesson5_write_here
}

// ===================================================

// ==============  donnees du module 2 ==================

// ------------------ audios files --------------------

const audios_lesson2_mod2 =
  pages_data_mod2[5].questions[1].responses[0].media_responses[0].audio_file;

const consigne_lesson2_mod2 = pages_data_mod2[5].questions[1].responses[0].text;

const audios_lesson3_mod2 = pages_data_mod2[5].questions[2].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const consignes_lesson3_mod2 = pages_data_mod2[5].questions[2].responses.map(
  (consigne) => consigne.text
);

const audios_lesson4_mod2 = pages_data_mod2[5].questions[3].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const consignes_lesson4_mod2 = pages_data_mod2[5].questions[3].responses.map(
  (consigne) => consigne.text
);
const audios_lesson5_mod2 = pages_data_mod2[5].questions[4].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const consignes_lesson5_mod2 =
  pages_data_mod2[5].questions[4].responses[0].text;

const mod2_audios_lessons = {
  exercice1: audios_lesson2_mod2,
  exercice2: audios_lesson3_mod2,
  exercice3: audios_lesson4_mod2,
  exercice4: audios_lesson5_mod2,
};

const mod2_consignes_lessons = {
  lesson1: consigne_lesson2_mod2,
  lesson2: consignes_lesson3_mod2,
  lesson3: consignes_lesson4_mod2,
  lesson4: consignes_lesson5_mod2,
};

// ============= donnees du module 2 premiere lesson  ===============
const mod2_lesson1_content = {
  initialData: {
    title: null,
    content: pages_data_mod2[0].text,
    sub_content: null,
    audio: pages_data_mod2[0].medias[0].audio_file,
  },
};
// ===================================================

// ============= donnees du module 2 deuxieme lesson  ===============
const mod2_lesson2_content = {
  initialData: {
    title: pages_data_mod2[1].questions[0].text,
    content: pages_data_mod2[1].questions[0].responses[0].text,
    sub_content: null,
    audio: pages_data_mod2[1].questions[0].media_questions[1].audio_file,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod2[1].questions[0].text,
      content: pages_data_mod2[1].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod2[1].questions[0].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod2[1].questions[1].text,
      content: pages_data_mod2[1].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod2[1].questions[1].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod2[1].questions[2].text,
      content: pages_data_mod2[1].questions[2].responses[0].text,
      sub_content: null,
      audio: pages_data_mod2[1].questions[2].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod2[1].questions[3].text,
      content: pages_data_mod2[1].questions[3].responses[0].text,
      sub_content: null,
      audio: pages_data_mod2[1].questions[3].media_questions[0].audio_file,
    },
  ],
};
// ===================================================


// ============= donnees du module 2 troisieme lesson  ===============
const mod2_lesson3_content = {
  initialData: {
    title: pages_data_mod2[2].questions[0].text,
    content: pages_data_mod2[2].questions[0].responses[0].text,
    sub_content: null,
  },
  onPreciseClickData: [
    
    {
      title: pages_data_mod2[2].questions[0].text,
      content: pages_data_mod2[2].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod2[2].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod2[2].questions[2].text,
      content: null,
      sub_content: null,
      audio: pages_data_mod2[2].questions[2].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod2[2].questions[1].text,
      content: pages_data_mod2[2].questions[1].responses.map((app) => app.text),
      // content: null,
      sub_content: null,
      audio: pages_data_mod2[2].questions[1].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod2[2].questions[3].text,
      content: pages_data_mod2[2].questions[3].responses.map((btn) => btn.text),
      // content: null,
      sub_content: null,
      audio: pages_data_mod2[2].questions[3].media_questions[1].audio_file,
    },
  ],
};
// ===================================================

// ============= donnees du module 2 quatrieme lesson  ===============
const mod2_lesson4_content = {
  initialData: {
    title: null,
    content: pages_data_mod2[3].text,
    sub_content: null,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod2[3].questions[0].text,
      content: pages_data_mod2[3].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod2[3].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod2[3].questions[1].text,
      content: pages_data_mod2[3].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod2[3].questions[1].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod2[3].questions[2].text,
      content: pages_data_mod2[3].questions[2].responses[0].text,
      sub_content: null,
      audio: pages_data_mod2[3].questions[2].media_questions[1].audio_file,
    },
   {
      title: pages_data_mod2[3].questions[3].text,
      //content: pages_data_mod2[3].questions[3].responses[0].text,
      sub_content: null,
      audio: pages_data_mod2[3].questions[3].media_questions[1].audio_file,
    },

    {
      title: pages_data_mod2[3].questions[4].text,
      content: pages_data_mod2[3].questions[4].responses[0].text,
      content: null,
      sub_content: null,
      audio: pages_data_mod2[3].questions[4].media_questions[1].audio_file,
    },
  ],
};
// ===================================================

// ============= donnees du module 2 cinquieme lesson  ===============
const mod2_lesson5_content = {
  initialData: {
    title: pages_data_mod2[4].questions[0].text,
    content: pages_data_mod2[4].questions[0].responses[0].text,
    sub_content: null,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod2[4].questions[0].text,
      content: pages_data_mod2[4].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod2[4].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod2[4].questions[1].text,
      content: pages_data_mod2[4].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod2[4].questions[1].media_questions[1].audio_file,
    },
  ],
};
// ===================================================

// ============= donnees par defaut dans l'affichage des exercices  ===============
const mod2_lesson6_content = {
  initialData: {
    title: null,
    content: null,
    sub_content: null,
  },
};
// ===================================================

// ============= toutes les donnees du module 2 sans les exercices ===============
const module2_content = [
  mod2_lesson1_content,
  mod2_lesson2_content,
  mod2_lesson3_content,
  mod2_lesson4_content,
  mod2_lesson5_content,
  mod2_lesson6_content,
];
// ===================================================

// ============= donnees du module 3 premiere lesson  ===============

const mod3_lesson1_content = {
  initialData: {
    title: pages_data_mod3[0].questions[0].text,
    content: pages_data_mod3[0].questions[0].responses[0].text,
    sub_content: pages_data_mod3[0].text,
    audio: pages_data_mod3[0].questions[0].media_questions[0].audio_file,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod3[0].questions[0].text,
      content: pages_data_mod3[0].text,
      sub_content: pages_data_mod3[0].questions[0].responses[0].text + " " + pages_data_mod3[0].questions[0].responses[1].text + " " + pages_data_mod3[0].questions[0].responses[2].text,
      audio: pages_data_mod3[0].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod3[0].questions[1].text,
      content: pages_data_mod3[0].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod3[0].questions[1].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod3[0].questions[2].text,
      content: pages_data_mod3[0].questions[2].responses[0].text,
      sub_content: null,
      audio: pages_data_mod3[0].questions[2].media_questions[1].audio_file,
    },
  ],
};

const mod3_lesson2_content = {
  initialData: {
    title: pages_data_mod3[1].questions[0].text,
    content: pages_data_mod3[1].questions[0].responses[0].text,
    sub_content: null,
    audio: pages_data_mod3[1].questions[0].media_questions[0].audio_file,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod3[1].questions[0].text,
      content: pages_data_mod3[1].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod3[1].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod3[1].questions[1].text,
      content: pages_data_mod3[1].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod3[1].questions[1].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod3[1].questions[2].text,
      content: pages_data_mod3[1].questions[2].responses[0].text,
      sub_content: null,
      audio: pages_data_mod3[1].questions[2].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod3[1].questions[3].text,
      content: pages_data_mod3[1].questions[3].responses[0].text,
      sub_content: null,
      audio: pages_data_mod3[1].questions[3].media_questions[1].audio_file,
    },
  ],
};

const mod3_lesson3_content = {
  initialData: {
    title: null,
    content: pages_data_mod3[2].text,
    sub_content: null,
    audio: pages_data_mod3[2].medias[1].audio_file,
  },
  onPreciseClickData: { title: null, content: null, sub_content: null, audio: null },
};

const mod3_lesson4_content = {
  initialData: {
    title: pages_data_mod3[3].questions[0].text,
    content: null,
    sub_content: null,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod3[3].questions[0].text,
      content: pages_data_mod3[3].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod3[3].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod3[3].questions[1].text,
      content: pages_data_mod3[3].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod3[3].questions[1].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod3[3].questions[2].text,
      content: pages_data_mod3[3].questions[2].responses[0].text,
      sub_content: null,
      audio: pages_data_mod3[3].questions[2].media_questions[1].audio_file,
    },
  ],
};

const mod3_lesson5_content = {
  initialData: {
    title: null,
    content: pages_data_mod3[4].text,
    sub_content: null,
    audio: pages_data_mod3[4].medias[1].audio_file,
  },
  onPreciseClickData: { title: null, content: null, sub_content: null },
};

const mod3_lesson6_content = {
  initialData: {
    title: null,
    content: null,
    sub_content: null,
  },
};

// ===================================================

// ============= donnees des exercices module 3 ===============

const consignes_Exercice1_mod3 = pages_data_mod3[5].questions[0].responses.map(
  (consigne) => consigne.text
);
const consignes_Exercice2_mod3 = pages_data_mod3[5].questions[1].responses.map(
  (consigne) => consigne.text
);
const consignes_Exercice3_mod3 = pages_data_mod3[5].questions[3].responses.map(
  (consigne) => consigne.text
);

const mod3_consignes = {
  exercice1: consignes_Exercice1_mod3,
  exercice2: consignes_Exercice2_mod3,
  exercice3: consignes_Exercice3_mod3,
};

// ==================================================
// ============= donnees des exercices module 4 ===============

const consignes_Exercice1_mod4 = pages_data_mod4[5].questions[0].responses.map(
  (consigne) => consigne.text
);
const consignes_Exercice2_mod4 = pages_data_mod4[5].questions[1].responses.map(
  (consigne) => consigne.text
);
const consignes_Exercice3_mod4 = pages_data_mod4[5].questions[2].responses.map(
  (consigne) => consigne.text
);
const consignes_Exercice4_mod4 = pages_data_mod4[5].questions[3].responses.map(
  (consigne) => consigne.text
);
const consignes_Exercice5_mod4 = pages_data_mod4[5].questions[4].responses.map(
  (consigne) => consigne.text
);

const mod4_consignes = {
  exercice1: consignes_Exercice1_mod4,
  exercice2: consignes_Exercice2_mod4,
  exercice3: consignes_Exercice3_mod4,
  exercice4: consignes_Exercice4_mod4,
  exercice5: consignes_Exercice5_mod4
  
};

const consignes_Exercice1_mod6 = pages_data_mod6[5].questions[0].responses.map(
  (consigne) => consigne.text
);
const consignes_Exercice2_mod6 = pages_data_mod6[5].questions[1].responses.map(
  (consigne) => consigne.text
);
const consignes_Exercice3_mod6 = pages_data_mod6[5].questions[2].responses.map(
  (consigne) => consigne.text
);
const consignes_Exercice4_mod6 = pages_data_mod6[5].questions[3].responses.map(
  (consigne) => consigne.text
);
const mod6_consignes = {
  exercice1: consignes_Exercice1_mod6,
  exercice2: consignes_Exercice2_mod6,
  exercice3: consignes_Exercice3_mod6,
  exercice4: consignes_Exercice4_mod6,
  
};

// ==================================================

// Audio files

const audios_Exercice1_mod3 = pages_data_mod3[5].questions[0].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const audios_Exercice2_mod3 = pages_data_mod3[5].questions[1].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const audios_Exercice3_mod3 = pages_data_mod3[5].questions[3].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const mod3_audios = {
  exercice1: audios_Exercice1_mod3,
  exercice2: audios_Exercice2_mod3,
  exercice3: audios_Exercice3_mod3,
};

// ============= donnees du module 4  ===============

const mod4_lesson1_content = {
  initialData: {
    title: pages_data_mod4[0].questions[0].text,
    content: pages_data_mod4[0].questions[0].responses[0].text,
    sub_content: null,
    audio: pages_data_mod4[0].questions[0].media_questions[1].audio_file,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod4[0].questions[0].text,
      content: pages_data_mod4[0].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod4[0].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod4[0].questions[1].text,
      content: pages_data_mod4[0].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod4[0].questions[1].media_questions[1].audio_file,
    }
  ],
  
};

const mod4_lesson2_content = {
  initialData: {
    title: pages_data_mod4[1].questions[0].text,
      content: pages_data_mod4[1].questions[0].responses[0].text,
    sub_content: null,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod4[1].questions[0].text,
      content: pages_data_mod4[1].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod4[1].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod4[1].questions[1].text,
      content: pages_data_mod4[1].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod4[1].questions[1].media_questions[1].audio_file,
    },
  ],
};

const mod4_lesson3_content = {
  initialData: {
    title: null,
    content: pages_data_mod4[2].text,
    sub_content: null,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod4[2].questions[0].text,
      content: '',
      sub_content: '',
      audio: pages_data_mod4[2].questions[0].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod4[2].questions[1].text,
      content: '',
      sub_content: '',
      audio: pages_data_mod4[2].questions[1].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod4[2].questions[2].text,
      content: '',
      sub_content: '',
      audio: pages_data_mod4[2].questions[2].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod4[2].questions[3].text,
      content: '',
      sub_content: '',
      audio: pages_data_mod4[2].questions[3].media_questions[0].audio_file,
    }
  ],
};

const mod4_lesson4_content = {
  initialData: {
    title: pages_data_mod4[3].title,
    content: pages_data_mod4[3].text,
    sub_content: null,
    audio: pages_data_mod4[3].medias[1].audio_file,
  },
};

const mod4_lesson5_content = {

  initialData: {
    title: null,
    content: pages_data_mod4[4].text,
    sub_content: null,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod4[4].questions[0].text,
      content: '',
      sub_content: '',
      audio: pages_data_mod4[4].questions[0].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod4[4].questions[1].text,
      content: '',
      sub_content: '',
      audio: pages_data_mod4[4].questions[1].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod4[4].questions[2].text,
      content: '',
      sub_content: '',
      audio: pages_data_mod4[4].questions[2].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod4[4].questions[3].text,
      content: '',
      sub_content: '',
      audio: pages_data_mod4[4].questions[3].media_questions[0].audio_file,
    }
  ],
};

const mod4_lesson6_content = {
  initialData: {
    title: null,
    content: null,
    sub_content: null,
  },
};

// audio files

const audios_Exercice1_mod4 = pages_data_mod4[5].questions[0].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const audios_Exercice2_mod4 = pages_data_mod4[5].questions[1].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const audios_Exercice3_mod4 = pages_data_mod4[5].questions[2].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const audios_Exercice4_mod4 = pages_data_mod4[5].questions[3].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const audios_Exercice5_mod4 = pages_data_mod4[5].questions[4].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const mod4_audios = {
  exercice1: audios_Exercice1_mod4,
  exercice2: audios_Exercice2_mod4,
  exercice3: audios_Exercice3_mod4,
  exercice4: audios_Exercice4_mod4,
  exercice5: audios_Exercice5_mod4,
};

// ==================================================

// ============= donnees du module 5  ===============

// const mod5_lesson1_content = {
//   initialData: {
//     title: null,
//     content: pages_data_mod5[0].text,
//     sub_content: null,
//   },
//   onPreciseClickData: [
//     {
//       title: null,
//       content: pages_data_mod5[0].text,
//       sub_content:
//         pages_data_mod5[0].questions[0].text +
//         " : " +
//         pages_data_mod5[0].questions[0].responses[0].text,
//     },
//     {
//       title: null,
//       content: pages_data_mod5[0].text,
//       sub_content:
//         pages_data_mod5[0].questions[1].text +
//         " : " +
//         pages_data_mod5[0].questions[1].responses[0].text,
//     },
//     {
//       title: null,
//       content: pages_data_mod5[0].text,
//       sub_content:
//         pages_data_mod5[0].questions[2].text +
//         " : " +
//         pages_data_mod5[0].questions[2].responses[0].text,
//     },

//     {
//       title: null,
//       content: pages_data_mod5[0].text,
//       sub_content:
//         pages_data_mod5[0].questions[3].text +
//         " : " +
//         pages_data_mod5[0].questions[3].responses[0].text,
//     },
//     {
//       title: null,
//       content: pages_data_mod5[0].text,
//       sub_content:
//         pages_data_mod5[0].questions[4].text +
//         " : " +
//         pages_data_mod5[0].questions[4].responses[0].text,
//     },
//     {
//       title: null,
//       content: pages_data_mod5[0].text,
//       sub_content:
//         pages_data_mod5[0].questions[5].text +
//         " : " +
//         pages_data_mod5[0].questions[5].responses[0].text,
//     },
//   ],
// };
const mod5_lesson1_content = {
  initialData: {
    title: null,
    content: pages_data_mod5[0].text,
    sub_content: null,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod5[0].questions[0].text,
      content: pages_data_mod5[0].questions[0].responses.map(
        (select) => select.text
      ),
      sub_content: null,
      audio: pages_data_mod5[0].questions[0].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod5[0].questions[1].text,
      content: pages_data_mod5[0].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[0].questions[1].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod5[0].questions[2].text,
      content: pages_data_mod5[0].questions[2].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[0].questions[2].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod5[0].questions[3].text,
      content: pages_data_mod5[0].questions[3].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[0].questions[3].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod5[0].questions[4].text,
      content: pages_data_mod5[0].questions[4].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[0].questions[4].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod5[0].questions[5].text,
      content: pages_data_mod5[0].questions[5].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[0].questions[5].media_questions[0].audio_file,
    },
  ],
};

const mod5_lesson2_content = {
  initialData: {
    title: null,
    content: pages_data_mod5[1].text,
    sub_content: null,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod5[1].questions[0].text,
      content: pages_data_mod5[1].questions[0].responses.map(
        (select) => select.text
      ),
      sub_content: null,
      audio: pages_data_mod5[1].questions[0].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod5[1].questions[1].text,
      content: pages_data_mod5[1].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[1].questions[1].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod5[1].questions[2].text,
      content: pages_data_mod5[1].questions[2].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[1].questions[2].media_questions[0].audio_file,
    }
  
  ],
};

const mod5_lesson3_content = {
  initialData: {
    title: null,
    content: pages_data_mod5[2].text,
    sub_content: null,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod5[2].questions[0].text,
      content: pages_data_mod5[2].questions[0].responses.map(
        (select) => select.text
      ),
      sub_content: null,
      audio: pages_data_mod5[2].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod5[2].questions[1].text,
      content: pages_data_mod5[2].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[2].questions[1].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod5[2].questions[2].text,
      content: pages_data_mod5[2].questions[2].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[2].questions[2].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod5[2].questions[3].text,
      content: pages_data_mod5[2].questions[3].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[2].questions[3].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod5[2].questions[4].text,
      content: pages_data_mod5[2].questions[4].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[2].questions[4].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod5[2].questions[5].text,
      content: pages_data_mod5[2].questions[5].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[2].questions[5].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod5[2].questions[6].text,
      content: pages_data_mod5[2].questions[6].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[2].questions[6].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod5[2].questions[7].text,
      content: pages_data_mod5[2].questions[7].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[2].questions[7].media_questions[1].audio_file,
    },
  
  ],
};

const mod5_lesson4_content = {
  initialData: {
    title: pages_data_mod5[3].questions[0].text,
    content: pages_data_mod5[3].questions[0].responses[0].text,
    sub_content: null,
    audio: pages_data_mod5[3].questions[0].media_questions[0].audio_file,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod5[3].questions[0].text,
      content: pages_data_mod5[3].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[3].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod5[3].questions[1].text,
      content: pages_data_mod5[3].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[3].questions[1].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod5[3].questions[2].text,
      content: pages_data_mod5[3].questions[2].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[3].questions[2].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod5[3].questions[3].text,
      content: pages_data_mod5[3].questions[3].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[3].questions[3].media_questions[1].audio_file,
    },
  ],
};

const mod5_lesson5_content = {
  initialData: {
    title: pages_data_mod5[4].questions[0].text,
    content: pages_data_mod5[4].questions[0].responses[0].text,
    sub_content: null,
    audio: pages_data_mod5[4].questions[0].media_questions[1].audio_file,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod5[4].questions[0].text,
      content: pages_data_mod5[4].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[4].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod5[4].questions[1].text,
      content: pages_data_mod5[4].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[4].questions[1].media_questions[0].audio_file,
    },
    {
      title: pages_data_mod5[4].questions[2].text,
      content: pages_data_mod5[4].questions[2].responses[0].text,
      sub_content: null,
      audio: pages_data_mod5[4].questions[2].media_questions[0].audio_file,
    },
  ],
};

const mod5_lesson6_content = {
  initialData: {
    title: null,
    content: null,
    sub_content: null,
  },
};

// ==================================================

// ==================================================

// ============= donnees du module 5  ===============

const mod6_lesson1_content = {
  initialData: {
    title: pages_data_mod6[0].questions[0].text,
    content: pages_data_mod6[0].questions[0].responses[0].text,
    sub_content: null,
    audio: pages_data_mod6[0].questions[0].media_questions[1].audio_file,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod6[0].questions[0].text,
      content: pages_data_mod6[0].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod6[0].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod6[0].questions[1].text,
      content: pages_data_mod6[0].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod6[0].questions[1].media_questions[1].audio_file,
    },
    
  ],
};

const mod6_lesson2_content = {
  initialData: {
    title: null,
    content: pages_data_mod6[1].text,
    sub_content: null,
    audio: pages_data_mod6[1].medias[1].audio_file,
  },
  onPreciseClickData: { title: null, content: null, sub_content: null },
};

const mod6_lesson3_content = {
  initialData: {
    title: pages_data_mod6[2].questions[0].text,
    content: pages_data_mod6[2].questions[0].responses[0].text,
    sub_content: null,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod6[2].questions[0].text,
      content: pages_data_mod6[2].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod6[2].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod6[2].questions[1].text,
      content: pages_data_mod6[2].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod6[2].questions[1].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod6[2].questions[2].text,
      content: pages_data_mod6[2].questions[2].responses[0].text,
      sub_content: null,
      audio: pages_data_mod6[2].questions[2].media_questions[1].audio_file,
    },
  ],
};

const mod6_lesson4_content = {

  initialData: {
    title: null,
    content: pages_data_mod6[3].text,
    sub_content: null,
    audio: pages_data_mod6[3].medias[1].audio_file,
  },
  onPreciseClickData: [
    
    {
      title: null,
      content: pages_data_mod6[3].questions[0].responses[0].text,
      sub_content: null,
    },
    {
      title: null,
      content:  pages_data_mod6[3].questions[0].responses[1].text,
      sub_content: null,
    },
    {
      title: null,
      content: pages_data_mod6[3].questions[0].responses[2].text,
      sub_content: null,
    },
    {
      title: null,
      content:  pages_data_mod6[3].questions[0].responses[3].text,
      sub_content: null,
    },
    {
      title: null,
      content:  pages_data_mod6[3].questions[0].responses[4].text,
      sub_content: null,
    },
    {
      title: null,
      content:  pages_data_mod6[3].questions[0].responses[5].text,
      sub_content: null,
    },
    {
      title: null,
      content:  pages_data_mod6[3].questions[0].responses[6].text,
      sub_content: null,
    },
  ],
};

const mod6_lesson5_content = {
  initialData: {
    title: null,
    content: pages_data_mod6[4].text,
    sub_content: null,
  },
  onPreciseClickData: [
    {
      title: pages_data_mod6[4].questions[0].text,
      content: pages_data_mod6[4].questions[0].responses[0].text,
      sub_content: null,
      audio: pages_data_mod6[4].questions[0].media_questions[1].audio_file,
    },
    {
      title: pages_data_mod6[4].questions[1].text,
      content: pages_data_mod6[4].questions[1].responses[0].text,
      sub_content: null,
      audio: pages_data_mod6[4].questions[1].media_questions[1].audio_file,
    },
    
  ],
};

const mod6_lesson6_content = {
  initialData: {
    title: null,
    content: null,
    sub_content: null,
  },
};

// audio files

const audios_Exercice1_mod6 = pages_data_mod6[5].questions[0].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const audios_Exercice2_mod6 = pages_data_mod6[5].questions[1].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const audios_Exercice3_mod6 = pages_data_mod6[5].questions[2].responses.map(
  (consigne) => consigne.media_responses[0].audio_file
);

const mod6_audios = {
  exercice1: audios_Exercice1_mod6,
  exercice2: audios_Exercice2_mod6,
  exercice3: audios_Exercice3_mod6
};

// ==================================================

function stringFormer(string) {
  if (string == null) {
    return "";
  } else {
    const txt = string.replace(/<[^>]+>/g, "");
    return txt;
  }
}

const module3_content = [
  mod3_lesson1_content,
  mod3_lesson2_content,
  mod3_lesson3_content,
  mod3_lesson4_content,
  mod3_lesson5_content,
  mod3_lesson6_content,
];

const module4_content = [
  mod4_lesson1_content,
  mod4_lesson2_content,
  mod4_lesson3_content,
  mod4_lesson4_content,
  mod4_lesson5_content,
  mod4_lesson6_content,
];

const module5_content = [
  mod5_lesson1_content,
  mod5_lesson2_content,
  mod5_lesson3_content,
  mod5_lesson4_content,
  mod5_lesson5_content,
  mod5_lesson6_content,
];

const module6_content = [
  mod6_lesson1_content,
  mod6_lesson2_content,
  mod6_lesson3_content,
  mod6_lesson4_content,
  mod6_lesson5_content,
  mod6_lesson6_content,
];

export {
  pages_data,
  module1_content,
  module2_content,
  module3_content,
  module4_content,
  module5_content,
  module6_content,
  mod1_exercices_content,
  pages_data_mod2,
  pages_data_mod3,
  pages_data_mod4,
  pages_data_mod5,
  pages_data_mod6,
  Mod_Titles,
  mod1_audios,
  mod1_audios_lessons,
  mod1_consignes_lessons,
  mod2_audios_lessons,
  mod2_consignes_lessons,
  mod3_consignes,
  mod4_consignes,
  mod6_consignes,
  mod1_exercice5,
  mod3_audios,
  mod4_audios,
  mod6_audios,
  consignes_Mod2,
  consignes_Mod3,
  consignes_Mod4,
  consignes_Mod5,
  consignes_Mod6,
  audio_consignes_Mod1,
  audio_consignes_Mod2,
  audio_consignes_Mod3,
  audio_consignes_Mod4,
  audio_consignes_Mod5,
  audio_consignes_Mod6,
  clicks,
  validateText
};
