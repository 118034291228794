// -------------- CARDS FROM HOME ------------------- //

import card1 from "../assets/ordinateur_portable.png";
import card2 from "../assets/tablette_smartphone.png";
import card3 from "../assets/bureau.png";
import card4 from "../assets/accessoires.png";
import card5 from "../assets/internet.png";
import card6 from "../assets/traitement_texte.png";

// --------------- GENERAL ------------------- //
import settingsImg from "../assets/settings.png";
import backHomeImg from "../assets/home.png";
import scheduleImg from "../assets/schedule.png";
import closeIcon from "../assets/closeIcon.png";
import restartIcon from "../assets/IconRestart.png";
import circleIcon from "../assets/cercle.png";
import logoAfricatik_loadingPage from "../assets/logo_chargement.png";
import logoAfricatik_mainPage from "../assets/logo_raqmi.png";

// --------------- IMAGES MODULE 1 ------------------- //

import mod1_step1_blue from "../assets/mod1/icone_zoom_ordinateur_fixe(1).png";
import mod1_step2_blue from "../assets/mod1/zoom_ordinateur_portable.png";
import mod1_step3_blue from "../assets/mod1/zoom_unite_centrale.png";
import mod1_step4_blue from "../assets/mod1/zoom_souris.png";
import mod1_step5_blue from "../assets/mod1/zoom_clavier.png";
import mod1_exercice_blue from "../assets/mod1/zoom_exercice.png";

import mod1_step1_orange from "../assets/mod1/zoom_ordinateur_fixe.png";
import mod1_step2_orange from "../assets/mod1/zoom_ordinateur_portable(1).png";
import mod1_step3_orange from "../assets/mod1/icone_unite_centrale.png";
import mod1_step4_orange from "../assets/mod1/icone_souris.png";
import mod1_step5_orange from "../assets/mod1/icone_clavier.png";
import mod1_exercice_orange from "../assets/mod1/icone_exercice.png";

import mod2_step1_blue from "../assets/mod2/1_ICONE_ACTIVITE_2(2).png";
import mod2_step2_blue from "../assets/mod2/2_ICONE_ACTIVITE_2.png";
import mod2_step3_blue from "../assets/mod2/3_ICONE_ACTIVITE_2.png";
import mod2_step4_blue from "../assets/mod2/4_ICONE_ACTIVITE_2.png";
import mod2_step5_blue from "../assets/mod2/5_ICONE_ACTIVITE_2.png";
import mod2_exercice_blue from "../assets/mod2/6_ICONE_ACTIVITE_2.png";

import mod2_step1_orange from "../assets/mod2/1_ICONE_ACTIVITE_2(1).png";
import mod2_step2_orange from "../assets/mod2/2_ICONE_ACTIVITE_2(1).png";
import mod2_step3_orange from "../assets/mod2/3_ICONE_ACTIVITE_2(1).png";
import mod2_step4_orange from "../assets/mod2/4_ICONE_ACTIVITE_2(1).png";
import mod2_step5_orange from "../assets/mod2/5_ICONE_ACTIVITE_2(1).png";
import mod2_exercice_orange from "../assets/mod2/6_ICONE_ACTIVITE_2(1).png";

import mod3_step1_blue from "../assets/mod3/1_ICONE_ACTIVITE_3.png";
import mod3_step2_blue from "../assets/mod3/2_ICONE_ACTIVITE_3.png";
import mod3_step3_blue from "../assets/mod3/3_ICONE_ACTIVITE_3.png";
import mod3_step4_blue from "../assets/mod3/4_ICONE_ACTIVITE_3.png";
import mod3_step5_blue from "../assets/mod3/5_ICONE_ACTIVITE_3.png";
import mod3_exercice_blue from "../assets/mod3/6_ICONE_ACTIVITE_3.png";

import mod3_step1_orange from "../assets/mod3/1_ICONE_ACTIVITE_3(1).png";
import mod3_step2_orange from "../assets/mod3/2_ICONE_ACTIVITE_3(1).png";
import mod3_step3_orange from "../assets/mod3/3_ICONE_ACTIVITE_3(1).png";
import mod3_step4_orange from "../assets/mod3/4_ICONE_ACTIVITE_3(1).png";
import mod3_step5_orange from "../assets/mod3/5_ICONE_ACTIVITE_3(1).png";
import mod3_exercice_orange from "../assets/mod3/6_ICONE_ACTIVITE_3(1).png";

import mod4_step1_blue from "../assets/mod4/1_ICONE_ACTIVITE_4(1).png";
import mod4_step2_blue from "../assets/mod4/2_ICONE_ACTIVITE_4(1).png";
import mod4_step3_blue from "../assets/mod4/3_ICONE_ACTIVITE_4(1).png";
import mod4_step4_blue from "../assets/mod4/4_ICONE_ACTIVITE_4(1).png";
import mod4_step5_blue from "../assets/mod4/5_ICONE_ACTIVITE_4(1).png";
import mod4_exercice_blue from "../assets/mod4/6_ICONE_ACTIVITE_4(1).png";

import mod4_step1_orange from "../assets/mod4/1_ICONE_ACTIVITE_4.png";
import mod4_step2_orange from "../assets/mod4/2_ICONE_ACTIVITE_4.png";
import mod4_step3_orange from "../assets/mod4/3_ICONE_ACTIVITE_4.png";
import mod4_step4_orange from "../assets/mod4/4_ICONE_ACTIVITE_4.png";
import mod4_step5_orange from "../assets/mod4/5_ICONE_ACTIVITE_4.png";
import mod4_exercice_orange from "../assets/mod4/6_ICONE_ACTIVITE_4.png";

import mod5_step1_blue from "../assets/mod5/1_ICONE_ACTIVITE_5.png";
import mod5_step2_blue from "../assets/mod5/2_ICONE_ACTIVITE_5.png";
import mod5_step3_blue from "../assets/mod5/3_ICONE_ACTIVITE_5.png";
import mod5_step4_blue from "../assets/mod5/4_ICONE_ACTIVITE_5.png";
import mod5_step5_blue from "../assets/mod5/5_ICONE_ACTIVITE_5.png";
import mod5_exercice_blue from "../assets/mod5/6_ICONE_ACTIVITE_5.png";

import mod5_step1_orange from "../assets/mod5/1_ICONE_ACTIVITE_5(1).png";
import mod5_step2_orange from "../assets/mod5/2_ICONE_ACTIVITE_5(1).png";
import mod5_step3_orange from "../assets/mod5/3_ICONE_ACTIVITE_5(1).png";
import mod5_step4_orange from "../assets/mod5/4_ICONE_ACTIVITE_5(1).png";
import mod5_step5_orange from "../assets/mod5/5_ICONE_ACTIVITE_5(1).png";
import mod5_exercice_orange from "../assets/mod5/6_ICONE_ACTIVITE_5(1).png";

import mod6_step1_blue from "../assets/mod6/1_ICONE_ACTIVITE_6.png";
import mod6_step2_blue from "../assets/mod6/2_ICONE_ACTIVITE_6.png";
import mod6_step3_blue from "../assets/mod6/3_ICONE_ACTIVITE_6.png";
import mod6_step4_blue from "../assets/mod6/4_ICONE_ACTIVITE_6.png";
import mod6_step5_blue from "../assets/mod6/5_ICONE_ACTIVITE_6.png";
import mod6_exercice_blue from "../assets/mod6/6_ICONE_ACTIVITE_6.png";

import mod6_step1_orange from "../assets/mod6/1_ICONE_ACTIVITE_6(1).png";
import mod6_step2_orange from "../assets/mod6/2_ICONE_ACTIVITE_6(1).png";
import mod6_step3_orange from "../assets/mod6/3_ICONE_ACTIVITE_6(1).png";
import mod6_step4_orange from "../assets/mod6/4_ICONE_ACTIVITE_6(1).png";
import mod6_step5_orange from "../assets/mod6/5_ICONE_ACTIVITE_6(1).png";
import mod6_exercice_orange from "../assets/mod6/6_ICONE_ACTIVITE_6(1).png";

import mod1_lesson1 from "../assets/mod1/MOD1_ordinateur_fixe_raqmi.png";
import mod1_lesson2 from "../assets/mod1/MOD1_ordinateur_portable_raqmi.png";
import mod1_lesson3 from "../assets/mod1/MOD1_unité_centrale_raqmi.png";
import mod1_lesson4_image1 from "../assets/mod1/MOD1_souris_raqmi.png";
import mod1_lesson4_image2 from "../assets/mod1/MOD1_positionclickgauchesouris_raqmi_01.png";
import mod1_lesson4_image3 from "../assets/mod1/MOD1_positionclickdroitsouris_raqmi_01_01.png";
import mod1_lesson4_image4 from "../assets/mod1/MOD1_doubleclicsouris_raqmi.gif";
import mod1_lesson4_image5 from "../assets/mod1/MOD1_molettesouris_raqmi.gif";
import mod1_lesson5 from "../assets/mod1/MOD1_clavier_raqmi.png";

// // --------------- IMAGES MODULE 2 ------------------- //

// import mod2_lesson1_image1 from "../assets/mod2/MOD2_smartphone_raqmi.png";
// import mod2_lesson1_image2 from "../assets/mod2/MOD2_tablette_raqmi.png";

// import mod2_lesson2_image1 from "../assets/mod2/MOD2_allumer_eteindre_raqmi.png";
// import mod2_lesson2_image2 from "../assets/mod2/MOD2_vérouiller_dévérouiller_raqmi.png";

// import mod2_lesson3_image1 from "../assets/mod2/MOD2_organiser_ecran_accueil_raqmi.png";
// import mod2_lesson3_image2 from "../assets/mod2/MOD2_changer_ecran_raqmi.png";
// // import mod2_lesson3_image3 from "../assets/mod2/MOD2_bouton_navigation_raqmi.png";
// import mod2_lesson3_image3 from "../assets/mod2/MOD2_organiser_ecran_accueil_raqmi.png";
// import mod2_lesson3_image4 from "../assets/mod2/MOD2_organiser_ecran_accueil_raqmi.png";

// import mod2_lesson4_image1 from "../assets/mod2/MOD2_appuyer_sur_application_raqmi.png";
// import mod2_lesson4_image2 from "../assets/mod2/MOD2_smartphone_raqmi.png";
// import mod2_lesson4_image3 from "../assets/mod2/MOD2_taper_le_nom_de_app_raqmi.png";
// import mod2_lesson4_image4 from "../assets/mod2/MOD2_ouvrir_application_raqmi.png";
// import mod2_lesson4_image5 from "../assets/mod2/MOD2_a_propos_de_application_raqmi.png";
// import mod2_lesson4_image6 from "../assets/mod2/MOD2_télécharger_une_appli_raqmi.png";

// import mod2_lesson5_image1 from "../assets/mod2/MOD2_faireunecapture_raqmi.png";

// // --------------- IMAGES MODULE 3 ------------------- //

// import mod3_lesson1_image1 from "../assets/mod3/MOD3_selectionner_texte.gif";
// import mod3_lesson1_image2 from "../assets/mod3/MOD3_séléctionner_une_photo.png";
// import mod3_lesson1_image3 from "../assets/mod3/MOD3_séléctionner_plusieurs_photo.png";
// import mod3_lesson1_image4 from "../assets/mod3/MOD3_copier_une_image.png";
// import mod3_lesson1_image5 from "../assets/mod3/MOD3_coller_une_photo_ou_fichier.png";

// import mod3_lesson2_image1 from "../assets/mod3/MOD3_Aller_à_emplacement_cible.png";
// import mod3_lesson2_image2 from "../assets/mod3/MOD3_créer_un_dossier1.png";
// // import mod3_lesson2_image3 from "../assets/mod3/MOD3_créer_un_dossier2.png";
// import mod3_lesson2_image3 from "../assets/mod3/MOD3_nommer_un_dossier.png";
// import mod3_lesson2_image4 from "../assets/mod3/MOD3_supprimer_un_dossier1.png";
// // import mod3_lesson2_image6 from "../assets/mod3/MOD3_supprimer_un_dossier_2.png";

// import mod3_lesson3 from "../assets/mod3/MOD3_accéder_aux_programmes.png";

// import mod3_lesson4_image1 from "../assets/mod3/MOD3_Accéder_rapidement_à_diverses_fonctionnalités.png";
// import mod3_lesson4_image2 from "../assets/mod3/MOD3_Eteindre_ordinateur_raqmi.png";
// // import mod3_lesson4_image3 from "../assets/mod3/MOD3_Epingler_une_application_sur_barre_taches_2_(2).png";
// import mod3_lesson4_image3 from "../assets/mod3/MOD3_Epingler_une_application_sur_barretaches_1.png";

// import mod3_lesson5 from "../assets/mod3/MOD3_configurer_le_wifi.png";
import LANG from "../properties.json";

if (sessionStorage.getItem("Lang") === null) {
  sessionStorage.setItem("Lang", 0);
}

var LangId = sessionStorage.getItem('Lang');

//-----------Mod3-------------//
function Img_mod3_page1(IdLang, quest) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[2].pages[0]
      .questions[quest].media_questions[0].img_file;
  });
  return data.toString();
}

function Img_mod3_resp(IdLang, quest) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[2].pages[0]
      .questions[0].responses[quest].media_responses[0].img_file;
  });
  return data.toString();
}
const mod3_lesson1 = {
  imgs: [
    Img_mod3_page1(LangId, 0),
    Img_mod3_page1(LangId, 1),
    Img_mod3_page1(LangId, 2),
  ],
};

function Img_mod3_page2(IdLang, quest) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[2].pages[1]
      .questions[quest].media_questions[0].img_file;
  });
  return data.toString();
}

function Img_mod3_page3(IdLang) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[2].pages[2]
      .media_pages[0].img_file;
  });
  return data.toString();
}

const mod3_lesson2 = {
  imgs: [
    Img_mod3_page2(LangId, 0),
    Img_mod3_page2(LangId, 1),
    Img_mod3_page2(LangId, 2),
    Img_mod3_page2(LangId, 3),
  ],
};
function Img_mod3_page4(IdLang, quest) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[2].pages[3]
      .questions[quest].media_questions[0].img_file;
  });
  return data.toString();
}

function Img_mod3_page5(IdLang) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[2].pages[4]
      .media_pages[1].img_file;
  });
  return data.toString();
}

const mod3_lesson4 = {
  imgs: [Img_mod3_page4(LangId, 0), Img_mod3_page4(LangId, 1), Img_mod3_page4(LangId, 2)],
};

function Lesson_img_mod2_P1(IdLang, quest) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[1].pages[0]
      .media_pages[0].img_file;
  });
  return data.toString();
}
//
function Lesson_img_mod2(IdLang, quest) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[1].pages[1]
      .questions[quest].media_questions[1].img_file;
  });
  return data.toString();
}
//
function Lesson_img2_mod2(IdLang, quest) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[1].pages[2]
      .questions[quest].media_questions[0].img_file;
  });
  return data.toString();
}
function Lesson_img3_mod2(IdLang, quest) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[1].pages[3]
      .questions[quest].media_questions[0].img_file;
  });
  return data.toString();
}
function Lesson_img4_mod2(IdLang, quest) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[1].pages[4]
      .questions[quest].media_questions[0].img_file;
  });
  return data.toString();
}
function Lesson_img5_mod2(IdLang) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[2].pages[4]
      .media_pages[0].img_file;
  });
  return data.toString();
}

const mod2_lesson1 = {
  imgs: [Lesson_img_mod2_P1(LangId, 0)],
};

const mod2_lesson2 = {
  imgs: [
    Lesson_img_mod2(LangId, 0),
    Lesson_img_mod2(LangId, 0),
    Lesson_img_mod2(LangId, 0),
    Lesson_img_mod2(LangId, 3),
  ],
};

const mod2_lesson3 = {
  imgs: [
    Lesson_img2_mod2(LangId, 0),
    Lesson_img2_mod2(LangId, 1),
    Lesson_img2_mod2(LangId, 2),
    Lesson_img2_mod2(LangId, 3),
  ],
};
const mod2_lesson4 = {
  imgs: [
    Lesson_img3_mod2(LangId, 0),
    Lesson_img3_mod2(LangId, 1),
    Lesson_img3_mod2(LangId, 2),
    Lesson_img3_mod2(LangId, 3),
    Lesson_img3_mod2(LangId, 4),
  ],
};

const mod2_lesson5 = {
  imgs: [Lesson_img4_mod2(LangId, 0), Lesson_img4_mod2(LangId, 1)],
};

const mod1_lesson4 = [
  mod1_lesson4_image1,
  mod1_lesson4_image2,
  mod1_lesson4_image3,
  mod1_lesson4_image4,
  mod1_lesson4_image5,
];
function Lesson_img(IdLang, page) {
  const data = LANG.map((info) => {
    return info.languages[IdLang].domains[0].subdomains[0].sequences[0].pages[
      page
    ].media_pages[0].img_file;
  });

  return data.toString();
}
//module4
function img_page_mod4(IdLang, page) {
  const data = LANG.map((info) => {
  return info.languages[IdLang].domains[0].subdomains[0].sequences[3].pages[page]
      .media_pages[0].img_file;
  });
  return data;
}
function img_page_mod4_copy(IdLang, page,media) {
  const data = LANG.map((info) => {
  return info.languages[IdLang].domains[0].subdomains[0].sequences[3].pages[page]
      .media_pages[media].img_file;
  });
  return data;
}
function img_page_mod4_quest(IdLang, page, quest) {
  const data = LANG.map((info) => {
   return info.languages[IdLang].domains[0].subdomains[0].sequences[3].pages[page]
      .questions[quest].media_questions[0].img_file;
  });
  return data;
}
const mod4_lesson1 ={ 
  imgs:[
    img_page_mod4_quest(LangId,0,0),
    img_page_mod4_quest(LangId,0,1),
]}
const mod4_lesson2 ={imgs: [
  img_page_mod4_quest(LangId,1,0),
  img_page_mod4_quest(LangId,1,1),
]}
const mod4_lesson3 ={imgs: [
  img_page_mod4_copy(LangId,2,0),
  img_page_mod4_copy(LangId,2,1),
  img_page_mod4_copy(LangId,2,2),
  img_page_mod4_copy(LangId,2,3),
]}
const mod4_lesson4 ={ imgs:[
  img_page_mod4(LangId, 3),
]}
const mod4_lesson5 ={ imgs:[
  img_page_mod4_copy(LangId,4,0),
  img_page_mod4_copy(LangId,4,1),
  img_page_mod4_copy(LangId,4,2),
  img_page_mod4_copy(LangId,4,3),
  
]}

//Mod5
function img_mod5_lesson1(IdLang, page, quest) {
  const data = LANG.map((info) => {
   return info.languages[IdLang].domains[0].subdomains[0].sequences[4].pages[page]
      .questions[quest].media_questions[0].img_file;
  });
  return data;
}

function img_mod5_lesson_page(IdLang, page) {
  const data = LANG.map((info) => {
   return info.languages[IdLang].domains[0].subdomains[0].sequences[4].pages[page].media_pages[0].img_file
  });
  return data;
}

const mod5_lesson1 ={ 
  imgs:[
    img_mod5_lesson_page(0,0),
    img_mod5_lesson_page(0,0),
    img_mod5_lesson_page(0,0),
    img_mod5_lesson_page(0,0),
    img_mod5_lesson_page(0,0),
    img_mod5_lesson_page(0,0),
]}
const mod5_lesson2 ={ 
  imgs:[
    img_mod5_lesson_page(0,1),
    img_mod5_lesson_page(0,1),
    img_mod5_lesson_page(0,1),
]}
const mod5_lesson3 ={ 
  imgs:[
    img_mod5_lesson1(0,2,0),
    img_mod5_lesson1(0,2,1),
    img_mod5_lesson1(0,2,2),
    img_mod5_lesson1(0,2,3),
    img_mod5_lesson1(0,2,4),
    img_mod5_lesson1(0,2,5),
    img_mod5_lesson1(0,2,6),
    img_mod5_lesson1(0,2,7),
]}
const mod5_lesson4 ={ 
  imgs:[
    img_mod5_lesson1(LangId,3,0),
    img_mod5_lesson1(LangId,3,1),
    img_mod5_lesson1(LangId,3,2),
    img_mod5_lesson1(LangId,3,3)
]}
const mod5_lesson5 ={ 
  imgs:[
    img_mod5_lesson1(LangId,4,0),
    img_mod5_lesson1(LangId,4,0),
    img_mod5_lesson1(LangId,4,0),
    
]}
//Module 6
function img_mod6_lesson(IdLang, page, quest) {
  const data = LANG.map((info) => {
   return info.languages[IdLang].domains[0].subdomains[0].sequences[5].pages[page]
      .questions[quest].media_questions[0].img_file;
  });
  return data;
}
function img_mod6_lesson_page(IdLang, page) {
  const data = LANG.map((info) => {
   return info.languages[IdLang].domains[0].subdomains[0].sequences[5].pages[page].media_pages[0].img_file
  });
  return data;
}
const mod6_lesson1 ={ 
  imgs:[
    img_mod6_lesson(LangId,0,0),
    img_mod6_lesson(LangId,0,1),
    
]}
const mod6_lesson2 ={ 
  imgs:[
    img_mod6_lesson_page(0,1),  
]}
const mod6_lesson3 ={ 
  imgs:[
    [Img_mod3_page1(LangId, 0)], ///zanazlenlkaznelka
    Img_mod3_page1(LangId, 1),
    Img_mod3_page1(LangId, 2),
]}
const mod6_lesson4 ={ 
  imgs:[img_mod6_lesson_page(0,3),  
]}
const mod6_lesson5 ={ 
  imgs:[
    img_mod6_lesson(0,4,0),
    img_mod6_lesson(0,4,1),
]}

//
const images_store = {
  general: {
    settingsImage: settingsImg,
    backHomeImage: backHomeImg,
    scheduleImage: scheduleImg,
    closeModalIcon: closeIcon,
    restartIcon: restartIcon,
    circleImage: circleIcon,
    logo_loading: logoAfricatik_loadingPage,
    logo_main: logoAfricatik_mainPage,
  },
  home: [card1, card2, card3, card4, card5, card6],
  mod1: {
    steps: {
      step1: mod1_step1_blue,
      step2: mod1_step2_blue,
      step3: mod1_step3_blue,
      step4: mod1_step4_blue,
      step5: mod1_step5_blue,
      step6: mod1_exercice_blue,
    },
    steps_onClick: {
      step1: mod1_step1_orange,
      step2: mod1_step2_orange,
      step3: mod1_step3_orange,
      step4: mod1_step4_orange,
      step5: mod1_step5_orange,
      step6: mod1_exercice_orange,
    },
    lessons: [
      mod1_lesson1,
      mod1_lesson2,
      mod1_lesson3,
      mod1_lesson4,
      mod1_lesson5,
    ],
  },
  mod2: {
    steps: {
      step1: mod2_step1_blue,
      step2: mod2_step2_blue,
      step3: mod2_step3_blue,
      step4: mod2_step4_blue,
      step5: mod2_step5_blue,
      step6: mod2_exercice_blue,
    },
    steps_onClick: {
      step1: mod2_step1_orange,
      step2: mod2_step2_orange,
      step3: mod2_step3_orange,
      step4: mod2_step4_orange,
      step5: mod2_step5_orange,
      step6: mod2_exercice_orange,
    },
    lessons: [
      mod2_lesson1,
      mod2_lesson2,
      mod2_lesson3,
      mod2_lesson4,
      mod2_lesson5,
    ],
  },
  mod3: {
    steps: {
      step1: mod3_step1_blue,
      step2: mod3_step2_blue,
      step3: mod3_step3_blue,
      step4: mod3_step4_blue,
      step5: mod3_step5_blue,
      step6: mod3_exercice_blue,
    },
    steps_onClick: {
      step1: mod3_step1_orange,
      step2: mod3_step2_orange,
      step3: mod3_step3_orange,
      step4: mod3_step4_orange,
      step5: mod3_step5_orange,
      step6: mod3_exercice_orange,
    },
    lessons: [
      mod3_lesson1,
      mod3_lesson2,
      Img_mod3_page3(LangId),
      mod3_lesson4,
      Img_mod3_page5(LangId),
    ],
  },
  mod4: {
    steps: {
      step1: mod4_step1_blue,
      step2: mod4_step2_blue,
      step3: mod4_step3_blue,
      step4: mod4_step4_blue,
      step5: mod4_step5_blue,
      step6: mod4_exercice_blue,
    },
    steps_onClick: {
      step1: mod4_step1_orange,
      step2: mod4_step2_orange,
      step3: mod4_step3_orange,
      step4: mod4_step4_orange,
      step5: mod4_step5_orange,
      step6: mod4_exercice_orange,
    },
    lessons: [
      mod4_lesson1,
      mod4_lesson2,
      mod4_lesson3,
      img_page_mod4(LangId,3),
      mod4_lesson5,
      
    ],
  },
  mod5: {
    steps: {
      step1: mod5_step1_blue,
      step2: mod5_step2_blue,
      step3: mod5_step3_blue,
      step4: mod5_step4_blue,
      step5: mod5_step5_blue,
      step6: mod5_exercice_blue,
    },
    steps_onClick: {
      step1: mod5_step1_orange,
      step2: mod5_step2_orange,
      step3: mod5_step3_orange,
      step4: mod5_step4_orange,
      step5: mod5_step5_orange,
      step6: mod5_exercice_orange,
    },
    lessons: [
      mod5_lesson1,
      mod5_lesson2,mod5_lesson3,mod5_lesson4,mod5_lesson5
     
    ],
  },
  mod6: {
    steps: {
      step1: mod6_step1_blue,
      step2: mod6_step2_blue,
      step3: mod6_step3_blue,
      step4: mod6_step4_blue,
      step5: mod6_step5_blue,
      step6: mod6_exercice_blue,
    },
    steps_onClick: {
      step1: mod6_step1_orange,
      step2: mod6_step2_orange,
      step3: mod6_step3_orange,
      step4: mod6_step4_orange,
      step5: mod6_step5_orange,
      step6: mod6_exercice_orange,
    },
    lessons: [
      mod6_lesson1,
      img_mod6_lesson_page(LangId,1), 
      mod6_lesson3,
      img_mod6_lesson_page(LangId,3),  
      mod6_lesson5,
    ],
  },
};
export default images_store;
