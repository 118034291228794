import React from "react";
import images_store from "../utils/Images";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Howl, Howler } from "howler";

function VerticaleImage(props) {
  const applyDisplayNone = {
    display: "none",
  };

  const imageWidth = {
    width: "50vw",
    marginLeft: "-50%",
  };

  const goToPrevious = () => {
    const isFirstSlide = props.currentIndex === 0;
    const newIndex = isFirstSlide
      ? props.lessonArrayLenght - 1
      : props.currentIndex - 1;
    props.setCurrentIndex(newIndex);
    Howler.stop();
  };

  const onTurnof = () => {
    props.setCurrentIndex(1);
  };

  const onStandBy = () => {
    props.setCurrentIndex(1);
  };

  const goToNext = () => {
    const isLastSlide = props.currentIndex === props.lessonArrayLenght - 1;
    const newIndex = isLastSlide ? 0 : props.currentIndex + 1;
    props.setCurrentIndex(newIndex);
    Howler.stop();
  };

  return (
    <div className="vertical_pattern">
      <div
        style={props.is_several && props.is_notFirst ? null : applyDisplayNone}
        onClick={goToPrevious}
        className="accollade_gauche"
      >
        ❰
      </div>

      <div
        className="Vertical_lessonImageConteiner"
      >
        {/* les applications */}
        <div
          style={
            props.currentIndex === 0 && props.lessonId_into === 4
              ? null
              : applyDisplayNone
          }
          className="circleRedImg playstor_choice"
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>

        <div
          style={
            props.currentIndex === 0 && props.lessonId_into === 5
              ? null
              : applyDisplayNone
          }
          className="circleRedImg capture1"
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>

        <div
          style={
            props.currentIndex === 1 && props.lessonId_into === 5
              ? null
              : applyDisplayNone
          }
          className="circleRedImg capture2"
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>
        <div
          style={
            props.currentIndex === 1 && props.lessonId_into === 5
              ? null
              : applyDisplayNone
          }
          className="circleRedImg capture3"
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>

        <div
          style={
            props.currentIndex === 2 && props.lessonId_into === 2
              ? null
              : applyDisplayNone
          }
          className="circleRedImg turnoff_phone_choice"
          // onClick={onTurnof}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>
        <div
          style={
            props.currentIndex === 2 && props.lessonId_into === 2
              ? null
              : applyDisplayNone
          }
          className="circleRedImg turnoff_phone_choice1"
          //onClick={onTurnof}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>

        <div
          style={
            props.currentIndex === 1 && props.lessonId_into === 2
              ? null
              : applyDisplayNone
          }
          className="circleRedImg restart_phone_choice"
          onClick={onTurnof}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>

        <div
          style={
            props.currentIndex === 0 && props.lessonId_into === 2
              ? null
              : applyDisplayNone
          }
          className="circleRedImg standby_phone_choice"
          //onClick={onStandBy}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>

        <div
          style={props.onExo4screen_capture ? null : applyDisplayNone}
          className="circleRedImg_appsIcn scrennCap_zone1"
          onClick={props.falseScreenCap_onClick}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>
        <div
          style={props.onExo4screen_capture ? null : applyDisplayNone}
          className="circleRedImg_appsIcn scrennCap_zone2"
          onClick={props.goodScreenCap_onClick}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>
        <div
          style={props.onExo4screen_capture ? null : applyDisplayNone}
          className="circleRedImg_appsIcn scrennCap_zone3"
          onClick={props.falseScreenCap_onClick}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>

        <div
          style={props.isExo1openphone ? null : applyDisplayNone}
          className="circleRedImg_appsIcn openPhone"
          onClick={props.phoneOpen_onClick}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>

        <div
          style={props.isExo1openphone ? null : applyDisplayNone}
          className="circleRedImg_appsIcn airplainePhone"
          onClick={props.airplaine_onClick}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>

        <div
          style={props.isExo1openphone ? null : applyDisplayNone}
          className="circleRedImg_appsIcn shutdownPhone"
          onClick={props.shutdown_onClick}
        >
          <img
            src={images_store.general.circleImage}
            alt=""
            style={{ width: "3vw" }}
          />
        </div>

        <div className="col" style={{}}>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_into === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg_appsIcn phoneApp_choice"
            onClick={props.phoneApp_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>

          <div
            style={
              props.currentIndex === 2 && props.lessonId_into === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg_appsIcn msg_choice"
            onClick={props.msg_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_into === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg_appsIcn camera_choice"
            onClick={props.camera_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_into === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg_appsIcn galerie_choice"
            onClick={props.galerie_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_into === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg_appsIcn horloge_choice"
            onClick={props.horloge_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_into === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg_appsIcn contact_choice"
            onClick={props.contact_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_into === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg_appsIcn settings_choice"
            onClick={props.setting_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_into === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg_appsIcn calandar_choice"
            onClick={props.calandar_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>

          <div
            style={
              props.currentIndex === 2 && props.lessonId_into === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg_appsIcn clc_choice"
            onClick={props.clc_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          {/* la bare de navigation */}
          <div
            style={
              props.currentIndex === 2 && props.lessonId_into === 3
                ? null
                : applyDisplayNone
            }
            onClick={props.nav_home_onClick}
          >
            <img
              className="circleRedImg_appsIcn nav_home"
              src={images_store.general.circleImage}
              alt=""
              style={{ marginTop: "63%", marginLeft: "22%" }}
            />
          </div>
        </div>
        <div className="col" style={{}}>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_intos === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg phoneApp_choice2"
            onClick={props.phoneApp_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>

          <div
            style={
              props.currentIndex === 2 && props.lessonId_intos === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg msg_choice2"
            onClick={props.msg_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_intos === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg camera_choice2"
            onClick={props.camera_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_intos === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg galerie_choice2"
            onClick={props.galerie_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_intos === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg horloge_choice2"
            onClick={props.horloge_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_intos === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg contact_choice2"
            onClick={props.contact_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_intos === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg settings_choice2"
            onClick={props.setting_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 2 && props.lessonId_intos === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg calandar_choice2"
            onClick={props.calandar_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>

          <div
            style={
              props.currentIndex === 2 && props.lessonId_intos === 3
                ? null
                : applyDisplayNone
            }
            className="circleRedImg clc_choice2"
            onClick={props.clc_onClick}
          >
            <img
              src={images_store.general.circleImage}
              alt=""
              style={{ width: "3vw" }}
            />
          </div>
          {/* la bare de navigation */}
        </div>
        <div>
          <div
            style={
              props.currentIndex === 3 && props.lessonId_intox === 3
                ? null
                : applyDisplayNone
            }
            onClick={props.nav_goBack_onClick}
          >
            <img
              className="circleRedImg nav_goBack2"
              src={images_store.general.circleImage}
              alt=""
              style={{ marginTop: "49%", marginLeft: "39.6%" }}
            />
          </div>
          <div
            style={
              props.currentIndex === 3 && props.lessonId_intox === 3
                ? null
                : applyDisplayNone
            }
            onClick={props.nav_home_onClick}
          >
            <img
              className="circleRedImg nav_home1"
              src={images_store.general.circleImage}
              alt=""
              style={{ marginTop: "49%", marginLeft: "22.4%" }}
            />
          </div>

          <div
            style={
              props.currentIndex === 3 && props.lessonId_intox === 3
                ? null
                : applyDisplayNone
            }
            onClick={props.nav_returnToApps_onClick}
          >
            <img
              className="circleRedImg nav_returnToApps2"
              src={images_store.general.circleImage}
              alt=""
            />
          </div>
        </div>

        <div style={{}}>
          
          <img src={props.img} className="Vertical_Image" alt="" style={ props.currentIndex === 3 && props.lessonId_intox === 3 ? imageWidth : null } />
        </div>
      </div>

      <div
        style={props.is_several && props.is_notLast ? null : applyDisplayNone}
        onClick={goToNext}
        className="accollade_droit"
      >
        ❱
      </div>
    </div>
  );
}

export default VerticaleImage;
