import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
//import du fichier json
 import LANG from "../properties.json";

const Parametre = () => {

 //Langue par défaut Fr
  function Languages(Idlangue){
    const data = LANG.map((info) => {
      return info.languages[Idlangue].name;
    });
    //console.log('DATA YEYO ',data);
   
    return data;
  }

  const handleSelect = (e) => {
    sessionStorage.setItem("Lang", e);
  };

  return (
    <div className=''>
       
      <DropdownButton size="lg" title=""
       onSelect={(e) => handleSelect(e)} 
       >
        
        <Dropdown.Item eventKey="1" href="/">
          <div className="langue">
              {Languages(
              // sessionStorage.getItem("Lang"),
              // sessionStorage.getItem("theme"),
              1
            )}     
          </div>
        </Dropdown.Item>
        <Dropdown.Item eventKey="0" href="/">
          <div className="langue">
             {Languages(
              // sessionStorage.getItem("Lang"),
              // sessionStorage.getItem("theme"),
              0
            )}     
          </div>
        </Dropdown.Item> 
        {/* <Dropdown.Item eventKey="2" href="/">
          <div className="langue">
             {Languages(
              // sessionStorage.getItem("Lang"),
              // sessionStorage.getItem("theme"),
              2
            )}     
          </div>
        </Dropdown.Item> 
        <Dropdown.Item eventKey="3" href="/">
          <div className="langue">
             {Languages(
              // sessionStorage.getItem("Lang"),
              // sessionStorage.getItem("theme"),
              3
            )}     
          </div>
        </Dropdown.Item> 
        
        <Dropdown.Item eventKey="4" href="/">
          <div className="langue">
             {Languages(
              // sessionStorage.getItem("Lang"),
              // sessionStorage.getItem("theme"),
              4
            )}     
          </div>
        </Dropdown.Item> 
         
        <Dropdown.Item eventKey="5" href="/">
          <div className="langue">
             {Languages(
              // sessionStorage.getItem("Lang"),
              // sessionStorage.getItem("theme"),
              5
            )}     
          </div>
        </Dropdown.Item>  */}
      </DropdownButton>
       
    </div>
  );
};

export default Parametre;
