import React from "react";
import images_store from "../utils/Images";
import CircleButton from "./CircleButton";
import { useNavigate, useParams } from "react-router-dom";
import LANG from '../properties.json';


function Modal(props) {
  const { moduleId } = useParams();

  function Fin_partie(IdLang){
const data = LANG.map((info)=>{
  return info.languages[IdLang].domains[0].subdomains[0].sequences[0].pages[6].title
})
return data
  }

  let navigate = useNavigate();

  const activeModal_overlay = {
    opacity: 1,
    visibility: "visible",
  };

  const activeModal = {
    visibility: "visible",
    opacity: 1,
    transform: "scale(1)",
    display:"flex"
  };

  const activeCloseModal = {
    transform: "translateY(10px)",
    opacity: 1,
  };

  const activeModal_content = {
    opacity: 1,
  };

  const handleOnRefresh = () => {
    props.onReload();
  };

  const handleReturnHome = () => {
    navigate("/" + moduleId + "/exercices", { replace: true });
  };

  var LangId = sessionStorage.getItem('Lang');

  return (
    <div
      style={props.isActive ? activeModal_overlay : null}
      className="modal-overlay"
    >
      <div style={props.isActive ? activeModal : null} className="modal-1">
        {/* <img
          alt=""
          src={images_store.general.closeModalIcon}
          style={props.isActive ? activeCloseModal : null}
          className="close-modal"
          onClick={() => {
            props.onReload();
          }}
          
        /> */}
        <div
          style={props.isActive ? activeModal_content : null}
          className="modal-content-1"
        >
          <h2 className="modal_title">{Fin_partie(LangId)}</h2>
          {/* <h3>
            Votre score est : {props.bonneReponse} bonne(s) reponse(s) et{" "}
            {props.mauvaiseReponse} mauvaise(s) reponse(s){" "}
          </h3> */}
          <div className="summary_count_point_div">
            {props.color_array.map((x, i) => (
              <div
                key={i}
                id={"item" + i.toString()}
                className={"exo_pointer " + x}
              ></div>
            ))}
          </div>
          <div className="btnsConteiner">
            <div className="col"  style={{}}>
            <CircleButton
              onClickEvt={handleOnRefresh}
              img={images_store.general.restartIcon}
            />

            </div>
            <div className="col" style={{marginLeft:'30%'}}>

            <CircleButton
              onClickEvt={handleReturnHome}
              img={images_store.general.backHomeImage}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;