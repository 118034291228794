import React, { useState } from 'react';
import Parametre from '../components/setting';

import { Modal } from "react-bootstrap";
import { NavLink } from 'react-router-dom';


const Exemple =()=>{
    const [show, setShow] = useState(true);
    const fermer = () => setShow(false);
  const ouvrir = () => setShow(true);
    
    return(
        <div>
            <Modal show={show} onHide={fermer} size="lg" style={{ width: "100%" }}>
          <div className="row" id="finpartie">
            <div className="row finpartie-text" style={{ marginBottom: "-1%" }}>
              <p className="h2">
                {/* <div
                  dangerouslySetInnerHTML={{
                    __html: FIN_DE_PAGE(langID, 0, 4),
                  }}
                /> */}
                FIN_DE_PAGE
              </p>
            </div>
            <div className="col mb-5 mt-5">
              <div className="col">CASE</div>
            </div>
            <div
              className="row finpartie-btn-groupe mb-5"
              style={{ marginTop: "-3%" }}
            >
              <div className="col center finpartie-btn">
                <NavLink to="" onClick={() => window.location.reload()}>
                  <img
                    src="./img/bureau.png"
                    alt="Récommencer"
                    className=""
                    style={{ width: "5vw", marginLeft: "80%" }}
                  />
                </NavLink>
              </div>
              <div className="col center finpartie-btn">
                
              </div>
            </div>
          </div>
        </Modal>
        </div>
    )
}
export default Exemple

